<div class="modal-wrapper" *ngIf="visible">
  <div class="modal-container">
    <div class="modal-header">
      <h3>Bilgesin'de Arayın</h3>
      <div class="modal-close" (click)="visibleChange.emit(false)"></div>
    </div>

    <div class="modal-body">
      <div class="form-control-holder">
        <input
          type="text"
          [formControl]="searchControl"
          placeholder="Bilgesin'de Arayın: Anket vb."
          #searchInput
        />
        <!-- <button type="button" (click)="get()">
          <img
            src="/assets/images/search-icon.png"
            alt="Search"
            height="30px"
          />
        </button> -->
      </div>
      <hr />
      <div class="search-list-wrapper">
        <div *ngIf="loading" class="loader-wrapper">
          <span class="loader"></span>
        </div>
        <ng-template [ngIf]="!loading">
          <div class="search-response-text" *ngIf="searchResponseText">
            <b>{{ searchResponseText }}</b>
            <br />
            <h5>Öneriler:</h5>
          </div>
          <div class="search-list" *ngFor="let searchItem of searchList">
            <div class="search-list--title">
              {{ searchItem.title }} ({{ searchItem.items.length }})
            </div>
            <div class="search-list--list">
              <a
                class="search-list--list--item"
                *ngFor="let item of searchItem.items"
                (click)="visibleChange.emit(false)"
                [href]="item.href"
              >
                {{ item.title }}
              </a>
            </div>
            <hr />
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
