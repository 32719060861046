<h2 style="margin-bottom: 20px">Kullanıcı Bilgileri Modülü</h2>

<table class="table table-hover table-bordered">
  <thead>
    <tr>
      <th>Telefon</th>
      <th *ngIf="booleans.hasGenderAsked">Cinsiyet</th>
      <th *ngIf="booleans.hasAgeAsked">Yaş</th>
      <th *ngIf="booleans.hasCityAsked">Şehir</th>
      <th *ngIf="booleans.hasShoppedBeforeAsked">
        Daha önce alışveriş yaptınız mı?
      </th>
      <th *ngIf="booleans.hasLastSixMonthsShoppingCountAsked">
        {{ propValue("lastSixMonthsShoppingQuestionTitle") }}
      </th>
      <th *ngIf="booleans.hasWorkingStatusAsked">Çalışma durumunuz?</th>
      <th>KVKK ve reklam için iletişim onayı</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of groupedUserInfos">
      <td>{{ item.userPhone }} ({{ item.userFillCount }})</td>
      <td *ngIf="booleans.hasGenderAsked">{{ item.gender }}</td>
      <td *ngIf="booleans.hasAgeAsked">{{ item.age }}</td>
      <td *ngIf="booleans.hasCityAsked">{{ item.city }}</td>
      <td *ngIf="booleans.hasShoppedBeforeAsked">
        {{ item.shoppedBefore }}
      </td>
      <td *ngIf="booleans.hasLastSixMonthsShoppingCountAsked">
        {{ item.lastSixMonthsShoppingCount }}
      </td>
      <td *ngIf="booleans.hasWorkingStatusAsked">
        {{ item.workingStatus }}
      </td>
      <td>{{ item.kvkkApproval }}</td>
    </tr>
  </tbody>
</table>
