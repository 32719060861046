import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class MetaService {
  constructor(private titleService: Title, private meta: Meta) {}

  set({ title, description }) {
    this.titleService.setTitle(title);
    this.meta.updateTag(
      {
        content: description,
      },
      "name=description"
    );
  }
}
