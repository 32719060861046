import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";

@Component({
  selector: "app-root-search-logs",
  templateUrl: "./root-search-logs.component.html",
  styleUrls: ["./root-search-logs.component.scss"],
})
export class RootSearchLogsComponent implements OnInit {
  searchLogs: any[] = [];
  totalCount = 0;
  page = 0;
  limit = 30;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.http
      .get("/search/logs", {
        params: {
          page: this.page.toString(),
          limit: this.limit.toString(),
        },
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.searchLogs = res.data;
        this.totalCount = res.totalCount;
      });
  }
}
