import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";

@Component({
  selector: "app-root-company-form-coupons",
  templateUrl: "./root-company-form-coupons.component.html",
  styleUrls: ["./root-company-form-coupons.component.scss"],
})
export class RootCompanyFormCouponsComponent implements OnInit {
  coupons: any[] = [];

  selected = {
    couponCode: "",
  };
  selectedId = "";
  modalVisible = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.http
      .get("/company-form-coupons")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.coupons = res;
      });
  }

  onEdit(item) {
    this.selected = { ...item };
    this.selectedId = item._id.toString();
    this.modalVisible = true;
  }

  onDelete(item) {
    const confirmed = confirm("Kuponu silmek istediğinize emin misiniz?");
    if (!confirmed) {
      return;
    }

    this.http
      .delete(`/company-form-coupons/${item._id}`)
      .pipe(take(1))
      .subscribe((res: any) => {
        alert(res.data);
        this.get();
      });
  }

  onAdd() {
    this.selected = {
      couponCode: "",
    };
    this.selectedId = "";
    this.modalVisible = true;
  }

  async save() {
    let showError = !this.selected.couponCode;
    if (showError) {
      alert("Lütfen boş alan bırakmayınız!");
      return;
    }

    const req = { ...this.selected };

    let action;
    if (this.selectedId) {
      delete (req as any)._id;
      action = this.http.put(`/company-form-coupons/${this.selectedId}`, req);
    } else {
      action = this.http.post("/company-form-coupons", req);
    }

    action.pipe(take(1)).subscribe((res: any) => {
      alert(res.data);
      this.modalVisible = false;
      this.get();
    });
  }
}
