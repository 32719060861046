import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { verifyPhoneNumber } from "src/app/helpers/helpers";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-admin-statistics",
  templateUrl: "./admin-statistics.component.html",
  styleUrls: ["./admin-statistics.component.scss"],
})
export class AdminStatisticsComponent implements OnInit {
  surweys = [];
  questionCount = [];
  maxQuestionCount = 0;
  qr = null;

  constructor(
    private _sanitizer: DomSanitizer,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getStats();
  }

  getStats() {
    this.http
      .get("/getStatistics")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.surweys = res;
      });
  }

  getBackground(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  removeSurvey(item) {
    this.http
      .post("/removeSurvey", {
        id: item.formId,
        statId: item._id,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  }

  editSurvey(item) {
    this.router.navigateByUrl(`/admin/edit-survey/${item.formId}`);
  }

  sendSurveySms(phone, formId) {
    if (!verifyPhoneNumber(phone.toString())) {
      return;
    }

    this.http
      .post("/sendSurveySms", {
        phone,
        formId,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.surweys.forEach((m) => (m.phone = ""));
        alert(res.message);
      });
  }

  showOnHomePageChange(item) {
    this.http
      .post("/updateShowOnHomePage", {
        id: item.formId,
        showOnHomePage: item.showOnHomePage,
      })
      .pipe(take(1))
      .subscribe((res: any) => {});
  }
}
