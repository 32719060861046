import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, take } from "rxjs/operators";
import { scrollIntoView, scrollToTop } from "src/app/helpers/helpers";

@Component({
  selector: "app-blog-detail",
  templateUrl: "./blog-detail.component.html",
  styleUrls: ["./blog-detail.component.scss"],
})
export class BlogDetailComponent implements OnInit {
  blog;
  blogSlugUrl = "";

  loading = false;

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    scrollToTop();

    this.activatedRoute.paramMap.subscribe((params) => {
      this.blogSlugUrl = params.get("slugUrl");
      if (this.blogSlugUrl) {
        this.get();
      }
    });
  }

  get() {
    this.loading = true;

    this.http
      .get(`/blog/${this.blogSlugUrl}`)
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe((res: any) => {
        this.blog = res.data;
        if (this.blog) {
          this.titleService.setTitle(this.blog.metaTitle);
          this.metaService.updateTag(
            {
              content: this.blog.metaDescription,
            },
            "name=description"
          );
        }
      });
  }

  navigateToCampaigns() {
    if (this.blog.surveyUrl) {
      this.router.navigate([this.blog.surveyUrl]);
    } else {
      this.router.navigate(["/"]).then((val) => {
        if (val) {
          setTimeout(() => {
            scrollIntoView("#campaigns-section-content");
          }, 250);
        }
      });
    }
  }
}
