import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-kullanim-kosullari",
  templateUrl: "./kullanim-kosullari.component.html",
  styleUrls: ["./kullanim-kosullari.component.scss"],
})
export class KullanimKosullariComponent implements OnInit {
  hostName = environment.hostName();

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title:
        "Kullanım Koşulları - Kullanım koşullarını kabul edin ve bizi keşfedin - Bilgesin",
      description:
        "Online anket doldurarak para kazanmak için, uzmanlar tarafından hazırlanmış koşulları kabul etmeniz yeterlidir.",
    });
  }
}
