<div class="logo-container">
  <div class="holder" (click)="goToHome()">
    <img src="../../../assets/images/logo.svg" alt="logo" [class]="imgClass" />
  </div>
  <div class="logo-admin-link-wrapper" *ngIf="user?.isAdmin || user?.isRoot">
    <a
      *ngIf="user.isAdmin && !userService.isPazanova()"
      routerLink="/admin/new-survey"
    >
      Yönetim Paneli
    </a>
    <a *ngIf="userService.isPazanova()" routerLink="/admin/pazanova"
      >Yönetim Paneli</a
    >
    <a *ngIf="user.isRoot" routerLink="/admin/companies">Yönetim Paneli</a>
  </div>
</div>
