import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-neden-bilgesin",
  templateUrl: "./neden-bilgesin.component.html",
  styleUrls: ["./neden-bilgesin.component.scss"],
})
export class NedenBilgesinComponent implements OnInit {
  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title:
        "Neden Bilgesin? - Anket doldurarak para kazanabileceğiniz güvenilir bir sitedir - Bilgesin",
      description:
        "Bilgesin.com güvenilir para kazandıran anket sitelerinden en bilinenlerindendir. Markalarla yorumlarınızı paylaşarak indirim kuponu kazanın.",
    });
  }
}
