<div class="popup-holder {{ landingPageKey }}" *ngIf="show">
  <div class="popup-container" *ngIf="state === 'phone'">
    <div class="popup-header">
      <div class="popup-header-title">Şifre Sıfırla</div>
      <img
        src="/assets/images/popup-close.svg"
        class="popup-header-close"
        (click)="hide.emit()"
      />
    </div>
    <div class="popup-content">
      <div class="popup-text">
        Yeni şifre almak için lütfen telefon numaranızı başında 0 olmadan girin.
      </div>
      <div class="form-control-holder">
        <label for="phoneInput">Telefon</label>
        <input
          type="text"
          id="phoneInput"
          [(ngModel)]="phoneInput"
          #ctrl="ngModel"
          maxlength="10"
          title="Lütfen başında 0 olmadan giriniz"
          placeholder="5321231212"
        />
      </div>
      <div class="login-button-holder">
        <app-button
          [type]="landingPageKey || 'simple'"
          text="Sıfırla"
          (click)="sendResetPasswordCode()"
        ></app-button>
      </div>
    </div>
  </div>

  <div class="popup-container" *ngIf="state === 'code'">
    <div class="popup-header">
      <div class="popup-header-title">Şifre Sıfırla</div>
      <img
        src="/assets/images/popup-close.svg"
        class="popup-header-close"
        (click)="hide.emit()"
      />
    </div>
    <div class="popup-content">
      <div class="popup-text">
        Lütfen telefonuza gönderilen yeni şifrenizi girin.
      </div>
      <div class="form-control-holder">
        <label for="code">Şifre</label>
        <input type="text" id="code" [(ngModel)]="code" #ctrl="ngModel" />
      </div>
      <div class="login-button-holder">
        <app-button
          [type]="landingPageKey || 'simple'"
          text="Sıfırla"
          (click)="renewPassword()"
        ></app-button>
      </div>
      <app-button
        type="link {{ landingPageKey || 'link-orange' }}"
        [text]="resendCodeText"
        (click)="sendResetPasswordCode()"
        *ngIf="!passwordSending"
      ></app-button>
    </div>
  </div>
</div>
