import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as moment from "moment";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-home-video-modal",
  templateUrl: "./home-video-modal.component.html",
  styleUrls: ["./home-video-modal.component.scss"],
})
export class HomeVideoModalComponent implements OnInit {
  modalVisible = false;
  isMobile = window.innerWidth < 768;
  get videoSrc() {
    return this.isMobile
      ? `${environment.baseUrl}/public/video/tanitim-mobile.mp4`
      : `${environment.baseUrl}/public/video/tanitim.mp4`;
  }
  get posterSrc() {
    return this.isMobile
      ? "/assets/images/tanitim-mobile.png"
      : "/assets/images/tanitim.png";
  }

  @ViewChild("videoPlayer") videoPlayer!: ElementRef;
  playing: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.handleVisibility();
  }

  handleVisibility() {
    const howLongToWait = 30; // 30 Minutes;
    let lastVisibleDate: Date | string =
      window.localStorage.getItem("BLG_VIDEO");
    let show = false;
    if (lastVisibleDate) {
      lastVisibleDate = new Date(lastVisibleDate);
      const diff = moment().diff(moment(lastVisibleDate), "minutes");
      if (diff > howLongToWait) {
        show = true;
      }
    } else {
      show = true;
    }

    if (show) {
      this.modalVisible = true;
      window.localStorage.setItem("BLG_VIDEO", new Date().toISOString());
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.isMobile = window.innerWidth < 768;
  }

  onChangeEvt(evt: "play" | "pause") {
    this.playing = evt === "play";
  }

  playVideoPlayer() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.play();
  }

  pauseVideoPlayer() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.pause();
  }
}
