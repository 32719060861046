<div class="holder">
  <div class="card">
    <h1>Şifre Sıfırla</h1>
    <ng-container *ngIf="state == 'fillForm'">
      <h2>
        Şifrenizi sıfırlamak için lütfen <br />
        <span *ngIf="type == 'phone'">telefon numaranızı</span>
        <span *ngIf="type != 'phone'">e-Posta adresinizi</span> girin.
      </h2>

      <div class="form-control-holder" *ngIf="type == 'phone'">
        <label for="phone">Telefon</label>
        <input
          type="text"
          id="phone"
          [(ngModel)]="phone"
          #ctrl="ngModel"
          maxlength="10"
          title="Lütfen başında 0 olmadan giriniz"
          placeholder="5321231212"
        />
      </div>
      <div class="form-control-holder" *ngIf="type != 'phone'">
        <label for="email">e-Posta</label>
        <input type="email" id="email" [(ngModel)]="email" #ctrl="ngModel" />
      </div>
      <div class="login-button-holder">
        <app-button
          type="simple"
          text="Sıfırla"
          (click)="submit()"
        ></app-button>
      </div>
    </ng-container>

    <ng-container *ngIf="state == 'verify'">
      <h2>
        Şifrenizi sıfırlamak için lütfen <br />
        <span *ngIf="type == 'phone'">telefonuza gönderilen</span>
        <span *ngIf="type != 'phone'">e-Posta'nıza gönderilen</span> kodu girin.
      </h2>
      <div class="form-control-holder">
        <label for="code">Kod</label>
        <input type="text" id="code" [(ngModel)]="code" #ctrl="ngModel" />
      </div>
      <div class="form-control-holder">
        <label for="password">Şifre</label>
        <app-password-input
          id="password"
          [(model)]="password"
        ></app-password-input>
      </div>
      <div class="form-control-holder">
        <label for="password2">Şifre Tekrar</label>
        <app-password-input
          id="password2"
          [(model)]="password2"
        ></app-password-input>
      </div>
      <div class="login-button-holder">
        <app-button
          type="simple"
          text="Sıfırla"
          (click)="renewPassowrd()"
        ></app-button>
      </div>
    </ng-container>
  </div>
</div>
