import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-is-ortagimiz-olmak-ister-misiniz",
  templateUrl: "./is-ortagimiz-olmak-ister-misiniz.component.html",
  styleUrls: ["./is-ortagimiz-olmak-ister-misiniz.component.scss"],
})
export class IsOrtagimizOlmakIsterMisinizComponent implements OnInit {
  constructor(private http: HttpClient, private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title: "İş Ortağımız Olmak İster misiniz? - Bilgesin",
      description:
        "Anket doldurarak para kazanma uygulamalarından en güvenilir olanı Bilgesin.com ile çalışmak ister misiniz? Yeni anket sitelerinden biriyiz, bize formla ulaşabilirsiniz.",
    });
  }

  onSubmit(e) {
    if (e.target.name.value == "") {
      alert("Lütfen 'Ad Soyad' alanını doldurunuz.");
      return false;
    }
    if (e.target.email.value == "") {
      alert("Lütfen 'E-Posta' alanını doldurunuz.");
      return false;
    }
    if (e.target.job.value == "") {
      alert("Lütfen 'Göreviniz' alanını doldurunuz.");
      return false;
    }
    if (e.target.company.value == "") {
      alert("Lütfen 'Firma Adı' alanını doldurunuz.");
      return false;
    }

    this.http
      .post("/companyForm", {
        name: e.target.name.value,
        email: e.target.email.value,
        tel: e.target.tel.value,
        job: e.target.job.value,
        company: e.target.company.value,
        legalname: e.target.legalname.value,
        text: e.target.text.value,
        couponCode: e.target.couponCode.value,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        alert(res.data);
        if (!res.error) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
  }
}
