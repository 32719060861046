import { HttpClient } from "@angular/common/http";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { verifyPhoneNumber } from "src/app/helpers/helpers";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-forgatten",
  templateUrl: "./forgatten.component.html",
  styleUrls: ["./forgatten.component.scss"],
})
export class ForgattenComponent implements OnInit {
  email = "";
  phone = "";
  type = null;
  code = null;
  password = null;
  password2 = null;
  state = "fillForm";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
    this.type = this.route.snapshot.paramMap.get("type");
  }

  ngOnInit(): void {}

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.submit();
    }
  }

  submit() {
    if (this.type == "phone") {
      if (!this.phone) {
        alert("Lütfen telefon numaranızı başında 0 olmadan giriniz.");
        return false;
      } else if (!verifyPhoneNumber(this.phone.toString())) {
        return;
      }
    }
    if (this.type != "phone" && !this.email) {
      alert("Lütfen e-Posta adresinizi giriniz.");
      return false;
    }

    this.http
      .post("/forgot", {
        type: this.type,
        phone: this.phone,
        email: this.email,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res.status) {
          this.state = "verify";
        } else {
          alert(res.message);
        }
      });
  }

  renewPassowrd() {
    if (this.password != this.password2) {
      alert("Girmiş olduğunuz şifreler birbiriyle uyuşmamaktadır.");
      return false;
    }
    if (!this.code) {
      alert("Girmiş olduğunuz kod geçersizdir.");
      return false;
    }

    this.http
      .post("/renewpassword", {
        type: this.type,
        phone: this.phone,
        email: this.email,
        password: this.password,
        code: this.code,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res.status) {
          alert("Şifreniz başarıyla değiştirildi");
          this.router.navigate([`/login`]);
        } else {
          alert(res.message);
        }
      });
  }
}
