import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  user: any;
  copyrightYear = new Date().getFullYear();
  searchModalVisible = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.user.subscribe((x) => {
      this.user = x;
    });
  }

  navigateToCampaigns() {
    if (window.location.pathname === "/") {
      document.getElementById("campaigns-section-content").scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.location.href = "/#campaigns-section-content";
    }
  }
}
