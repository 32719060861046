import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-admin-questions",
  templateUrl: "./admin-questions.component.html",
  styleUrls: ["./admin-questions.component.scss"],
})
export class AdminQuestionsComponent implements OnInit {
  sectorId = "";
  sectors = [];
  questions = null;
  filteredQuestions = null;
  questionType = "tumu";
  mustSectorId = "66f2a1c97240c13be0fd5c18";

  constructor(private http: HttpClient, private userService: UserService) {}

  ngOnInit(): void {
    this.getSectors();
  }

  getSectors(): any {
    this.http
      .get("/getSectors")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.sectors = res;
        //this.sectorId = this.userService.getUser().sectorId;
        this.sectorId = this.mustSectorId.toString();

        this.filterQuestions();
      });
  }

  filterQuestions() {
    if (this.sectorId !== this.mustSectorId) {
      this.questions = [];
      this.filteredQuestions = [];
    }

    this.questions = this.sectors.filter(
      (x) => x._id === this.sectorId
    )[0].questions;

    let type = this.questionType;
    this.filteredQuestions = [];
    if (type == "tumu") {
      this.filteredQuestions = this.questions;
    } else if (type == "open") {
      this.filteredQuestions = this.questions.filter((x) => x.kind == "open");
    } else if (type == "range") {
      this.filteredQuestions = this.questions.filter((x) => x.sort == "range");
    } else if (type == "multiple") {
      this.filteredQuestions = this.questions.filter(
        (x) => x.sort == "multiple"
      );
    }
  }
}
