<div class="holder">
  <h1>Hakkımızda</h1>
  <p>
    Kazan kazan felsefesini iş dünyasına yansıtma düşüncesiyle 2020’de kurulduk.
    İlk günden beri hem kurumlara hem de bireylere fayda sağlama amacına yönelik
    çalışmaktayız.
  </p>
  <p>
    Birçok kurum müşterilerinin bilgi ve fikirlerini öğrenmek istiyor. Giderek
    dijitalleşen ve hızlanan dünyada, zaman her geçen gün daha da önem
    kazanıyor. Kurumlara zaman ayırarak paylaşımda bulunan ve gelişimlerine
    katkı sağlayan müşteriler Bilgesin ile zamanların ve emeklerinin karşılığını
    indirim kazanarak alıyorlar.
  </p>
  <p>
    İşine tutku ile bağlı ekibimiz ile sürekli yenilenen teknolojik çözümleri
    kullanarak Bilgesin’i müşterilerimizin hizmetine sunuyoruz. Şu an için
    Türkiye’de birçok farklı sektörde faaliyet gösteriyoruz. Kurum olarak
    hedefimiz 2026’da yurtdışında da faaliyete geçerek ülkemizi tanıtmak, milli
    sermayeye katkıda bulunmaktır.
  </p>
</div>
