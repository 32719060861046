import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import {
  generateUUID,
  landingPageConfig,
  scrollIntoView,
  scrollToTop,
} from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  campaigns = [];
  campaignsLoading = false;
  isScreenLandscape = "portrait";
  filters = {
    sectors: [],
    cities: [],
    towns: [],
  };

  _filterText = "";
  get filterText() {
    return this._filterText;
  }
  set filterText(val: string) {
    this._filterText = val;
    this.getCampaigns();
  }

  _filterSector = "";
  get filterSector() {
    return this._filterSector;
  }
  set filterSector(val: string) {
    this._filterSector = val;
    this.getCampaigns();
  }

  _filterCity = "";
  get filterCity() {
    return this._filterCity;
  }
  set filterCity(val: string) {
    this._filterCity = val;
    this.filterTown = "";
    this.getCampaigns();
  }

  _filterTown = "";
  get filterTown() {
    return this._filterTown;
  }
  set filterTown(val: string) {
    this._filterTown = val;
    this.getCampaigns();
  }

  get filtersTowns() {
    if (!this.filterCity) {
      return [];
    }

    return this.filters.towns.filter((m) => m.cityName === this.filterCity);
  }

  tabs = [
    { name: "TÜM FIRSATLAR", active: true, promotion_type: "" },
    // { name: "İNDİRİMLER", active: false, promotion_type: "Indirim" },
    // { name: "HEDİYE ÇEKLERİ", active: false, promotion_type: 'HediyeCeki' },
    // { name: "HEDİYELER", active: false, promotion_type: "Hediye" },
  ];

  oldItemsTabs = [
    { name: "GEÇMİŞ FIRSATLAR", active: true, promotion_type: "" },
  ];

  oldItemsCampaigns = [];
  oldItemsCampaignsLoading = false;
  oldItemsFilters = {
    sectors: [],
    cities: [],
    towns: [],
  };
  oldItemsShowAll = false;

  _oldItemsFilterText = "";
  get oldItemsFilterText() {
    return this._oldItemsFilterText;
  }
  set oldItemsFilterText(val: string) {
    this._oldItemsFilterText = val;
    this.getOldItemsCampaigns();
  }

  _oldItemsFilterSector = "";
  get oldItemsFilterSector() {
    return this._oldItemsFilterSector;
  }
  set oldItemsFilterSector(val: string) {
    this._oldItemsFilterSector = val;
    this.getOldItemsCampaigns();
  }

  _oldItemsFilterCity = "";
  get oldItemsFilterCity() {
    return this._oldItemsFilterCity;
  }
  set oldItemsFilterCity(val: string) {
    this._oldItemsFilterCity = val;
    this.oldItemsFilterTown = "";
    this.getOldItemsCampaigns();
  }

  _oldItemsFilterTown = "";
  get oldItemsFilterTown() {
    return this._oldItemsFilterTown;
  }
  set oldItemsFilterTown(val: string) {
    this._oldItemsFilterTown = val;
    this.getOldItemsCampaigns();
  }

  get oldItemsFiltersTowns() {
    if (!this.oldItemsFilterCity) {
      return [];
    }

    return this.oldItemsFilters.towns.filter(
      (m) => m.cityName === this.oldItemsFilterCity
    );
  }

  get filteredOldItemsCampaigns() {
    if (this.oldItemsShowAll) {
      return this.oldItemsCampaigns;
    }
    return this.oldItemsCampaigns.slice(0, 3);
  }

  constructor(private http: HttpClient, private metaService: MetaService) {}

  ngOnInit(): void {
    this.metaService.set({
      title:
        "Burada herkes kazanır - Bilgesin'den anketi doldur indirim kuponu kazan - Bilgesin",
      description:
        "Bilgesin.com ile anket doldur para kazan. En çok para kazandıran siteler arasındadır. ",
    });

    this.isScreenLandscape =
      window.orientation === 0 ? "portrait" : "landscape";
    window.addEventListener("orientationchange", () => {
      this.isScreenLandscape =
        window.orientation === 0 ? "portrait" : "landscape";
    });
    scrollToTop();

    this.getCampaigns();
    this.getFilters();

    this.getOldItemsCampaigns();
    this.getOldItemsFilters();
  }

  navigateToCampaigns() {
    scrollIntoView("#campaigns-section-content");
  }

  navigateToCampaignsTabs() {
    scrollIntoView("#campaigns-tabs");
  }

  changeTab(item) {
    this.tabs.find((x) => x.active).active = false;
    item.active = true;
    this.filterSector = "";
    this.filterCity = "";
    this.filterTown = "";

    this.getFilters();
    this.getCampaigns();
  }

  changeOldItemsTab(item) {
    this.oldItemsTabs.find((x) => x.active).active = false;
    item.active = true;
    this.oldItemsFilterText = "";
    this.oldItemsFilterCity = "";
    this.oldItemsFilterTown = "";

    this.getOldItemsFilters();
    this.getOldItemsCampaigns();
  }

  searchGuid = generateUUID();
  getCampaigns() {
    const localSearchGuid = generateUUID();
    this.searchGuid = localSearchGuid.toString();
    this.campaigns = [];
    this.campaignsLoading = true;
    const discountType = this.tabs.find((m) => m.active)?.promotion_type || "";

    this.http
      .get("/findCampaigns", {
        params: {
          q: this.filterText,
          type: discountType,
          city: this.filterCity,
          town: this.filterTown,
          sector: this.filterSector,
        },
      })
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          if (this.searchGuid === localSearchGuid) {
            this.campaigns = res?.data || [];
            this.campaignsLoading = false;
          }
        },
        (err: any) => {
          if (this.searchGuid === localSearchGuid) {
            this.campaigns = [];
            this.campaignsLoading = false;
          }
        }
      );
  }

  oldItemsSearchGuid = generateUUID();
  getOldItemsCampaigns() {
    const localSearchGuid = generateUUID();
    this.oldItemsSearchGuid = localSearchGuid.toString();
    this.oldItemsCampaigns = [];
    this.oldItemsCampaignsLoading = true;
    const discountType =
      this.oldItemsTabs.find((m) => m.active)?.promotion_type || "";

    this.http
      .get("/findCampaigns", {
        params: {
          q: this.oldItemsFilterText,
          type: discountType,
          city: this.oldItemsFilterCity,
          town: this.oldItemsFilterTown,
          sector: this.oldItemsFilterSector,
          old: "true",
        },
      })
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          if (this.oldItemsSearchGuid === localSearchGuid) {
            this.oldItemsCampaigns = res?.data || [];
            this.oldItemsCampaignsLoading = false;
          }
        },
        (err: any) => {
          if (this.oldItemsSearchGuid === localSearchGuid) {
            this.oldItemsCampaigns = [];
            this.oldItemsCampaignsLoading = false;
          }
        }
      );
  }

  getFilters() {
    const discountType = this.tabs.find((m) => m.active)?.promotion_type || "";
    this.http
      .get("/getHomeFilters", {
        params: {
          discountType,
        },
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.filters = res?.data;
      });
  }

  getOldItemsFilters() {
    const discountType =
      this.oldItemsTabs.find((m) => m.active)?.promotion_type || "";
    this.http
      .get("/getHomeFilters", {
        params: {
          discountType,
          old: "true",
        },
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.oldItemsFilters = res?.data;
      });
  }

  cityTownName(item) {
    if (item.cityName && item.townName) {
      return `${item.cityName}/${item.townName}`;
    }
    if (item.cityName) {
      return item.cityName;
    }
    if (item.townName) {
      return item.townName;
    }
    return "";
  }

  getGiftFontClass(item) {
    if (item.discountDescription === "Yükleniyor...") {
      return "curvy desc-small";
    }

    if (item.discountDescription?.length > 13) {
      return "curvy desc-small";
    }

    return "curvy";
  }

  generateCampaignSlugUrl(item) {
    return `/${item.companySlugUrl}/${item.slugUrl || item.formId}`;
  }
}
