import { environment } from "src/environments/environment";

export function generateUUID(): string {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const exportExcel = (data, fileName) => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAsExcelFile(excelBuffer, fileName);
  });
};

export const saveAsExcelFile = (buffer, fileName) => {
  import("file-saver").then((FileSaver) => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  });
};

export function generateRandomPass(): string {
  const charset = "abcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";
  for (let i = 0, n = charset.length; i < 10; ++i) {
    id += charset.charAt(Math.floor(Math.random() * n));
  }
  return id;
}

export function verifyPhoneNumber(phone: string, showAlert = true): boolean {
  if (!phone || phone.length != 10) {
    if (showAlert) {
      alert("Telefon numarası 10 haneli olmalıdır.");
    }
    return false;
  }

  if (phone.startsWith("0")) {
    if (showAlert) {
      alert("Telefon numarası 0 ile başlamamalı.");
    }
    return false;
  }

  if (!phone.startsWith("5")) {
    if (showAlert) {
      alert("Telefon numarası 5 ile başlamalı.");
    }
    return false;
  }

  if (!phone.match(/^\d+$/)) {
    if (showAlert) {
      alert("Geçerli bir telefon numarası giriniz.");
    }
    return false;
  }

  return true;
}

export function fixStringLength(str: string, fixLen: number) {
  if (str.length <= fixLen) {
    return str;
  }
  return str.substring(0, fixLen) + "...";
}

export function uploadImage(file) {
  return new Promise(async (resolve, reject) => {
    if (file.type.indexOf("image/") > -1) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", environment.cloudinaryPreset);
      const data = await fetch(
        `https://api.cloudinary.com/v1_1/${environment.cloudinaryCloudName}/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      ).then((r) => r.json());
      resolve(data.secure_url);
    } else {
      reject({
        message: "Fotoğraf yüklenirken hata oluştu!",
      });
    }
  });
}

export function scrollIntoView(querySelector) {
  const elm = document.querySelector(querySelector);
  if (elm) {
    const elementPosition = elm.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - 120;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}

export function scrollToTop() {
  setTimeout(() => {
    try {
      (window as any).fbq("track", "PageView");
    } catch {}
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 100);
}

export const ascsTanitimCompanyId = "63d37e5ea40fd3584ad3bcbd";
export const sportiveCompanyId = "63e3cb9aa40fd3584ad3d1d0";
export const pickhobiCompanyId = "6433f764cfb35135a7b33114";
export const estmarineCompanyId = "64406d602b3fc0e3388b801f";
export const tatilimSensinCompanyId = "64885bc8b0fea56d8c2140be";
export const aktaslarLezzetCompanyId = "64887aa5b0fea56d8c214625";
export const boostOxygenCompanyId = "649317628bf70e545b622371";
export const leoparCompanyId = "64a93026fe06911fd2208cef";
export const mugoCompanyId = "64abfa8bfe06911fd220d9d5";
export const muhikoCompanyId = "64a42ff0025e254a79215444";
export const belTurCompanyId = "64abdb24fe06911fd220ceab";
export const yasomiCompanyId = "66b339f24e50fc48333049d4";
export const tatilAnketiCompanyId = "66f1653bd3bf28aa14926017";
export const pazanovaCompanyId = "66fa8b1df2f2293e0f6544c3";

export const pickhobiDiscountCode = "pick23";
export const estmarineDiscountCode = "EAST042023";
export const boostOxygenDiscountCode = "Oxygen2023";
export const leoparDiscountCode = "7239";
export const muhikoDiscountCode = "bilgesin15";

export const muhikoUtmLink =
  "https://muhiku.com/?utm_source=bilgesincom&utm_medium=website-anket&utm_campaign=bilgesincom-indirim&utm_term=bilgesincom-indirim&page-header=bilgesincom";

export const landingPageConfig = [
  {
    key: "eker",
    lpUrl: "eker-suturunleri-arastirmasi",
    slugUrl: "eker-suturunleri-anket",
    linkButtonText: "market.eker.com'a git",
    linkButtonHref: "https://market.eker.com",
  },
  {
    key: "sportive",
    lpUrl: "sportive-arastirmasi",
    slugUrl: "sportive-arastirma-anketi",
    linkButtonText: "sportive.com.tr'ye git",
    linkButtonHref: "https://www.sportive.com.tr",
  },
  {
    key: "scooter",
    lpUrl: "scooter-arastirmasi",
    slugUrl: "scooter-anketi",
    linkButtonText: "scooter.com.tr'ye git",
    linkButtonHref: "https://www.scooter.com.tr",
  },
  {
    key: "elbisebul",
    lpUrl: "elbisebul-arastirmasi",
    slugUrl: "elbisebul-anketi",
    linkButtonText: "elbisebul.com'a git",
    linkButtonHref: "https://www.elbisebul.com",
  },
  {
    key: "yasomi",
    lpUrl: "yasomi-arastirmasi",
    slugUrl: "yasomi-anketi",
    linkButtonText: "yasomi.com.tr'ye git",
    linkButtonHref: "https://www.yasomi.com.tr",
  },
  {
    key: "boost",
    lpUrl: "boost-oxygen-arastirmasi",
    slugUrl: "boost-oxygen-anketi",
    linkButtonText: "boostoxygen.com.tr'ye git",
    linkButtonHref: "https://www.boostoxygen.com.tr",
  },
];
