import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { finalize, take } from "rxjs/operators";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
  items = [];
  totalCount = 0;
  loading = false;
  page = 0;
  limit = 9;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    scrollToTop();

    this.activatedRoute.paramMap.pipe(take(1)).subscribe((params) => {
      if (params.get("page")) {
        this.page = Number(params.get("page")) - 1 || 0;
      }
      if (!this.page) {
        this.page = 0;
      }

      this.get();
    });

    this.metaService.set({
      title: "Blog - Bilgesin",
      description: "Bilgesin - Blog",
    });
  }

  get() {
    this.loading = true;

    this.http
      .get("/blog", {
        params: {
          page: this.page.toString(),
          limit: this.limit.toString(),
        },
      })
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe((res: any) => {
        this.items = res.data;
        this.totalCount = res.totalCount;
      });
  }
}
