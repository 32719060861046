import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";

@Component({
  selector: "app-root-cozum-gerektiren-konu",
  templateUrl: "./root-cozum-gerektiren-konu.component.html",
  styleUrls: ["./root-cozum-gerektiren-konu.component.scss"],
})
export class RootCozumGerektirenKonuComponent implements OnInit {
  data: any[] = [];
  selected: any = {};
  modalVisible = false;
  selectedId = "";
  companies: any[] = [];
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.get();
    this.getCompanies();
  }

  getCompanies() {
    this.http
      .get("/getCompanies")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.companies = res;
      });
  }

  get() {
    this.http
      .get("/cozum-gerektiren-konu-basliklari/all")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.data = res;
      });
  }

  onEdit(item) {
    this.selected = { ...item };
    this.selectedId = item._id.toString();
    this.modalVisible = true;
  }

  onDelete(item) {
    const confirmed = confirm("Silmek istediğinize emin misiniz?");
    if (!confirmed) {
      return;
    }

    this.http
      .delete(`/cozum-gerektiren-konu-basliklari/${item._id}`)
      .pipe(take(1))
      .subscribe((res: any) => {
        alert(res.data);
        this.get();
      });
  }

  onAdd() {
    this.selected = {
      companyId: "",
      items: [],
    };
    this.selectedId = "";
    this.modalVisible = true;
  }

  companyName(id) {
    return this.companies.find((company) => company._id === id)?.name || "...";
  }

  onAddItem() {
    this.selected.items.push({
      title: "",
      demography: "",
      reference: "",
      referenceActions: [],
    });
  }

  onDeleteItem(index) {
    this.selected.items.splice(index, 1);
  }

  onAddReferenceAction(index) {
    this.selected.items[index].referenceActions.push({
      reference: "",
      actionTaken: "",
    });
  }

  onDeleteReferenceAction(index, itemIndex) {
    this.selected.items[index].referenceActions.splice(itemIndex, 1);
  }

  save() {
    let error = false;
    if (!this.selected.companyId) {
      error = true;
    }
    if (this.selected.items.length === 0) {
      error = true;
    } else {
      for (let item of this.selected.items) {
        if (!item.title) {
          error = true;
          break;
        }
      }
    }

    if (error) {
      alert("Lütfen tüm alanları doldurunuz.");
      return;
    }

    if (this.selectedId) {
      delete this.selected._id;
      this.http
        .put(
          `/cozum-gerektiren-konu-basliklari/${this.selectedId}`,
          this.selected
        )
        .pipe(take(1))
        .subscribe((res: any) => {
          alert(res.data);
          this.get();
          this.modalVisible = false;
        });
    } else {
      this.http
        .post("/cozum-gerektiren-konu-basliklari", this.selected)
        .pipe(take(1))
        .subscribe((res: any) => {
          alert(res.data);
          this.get();
          this.modalVisible = false;
        });
    }
  }
}
