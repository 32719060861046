<div class="holder">
  <h1>{{ edit ? "Anketi Düzenle" : "Yeni Anket" }}</h1>
  <span class="message-holder" *ngIf="message">
    {{ message }}
  </span>

  <!-- <div *ngIf="qr" [style.background-image]="getBackground(qr.qr)" class="image-preview"></div> -->

  <div class="form-control-holder">
    <label for="">Anket Adı</label>
    <input type="text" [(ngModel)]="surwey.name" />
  </div>
  <div class="form-control-holder">
    <label for="">Anket Slug Url</label>
    <input type="text" [(ngModel)]="surwey.slugUrl" />
  </div>
  <div class="form-control-holder">
    <label for="">Anket Açıklaması</label>
    <input type="text" [(ngModel)]="surwey.description" />
  </div>
  <div class="form-control-holder">
    <label for="campaignDescription">Kampanya Açıklaması</label>
    <textarea id="campaignDescription" [(ngModel)]="surwey.campaignDescription">
    </textarea>
  </div>
  <div class="form-control-holder">
    <label for="campaignExpireDate">Kampanya Geçerlilik Tarihi</label>
    <input
      type="text"
      ngxDaterangepickerMd
      [(ngModel)]="surwey.campaignExpireDate"
      class="form-control"
      [singleDatePicker]="true"
      [autoApply]="true"
      [minDate]="today"
    />
  </div>
  <div class="form-control-holder">
    <label for="">Anket Tipi</label>
    <select [(ngModel)]="surwey.discountType">
      <option value="">Seçiniz</option>
      <option value="Indirim">İndirim</option>
      <!-- <option value="HediyeCeki">Hediye Çeki</option> -->
      <option value="Hediye">Hediye</option>
    </select>
  </div>
  <div class="form-control-holder" *ngIf="surwey.discountType !== 'Hediye'">
    <label for="">Indirim Miktari</label>
    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
      "
    >
      {{ surwey.discountAmountType }}&nbsp;<input
        type="number"
        [(ngModel)]="surwey.discountAmount"
        style="margin-bottom: 0"
      />
    </div>
  </div>
  <div class="form-control-holder" *ngIf="surwey.discountType !== 'Hediye'">
    <label for="">Indirim Tipi</label>
    <select [(ngModel)]="surwey.discountAmountType">
      <option value="">Seçiniz</option>
      <option value="%">Yuzde</option>
      <option value="TL">Miktar</option>
    </select>
  </div>
  <div class="form-control-holder" *ngIf="surwey.discountType === 'Hediye'">
    <label for="">Hediye Açıklaması</label>
    <div style="margin-bottom: 1rem">
      <input
        type="text"
        [(ngModel)]="surwey.discountDescription"
        style="margin-bottom: 0"
        maxlength="20"
      />
    </div>
  </div>
  <div class="form-control-holder">
    <label for="" style="margin-bottom: 1rem; margin-top: 2rem"
      >Anket Doğruluğu Methodu</label
    >
    <div style="display: flex; justify-content: start; align-items: center">
      <input
        style="width: 30px; height: 30px"
        type="checkbox"
        id="avarage"
        checked
        disabled
      />
      <label
        for="avarage"
        style="text-align: center; margin-left: 1rem; margin-right: 1rem"
        >Güvenlik Süresi <br />
        <small
          style="font-weight: normal; display: block; margin-top: 5px"
          *ngIf="recomendedTimeLimit"
        >
          Önerilen: {{ recomendedTimeLimit }}</small
        ></label
      >
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
      >
        <input
          type="number"
          [(ngModel)]="surwey.timeLimit"
          style="width: 50px; margin-bottom: 0"
        />&nbsp;&nbsp;Saniye
      </div>
    </div>
    <div style="display: flex; justify-content: start; align-items: center">
      <input
        style="width: 30px; height: 30px"
        type="checkbox"
        id="custom"
        [(ngModel)]="surwey.customTimeLimit"
      />
      <label
        style="text-align: center; margin-left: 1rem; margin-right: 1rem"
        for="custom"
        >Ortalama Süre</label
      >
    </div>
    <small
      style="
        font-size: 0.75rem;
        font-weight: normal;
        background: #ffe5bd;
        padding: 0.5rem;
        display: block;
        border-radius: 8px;
        border: 1px solid #f39200;
      "
      >Anketin icerisine capraz soru ekleyerek anket dogruluk oraninizi
      arttirabilirsiniz.</small
    >
  </div>
  <ng-template
    [ngIf]="true"
    *ngFor="let question of this.surwey.questions; index as i"
  >
    <div class="question-holder" *ngIf="!question.hide">
      <div class="form-control-holder">
        <label for="">Soru Sırası</label>
        <input
          [(ngModel)]="question.order"
          type="number"
          (blur)="orderQuestions()"
        />
      </div>
      <div class="form-control-holder">
        <label for="">Soru Tipi</label>
        <select [(ngModel)]="question.type">
          <option value="database">Veri Tabanı</option>
          <option value="new">Yeni Soru</option>
        </select>
      </div>
      <div class="form-control-holder" *ngIf="question.type">
        <label for="">Soru Tarzı</label>
        <select [(ngModel)]="question.kind">
          <option value="open">Açık Uçlu</option>
          <option value="close">Kapalı Uçlu</option>
        </select>
      </div>

      <div *ngIf="i == 0">
        <div *ngIf="question.type == 'database'">
          <div *ngIf="question.kind == 'open'">
            <div class="form-control-holder">
              <label for="">Veritabanindan Soru Seçin</label>
              <select [(ngModel)]="question.question">
                <option value="">Seçiniz</option>
                <option
                  *ngFor="let templatequestion of savedQuestions.open"
                  [ngValue]="templatequestion.question"
                >
                  {{ templatequestion.question.text }}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="question.kind == 'close'">
            <div class="form-control-holder">
              <label for="">Soru Şekli</label>
              <select [(ngModel)]="question.sort">
                <option value="multiple">Çoktan Seçmeli</option>
                <option value="range">Derecelendirmeli</option>
              </select>
            </div>
            <div class="form-control-holder" *ngIf="question.sort">
              <label for="">Veritabanindan Soru Seçin</label>
              <select
                [(ngModel)]="question.DBquestionIndex"
                (change)="handleDBQuestionSelect(i, question.sort)"
              >
                <option value="">Seçiniz</option>
                <ng-container
                  *ngFor="
                    let closeDBquestion of savedQuestions[question.sort];
                    let dbIndex = index
                  "
                >
                  <option [ngValue]="dbIndex">
                    {{ closeDBquestion.question.text }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="question.type == 'new'">
          <div *ngIf="question.kind == 'open'">
            <div class="form-control-holder" *ngIf="question.type">
              <label for="">Soru Metni Giriniz</label>
              <input [(ngModel)]="question.question.text" />
            </div>
          </div>
          <div *ngIf="question.kind == 'close'">
            <div class="form-control-holder">
              <label for="">Soru Şekli</label>
              <select [(ngModel)]="question.sort">
                <option value="multiple">Çoktan Seçmeli</option>
                <option value="range">Derecelendirmeli</option>
              </select>
            </div>
            <div *ngIf="question.sort">
              <div *ngIf="question.sort == 'multiple'">
                <div class="form-control-holder">
                  <label for="">Kaç Şıklı?</label>
                  <select
                    [(ngModel)]="question.question.optionCount"
                    (change)="changeOptionCount(question)"
                  >
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                <div
                  *ngIf="
                    question.question.optionCount &&
                    question.question.valueCount !== null
                  "
                >
                  <div class="form-control-holder">
                    <label for="">Kaç Şık Seçilebilsin</label>
                    <select [(ngModel)]="question.question.valueCount">
                      <option
                        [ngValue]="i"
                        *ngFor="
                          let item of question.question.options;
                          let i = index
                        "
                      >
                        {{ i + 1 }}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="question.question.valueCount !== null">
                    <div class="form-control-holder">
                      <label for="">Soru Metnini Girin</label>
                      <input type="text" [(ngModel)]="question.question.text" />
                    </div>
                    <div
                      class="form-control-holder"
                      *ngFor="
                        let item of question.question.options;
                        let ix = index
                      "
                    >
                      <label for="">{{ ix + 1 }}.Soru Şıkkını Girin</label>
                      <input
                        type="text"
                        [(ngModel)]="question.question.options[ix].text"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="question.sort == 'range'">
                <div class="form-control-holder">
                  <label for="">Kaç Dereceli?</label>
                  <select
                    [(ngModel)]="question.question.optionCount"
                    (change)="changeOptionCount(question)"
                  >
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                  </select>
                </div>
                <div *ngIf="question.question.optionCount">
                  <div class="form-control-holder">
                    <label for="">Soru Metnini Girin</label>
                    <input type="text" [(ngModel)]="question.question.text" />
                  </div>
                  <div
                    class="form-control-holder"
                    *ngFor="
                      let item of question.question.options;
                      let ix = index
                    "
                  >
                    <label for="">{{ ix + 1 }}. Dereceyi İsimlendirin</label>
                    <input
                      type="text"
                      [(ngModel)]="question.question.options[ix].value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="i != 0">
        <div *ngIf="question.type == 'database'">
          <div *ngIf="question.kind == 'open'">
            <div class="form-control-holder">
              <label for=""
                >Soru Bağlantılı Mı? - {{ question.isConnected }}</label
              >
              <select [(ngModel)]="question.isConnected">
                <option [value]="''">Seçiniz</option>
                <option [value]="'true'">Evet</option>
                <option [value]="'false'">Hayır</option>
              </select>
            </div>
            <div *ngIf="question.isConnected">
              <div *ngIf="question.isConnected == 'true'">
                <div class="form-control-holder">
                  <label for="">Bağlantılı Olduğu Soruyu Seçin</label>
                  <select
                    [(ngModel)]="question.connectedQuestionIndex"
                    (change)="handleConnectedQuestion(question, index)"
                  >
                    <option value="">Seçiniz</option>
                    <option
                      *ngFor="
                        let connectedQuestion of surwey.questions;
                        let cqIndex = index
                      "
                      [value]="cqIndex"
                      [attr.disabled]="
                        cqIndex == i || connectedQuestion.kind == 'open'
                          ? true
                          : null
                      "
                    >
                      {{ connectedQuestion.question.text }}
                    </option>
                  </select>
                </div>
                <div *ngIf="question.connectedQuestion">
                  <div *ngIf="question.connectedQuestion.sort == 'range'">
                    <div class="form-control-holder">
                      <label for="">Koşul İçin Minimum Değer</label>
                      <select [(ngModel)]="question.minimumValue">
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                        >
                          {{ connectionOptions.value }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="form-control-holder"
                      *ngIf="question.minimumValue"
                    >
                      <label for="">Koşul İçin Maksimum Değer</label>
                      <select [(ngModel)]="question.maximumValue">
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                          [disabled]="
                            connectionOptions.value < question.minimumValue
                          "
                        >
                          {{ connectionOptions.value }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.minimumValue">
                      <div class="form-control-holder">
                        <label for="">Veritabanindan Soru Seçin</label>
                        <select [(ngModel)]="question.question">
                          <option value="">Seçiniz</option>
                          <option
                            *ngFor="let templatequestion of savedQuestions.open"
                            [ngValue]="templatequestion.question"
                          >
                            {{ templatequestion.question.text }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="question.connectedQuestion.sort == 'multiple'">
                    <div class="form-control-holder">
                      <label for="">Hangi Şıklar Varsa Olmalı</label>
                      <select [(ngModel)]="question.connectionOptions" multiple>
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                        >
                          {{ connectionOptions.text }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.connectionOptions">
                      <div class="form-control-holder">
                        <label for="">Veritabanindan Soru Seçin</label>
                        <select [(ngModel)]="question.question">
                          <option value="">Seçiniz</option>
                          <option
                            *ngFor="let templatequestion of savedQuestions.open"
                            [ngValue]="templatequestion.question"
                          >
                            {{ templatequestion.question.text }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="question.isConnected == 'false'">
                <div class="form-control-holder">
                  <label for="">Veritabanindan Soru Seçin</label>
                  <select [(ngModel)]="question.question">
                    <option value="">Seçiniz</option>
                    <option
                      *ngFor="let templatequestion of savedQuestions.open"
                      [ngValue]="templatequestion.question"
                    >
                      {{ templatequestion.question.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="question.kind == 'close'">
            <div class="form-control-holder">
              <label for="">Soru Bağlantılı Mı?</label>
              <select [(ngModel)]="question.isConnected">
                <option [value]="null">Seçiniz</option>
                <option [value]="'true'">Evet</option>
                <option [value]="'false'">Hayır</option>
              </select>
            </div>
            <div *ngIf="question.isConnected">
              <div *ngIf="question.isConnected == 'true'">
                <div class="form-control-holder">
                  <label for="">Bağlantılı Olduğu Soruyu Seçin</label>
                  <select
                    [(ngModel)]="question.connectedQuestionIndex"
                    (change)="handleConnectedQuestion(question, index)"
                  >
                    <option value="">Seçiniz</option>
                    <option
                      *ngFor="
                        let connectedQuestion of surwey.questions;
                        let cqIndex = index
                      "
                      [value]="cqIndex"
                      [attr.disabled]="
                        cqIndex == i || connectedQuestion.kind == 'open'
                          ? true
                          : null
                      "
                    >
                      {{ connectedQuestion.question.text }}
                    </option>
                  </select>
                </div>
                <div *ngIf="question.connectedQuestion">
                  <div *ngIf="question.connectedQuestion.sort == 'range'">
                    <div class="form-control-holder">
                      <label for="">Koşul İçin Minimum Değer</label>
                      <select [(ngModel)]="question.minimumValue">
                        <option [value]="null">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                        >
                          {{ connectionOptions.value }}
                        </option>
                      </select>
                    </div>
                    <div class="form-control-holder">
                      <label for="">Koşul İçin Maksimum Değer</label>
                      <select [(ngModel)]="question.maximumValue">
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                          [disabled]="
                            connectionOptions.value < question.minimumValue
                          "
                        >
                          {{ connectionOptions.value }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.minimumValue">
                      <div class="form-control-holder">
                        <label for="">Çapraz Soru Mu?</label>
                        <select [(ngModel)]="question.isRelated">
                          <option [value]="null">Seçiniz</option>
                          <option [value]="'true'">Evet</option>
                          <option [value]="'false'">Hayır</option>
                        </select>
                      </div>
                      <div *ngIf="question.isRelated">
                        <div *ngIf="question.isRelated == 'true'">
                          <div class="form-control-holder">
                            <label for="">Çapraz Soruyu Seçin</label>
                            <select
                              [(ngModel)]="question.relatedQuestionIndex"
                              (change)="handleRelatedQuestion(question)"
                            >
                              <option value="">Seçiniz</option>
                              <option
                                *ngFor="
                                  let relatedQuestion of surwey.questions;
                                  let rqIndex1 = index
                                "
                                [value]="rqIndex1"
                                [attr.disabled]="
                                  rqIndex1 == i ||
                                  relatedQuestion.sort != 'range'
                                    ? true
                                    : null
                                "
                              >
                                {{ relatedQuestion.question.text }}
                              </option>
                            </select>
                          </div>
                          <div *ngIf="question.relatedQuestion">
                            <div class="form-control-holder">
                              <label for=""
                                >Fark kaç ve kaçtan büyük olursa geçersiz
                                say</label
                              >
                              <select
                                [(ngModel)]="question.relatedQuestionError"
                                (change)="show(question.relatedQuestionError)"
                              >
                                <option value="">Seçiniz</option>
                                <option
                                  *ngFor="
                                    let relatedQuestionOption of question
                                      .relatedQuestion.question.options;
                                    let rqIndex2 = $index
                                  "
                                  [value]="relatedQuestionOption.value"
                                >
                                  {{ relatedQuestionOption.value }}
                                </option>
                              </select>
                            </div>
                            <div *ngIf="question.relatedQuestionError">
                              <div class="form-control-holder">
                                <label for="">Veritabanindan Soru Seçin</label>
                                <select [(ngModel)]="question.question">
                                  <option value="">Seçiniz</option>
                                  <option
                                    *ngFor="
                                      let templatequestion of savedQuestions.range
                                    "
                                    [ngValue]="templatequestion.question"
                                  >
                                    {{ templatequestion.question.text }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="question.isRelated == 'false'">
                          <div class="form-control-holder">
                            <label for="">Soru Şekli</label>
                            <select
                              [(ngModel)]="question.sort"
                              (change)="
                                handleDbQuestions('sort', question.sort)
                              "
                            >
                              <option value="multiple">Çoktan Seçmeli</option>
                              <option value="range">Derecelendirmeli</option>
                            </select>
                          </div>
                          <div
                            class="form-control-holder"
                            *ngIf="question.sort"
                          >
                            <label for="">Veritabanindan Soru Seçin</label>
                            <select
                              [(ngModel)]="question.question"
                              *ngIf="question.sort == 'multiple'"
                            >
                              <option value="">Seçiniz</option>
                              <option
                                *ngFor="
                                  let multipleQuestion of savedQuestions.multiple
                                "
                                [ngValue]="multipleQuestion.question"
                              >
                                {{ multipleQuestion.question.text }}
                              </option>
                            </select>
                            <select
                              [(ngModel)]="question.question"
                              *ngIf="question.sort == 'range'"
                            >
                              <option value="">Seçiniz</option>
                              <option
                                *ngFor="
                                  let rangeQuestion of savedQuestions.range
                                "
                                [ngValue]="rangeQuestion.question"
                              >
                                {{ rangeQuestion.question.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="question.connectedQuestion.sort == 'multiple'">
                    <div class="form-control-holder">
                      <label for="">Hangi Şıklar Varsa Olmalı</label>
                      <select [(ngModel)]="question.connectionOptions" multiple>
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                        >
                          {{ connectionOptions.text }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.connectionOptions">
                      <div class="form-control-holder">
                        <label for="">Çapraz Soru Mu?</label>
                        <select [(ngModel)]="question.isRelated">
                          <option [value]="null">Seçiniz</option>
                          <option [value]="'true'">Evet</option>
                          <option [value]="'false'">Hayır</option>
                        </select>
                      </div>
                      <div *ngIf="question.isRelated">
                        <div *ngIf="question.isRelated == 'true'">
                          <div class="form-control-holder">
                            <label for="">Çapraz Soruyu Seçin</label>
                            <select
                              [(ngModel)]="question.relatedQuestionIndex"
                              (change)="handleRelatedQuestion(question)"
                            >
                              <option value="">Seçiniz</option>
                              <option
                                *ngFor="
                                  let relatedQuestion of surwey.questions;
                                  let rqIndex1 = index
                                "
                                [value]="rqIndex1"
                                [attr.disabled]="
                                  rqIndex1 == i ||
                                  relatedQuestion.sort != 'range'
                                    ? true
                                    : null
                                "
                              >
                                {{ relatedQuestion.question.text }}
                              </option>
                            </select>
                          </div>
                          <div *ngIf="question.relatedQuestion">
                            <div class="form-control-holder">
                              <label for=""
                                >Fark kaç ve kaçtan büyük olursa geçersiz
                                say</label
                              >
                              <select
                                [(ngModel)]="question.relatedQuestionError"
                                (change)="show(question.relatedQuestionError)"
                              >
                                <option value="">Seçiniz</option>
                                <option
                                  *ngFor="
                                    let relatedQuestionOption of question
                                      .relatedQuestion.question.options;
                                    let rqIndex2 = $index
                                  "
                                  [value]="relatedQuestionOption.value"
                                >
                                  {{ relatedQuestionOption.value }}
                                </option>
                              </select>
                            </div>
                            <div *ngIf="question.relatedQuestionError">
                              <div class="form-control-holder">
                                <label for="">Veritabanindan Soru Seçin</label>
                                <select [(ngModel)]="question.question">
                                  <option value="">Seçiniz</option>
                                  <option
                                    *ngFor="
                                      let templatequestion of savedQuestions.range
                                    "
                                    [ngValue]="templatequestion.question"
                                  >
                                    {{ templatequestion.question.text }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="question.isRelated == 'false'">
                          <div class="form-control-holder">
                            <label for="">Soru Şekli</label>
                            <select
                              [(ngModel)]="question.sort"
                              (change)="
                                handleDbQuestions('sort', question.sort)
                              "
                            >
                              <option value="multiple">Çoktan Seçmeli</option>
                              <option value="range">Derecelendirmeli</option>
                            </select>
                          </div>
                          <div
                            class="form-control-holder"
                            *ngIf="question.sort"
                          >
                            <label for="">Veritabanindan Soru Seçin</label>
                            <select
                              [(ngModel)]="question.question"
                              *ngIf="question.sort == 'multiple'"
                            >
                              <option value="">Seçiniz</option>
                              <option
                                *ngFor="
                                  let templatequestion of savedQuestions.multiple
                                "
                                [ngValue]="templatequestion.question"
                              >
                                {{ templatequestion.question.text }}
                              </option>
                            </select>
                            <select
                              [(ngModel)]="question.question"
                              *ngIf="question.sort == 'range'"
                            >
                              <option value="">Seçiniz</option>
                              <option
                                *ngFor="
                                  let templatequestion of savedQuestions.range
                                "
                                [ngValue]="templatequestion.question"
                              >
                                {{ templatequestion.question.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="question.isConnected == 'false'">
                <div class="form-control-holder">
                  <label for="">Çapraz Soru Mu?</label>
                  <select [(ngModel)]="question.isRelated">
                    <option [value]="null">Seçiniz</option>
                    <option [value]="'true'">Evet</option>
                    <option [value]="'false'">Hayır</option>
                  </select>
                </div>
                <div *ngIf="question.isRelated">
                  <div *ngIf="question.isRelated == 'true'">
                    <div class="form-control-holder">
                      <label for="">Çapraz Soruyu Seçin</label>
                      <select
                        [(ngModel)]="question.relatedQuestionIndex"
                        (change)="handleRelatedQuestion(question)"
                      >
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let relatedQuestion of surwey.questions;
                            let rqIndex1 = index
                          "
                          [value]="rqIndex1"
                          [attr.disabled]="
                            rqIndex1 == i || relatedQuestion.sort != 'range'
                              ? true
                              : null
                          "
                        >
                          {{ relatedQuestion.question.text }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.relatedQuestion">
                      <div class="form-control-holder">
                        <label for=""
                          >Fark kaç ve kaçtan büyük olursa geçersiz say</label
                        >
                        <select
                          [(ngModel)]="question.relatedQuestionError"
                          (change)="show(question.relatedQuestionError)"
                        >
                          <option value="">Seçiniz</option>
                          <option
                            *ngFor="
                              let relatedQuestionOption of question
                                .relatedQuestion.question.options;
                              let rqIndex2 = $index
                            "
                            [value]="relatedQuestionOption.value"
                          >
                            {{ relatedQuestionOption.value }}
                          </option>
                        </select>
                      </div>
                      <div *ngIf="question.relatedQuestionError">
                        <div class="form-control-holder">
                          <label for="">Veritabanindan Soru Seçin</label>
                          <ng-template
                            *ngFor="
                              let templatequestion of savedQuestions.range
                            "
                          >
                            <option
                              *ngIf="
                                templatequestion.question.options.length !=
                                question.relatedQuestion.question.options.length
                              "
                              [ngValue]="templatequestion.question"
                            >
                              {{ templatequestion.question.text }}
                            </option>
                          </ng-template>
                          <select [(ngModel)]="question.question">
                            <option value="">Seçiniz</option>
                            <ng-container
                              *ngFor="
                                let templatequestion of savedQuestions.range
                              "
                            >
                              <option
                                *ngIf="
                                  question.relatedQuestion.question.options
                                    .length ==
                                  templatequestion.question.options.length
                                "
                                [ngValue]="templatequestion.question"
                              >
                                {{ templatequestion.question.text }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="question.isRelated == 'false'">
                    <div class="form-control-holder">
                      <label for="">Soru Şekli</label>
                      <select
                        [(ngModel)]="question.sort"
                        (change)="handleDbQuestions('sort', question.sort)"
                      >
                        <option value="multiple">Çoktan Seçmeli</option>
                        <option value="range">Derecelendirmeli</option>
                      </select>
                    </div>
                    <div class="form-control-holder" *ngIf="question.sort">
                      <label for="">Veritabanindan Soru Seçin</label>
                      <select
                        [(ngModel)]="question.question"
                        *ngIf="question.sort == 'multiple'"
                      >
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let templatequestion of savedQuestions.multiple
                          "
                          [ngValue]="templatequestion.question"
                        >
                          {{ templatequestion.question.text }}
                        </option>
                      </select>
                      <select
                        [(ngModel)]="question.question"
                        *ngIf="question.sort == 'range'"
                      >
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="let templatequestion of savedQuestions.range"
                          [ngValue]="templatequestion.question"
                        >
                          {{ templatequestion.question.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="question.type == 'new'">
          <div *ngIf="question.kind == 'open'">
            <div class="form-control-holder">
              <label for="">Soru Bağlantılı Mı?</label>
              <select [(ngModel)]="question.isConnected">
                <option [value]="null">Seçiniz</option>
                <option [value]="'true'">Evet</option>
                <option [value]="'false'">Hayır</option>
              </select>
            </div>
            <div *ngIf="question.isConnected">
              <div *ngIf="question.isConnected == 'true'">
                <div class="form-control-holder">
                  <label for="">Bağlantılı Olduğu Soruyu Seçin</label>
                  <select
                    [(ngModel)]="question.connectedQuestionIndex"
                    (change)="handleConnectedQuestion(question, index)"
                  >
                    <option value="">Seçiniz</option>
                    <option
                      *ngFor="
                        let connectedQuestion of surwey.questions;
                        let cqIndex = index
                      "
                      [value]="cqIndex"
                      [attr.disabled]="
                        cqIndex == i || connectedQuestion.kind == 'open'
                          ? true
                          : null
                      "
                    >
                      {{ connectedQuestion.question.text }}
                    </option>
                  </select>
                </div>
                <div *ngIf="question.connectedQuestion">
                  <div *ngIf="question.connectedQuestion.sort == 'range'">
                    <div class="form-control-holder">
                      <label for="">Koşul İçin Minimum Değer</label>
                      <select [(ngModel)]="question.minimumValue">
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                        >
                          {{ connectionOptions.value }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="form-control-holder"
                      *ngIf="question.minimumValue"
                    >
                      <label for="">Koşul İçin Maksimum Değer</label>
                      <select [(ngModel)]="question.maximumValue">
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                          [disabled]="
                            connectionOptions.value < question.minimumValue
                          "
                        >
                          {{ connectionOptions.value }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.minimumValue">
                      <div class="form-control-holder">
                        <label for="">Soru Metni Giriniz</label>
                        <input [(ngModel)]="question.question.text" />
                      </div>
                    </div>
                  </div>
                  <div *ngIf="question.connectedQuestion.sort == 'multiple'">
                    <div class="form-control-holder">
                      <label for="">Hangi Şıklar Varsa Olmalı</label>
                      <select [(ngModel)]="question.connectionOptions" multiple>
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                        >
                          {{ connectionOptions.text }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.connectionOptions">
                      <div class="form-control-holder">
                        <label for="">Soru Metni Giriniz</label>
                        <input [(ngModel)]="question.question.text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="question.isConnected == 'false'">
                <div class="form-control-holder">
                  <label for="">Soru Metni Giriniz</label>
                  <input [(ngModel)]="question.question.text" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="question.kind == 'close'">
            <div class="form-control-holder">
              <label for="">Soru Bağlantılı Mı?</label>
              <select [(ngModel)]="question.isConnected">
                <option [value]="null">Seçiniz</option>
                <option [value]="'true'">Evet</option>
                <option [value]="'false'">Hayır</option>
              </select>
            </div>
            <div *ngIf="question.isConnected">
              <div *ngIf="question.isConnected == 'true'">
                <div class="form-control-holder">
                  <label for="">Bağlantılı Olduğu Soruyu Seçin</label>
                  <select
                    [(ngModel)]="question.connectedQuestionIndex"
                    (change)="handleConnectedQuestion(question, index)"
                  >
                    <option value="">Seçiniz</option>
                    <option
                      *ngFor="
                        let connectedQuestion of surwey.questions;
                        let cqIndex = index
                      "
                      [value]="cqIndex"
                      [attr.disabled]="
                        cqIndex == i || connectedQuestion.kind == 'open'
                          ? true
                          : null
                      "
                    >
                      {{ connectedQuestion.question.text }}
                    </option>
                  </select>
                </div>
                <div *ngIf="question.connectedQuestion">
                  <div *ngIf="question.connectedQuestion.sort == 'range'">
                    <div class="form-control-holder">
                      <label for="">Koşul İçin Minimum Değer</label>
                      <select [(ngModel)]="question.minimumValue">
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                        >
                          {{ connectionOptions.value }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="form-control-holder"
                      *ngIf="question.minimumValue"
                    >
                      <label for="">Koşul İçin Maksimum Değer</label>
                      <select [(ngModel)]="question.maximumValue">
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                          [disabled]="
                            connectionOptions.value < question.minimumValue
                          "
                        >
                          {{ connectionOptions.value }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.minimumValue">
                      <div class="form-control-holder">
                        <label for="">Çapraz Soru Mu?</label>
                        <select [(ngModel)]="question.isRelated">
                          <option [value]="null">Seçiniz</option>
                          <option [value]="'true'">Evet</option>
                          <option [value]="'false'">Hayır</option>
                        </select>
                      </div>
                      <div *ngIf="question.isRelated">
                        <div *ngIf="question.isRelated == 'true'">
                          <div class="form-control-holder">
                            <label for="">Çapraz Soruyu Seçin</label>
                            <select
                              [(ngModel)]="question.relatedQuestionIndex"
                              (change)="handleRelatedQuestion(question)"
                            >
                              <option value="">Seçiniz</option>
                              <option
                                *ngFor="
                                  let relatedQuestion of surwey.questions;
                                  let rqIndex1 = index
                                "
                                [value]="rqIndex1"
                                [attr.disabled]="
                                  rqIndex1 == i ||
                                  relatedQuestion.sort != 'range'
                                    ? true
                                    : null
                                "
                              >
                                {{ relatedQuestion.question.text }}
                              </option>
                            </select>
                          </div>
                          <div *ngIf="question.relatedQuestion">
                            <div class="form-control-holder">
                              <label for=""
                                >Fark kaç ve kaçtan büyük olursa geçersiz
                                say</label
                              >
                              <select
                                [(ngModel)]="question.relatedQuestionError"
                                (change)="show(question.relatedQuestionError)"
                              >
                                <option value="">Seçiniz</option>
                                <option
                                  *ngFor="
                                    let relatedQuestionOption of question
                                      .relatedQuestion.question.options;
                                    let rqIndex2 = $index
                                  "
                                  [value]="relatedQuestionOption.value"
                                >
                                  {{ relatedQuestionOption.value }}
                                </option>
                              </select>
                            </div>
                            <div *ngIf="question.relatedQuestionError">
                              <div class="form-control-holder">
                                <label for="">Soru Metni Giriniz</label>
                                <input [(ngModel)]="question.question.text" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="question.isRelated == 'false'">
                          <div class="form-control-holder">
                            <label for="">Soru Şekli</label>
                            <select [(ngModel)]="question.sort">
                              <option value="multiple">Çoktan Seçmeli</option>
                              <option value="range">Derecelendirmeli</option>
                            </select>
                          </div>
                          <div *ngIf="question.sort">
                            <div *ngIf="question.sort == 'multiple'">
                              <div class="form-control-holder">
                                <label for="">Kaç Şıklı?</label>
                                <select
                                  [(ngModel)]="question.question.optionCount"
                                  (change)="changeOptionCount(question)"
                                >
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                              <div *ngIf="question.question.optionCount">
                                <div class="form-control-holder">
                                  <label for="">Kaç Şık Seçilebilsin</label>
                                  <select
                                    [(ngModel)]="question.question.valueCount"
                                  >
                                    <option
                                      [value]="i"
                                      *ngFor="
                                        let item of question.question.options;
                                        let i = index
                                      "
                                    >
                                      {{ i + 1 }}
                                    </option>
                                  </select>
                                </div>
                                <div
                                  *ngIf="question.question.valueCount !== null"
                                >
                                  <div class="form-control-holder">
                                    <label for="">Soru Metnini Girin</label>
                                    <input
                                      type="text"
                                      [(ngModel)]="question.question.text"
                                    />
                                  </div>
                                  <div
                                    class="form-control-holder"
                                    *ngFor="
                                      let item of question.question.options;
                                      let ix = index
                                    "
                                  >
                                    <label for=""
                                      >{{ ix + 1 }}.Soru Şıkkını Girin</label
                                    >
                                    <input
                                      type="text"
                                      [(ngModel)]="
                                        question.question.options[ix].text
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="question.sort == 'range'">
                              <div class="form-control-holder">
                                <label for="">Kaç Dereceli?</label>
                                <select
                                  [(ngModel)]="question.question.optionCount"
                                  (change)="changeOptionCount(question)"
                                >
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                </select>
                              </div>
                              <div *ngIf="question.question.optionCount">
                                <div class="form-control-holder">
                                  <label for="">Soru Metnini Girin</label>
                                  <input
                                    type="text"
                                    [(ngModel)]="question.question.text"
                                  />
                                </div>
                                <div
                                  class="form-control-holder"
                                  *ngFor="
                                    let item of question.question.options;
                                    let ix = index
                                  "
                                >
                                  <label for=""
                                    >{{ ix + 1 }}.Dereceyi İsimlendirin</label
                                  >
                                  <input
                                    type="text"
                                    [(ngModel)]="
                                      question.question.options[ix].value
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="question.connectedQuestion.sort == 'multiple'">
                    <div class="form-control-holder">
                      <label for="">Hangi Şıklar Varsa Olmalı</label>
                      <select [(ngModel)]="question.connectionOptions" multiple>
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let connectionOptions of question.connectedQuestion
                              .question.options
                          "
                          [value]="connectionOptions.value"
                        >
                          {{ connectionOptions.text }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.connectionOptions">
                      <div class="form-control-holder">
                        <label for="">Çapraz Soru Mu?</label>
                        <select [(ngModel)]="question.isRelated">
                          <option [value]="null">Seçiniz</option>
                          <option [value]="'true'">Evet</option>
                          <option [value]="'false'">Hayır</option>
                        </select>
                      </div>
                      <div *ngIf="question.isRelated">
                        <div *ngIf="question.isRelated == 'true'">
                          <div class="form-control-holder">
                            <label for="">Çapraz Soruyu Seçin</label>
                            <select
                              [(ngModel)]="question.relatedQuestionIndex"
                              (change)="handleRelatedQuestion(question)"
                            >
                              <option value="">Seçiniz</option>
                              <option
                                *ngFor="
                                  let relatedQuestion of surwey.questions;
                                  let rqIndex1 = index
                                "
                                [value]="rqIndex1"
                                [attr.disabled]="
                                  rqIndex1 == i ||
                                  relatedQuestion.sort != 'range'
                                    ? true
                                    : null
                                "
                              >
                                {{ relatedQuestion.question.text }}
                              </option>
                            </select>
                          </div>
                          <div *ngIf="question.relatedQuestion">
                            <div class="form-control-holder">
                              <label for=""
                                >Fark kaç ve kaçtan büyük olursa geçersiz
                                say</label
                              >
                              <select
                                [(ngModel)]="question.relatedQuestionError"
                                (change)="show(question.relatedQuestionError)"
                              >
                                <option value="">Seçiniz</option>
                                <option
                                  *ngFor="
                                    let relatedQuestionOption of question
                                      .relatedQuestion.question.options;
                                    let rqIndex2 = $index
                                  "
                                  [value]="relatedQuestionOption.value"
                                >
                                  {{ relatedQuestionOption.value }}
                                </option>
                              </select>
                            </div>
                            <div *ngIf="question.relatedQuestionError">
                              <div class="form-control-holder">
                                <label for="">Soru Metni Giriniz</label>
                                <input [(ngModel)]="question.question.text" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="question.isRelated == 'false'">
                          <div class="form-control-holder">
                            <label for="">Soru Şekli</label>
                            <select [(ngModel)]="question.sort">
                              <option value="multiple">Çoktan Seçmeli</option>
                              <option value="range">Derecelendirmeli</option>
                            </select>
                          </div>
                          <div *ngIf="question.sort">
                            <div *ngIf="question.sort == 'multiple'">
                              <div class="form-control-holder">
                                <label for="">Kaç Şıklı?</label>
                                <select
                                  [(ngModel)]="question.question.optionCount"
                                  (change)="changeOptionCount(question)"
                                >
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                              <div *ngIf="question.question.optionCount">
                                <div class="form-control-holder">
                                  <label for="">Kaç Şık Seçilebilsin</label>
                                  <select
                                    [(ngModel)]="question.question.valueCount"
                                  >
                                    <option
                                      [value]="i"
                                      *ngFor="
                                        let item of question.question.options;
                                        let i = index
                                      "
                                    >
                                      {{ i + 1 }}
                                    </option>
                                  </select>
                                </div>
                                <div
                                  *ngIf="question.question.valueCount !== null"
                                >
                                  <div class="form-control-holder">
                                    <label for="">Soru Metnini Girin</label>
                                    <input
                                      type="text"
                                      [(ngModel)]="question.question.text"
                                    />
                                  </div>
                                  <div
                                    class="form-control-holder"
                                    *ngFor="
                                      let item of question.question.options;
                                      let ix = index
                                    "
                                  >
                                    <label for=""
                                      >{{ ix + 1 }}.Soru Şıkkını Girin</label
                                    >
                                    <input
                                      type="text"
                                      [(ngModel)]="
                                        question.question.options[ix].text
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="question.sort == 'range'">
                              <div class="form-control-holder">
                                <label for="">Kaç Dereceli?</label>
                                <select
                                  [(ngModel)]="question.question.optionCount"
                                  (change)="changeOptionCount(question)"
                                >
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                </select>
                              </div>
                              <div *ngIf="question.question.optionCount">
                                <div class="form-control-holder">
                                  <label for="">Soru Metnini Girin</label>
                                  <input
                                    type="text"
                                    [(ngModel)]="question.question.text"
                                  />
                                </div>
                                <div
                                  class="form-control-holder"
                                  *ngFor="
                                    let item of question.question.options;
                                    let ix = index
                                  "
                                >
                                  <label for=""
                                    >{{ ix + 1 }}.Dereceyi İsimlendirin</label
                                  >
                                  <input
                                    type="text"
                                    [(ngModel)]="
                                      question.question.options[ix].value
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="question.isConnected == 'false'">
                <div class="form-control-holder">
                  <label for="">Çapraz Soru Mu?</label>
                  <select [(ngModel)]="question.isRelated">
                    <option [value]="null">Seçiniz</option>
                    <option [value]="true">Evet</option>
                    <option [value]="false">Hayır</option>
                  </select>
                </div>
                <div *ngIf="question.isRelated">
                  <div *ngIf="question.isRelated == 'true'">
                    <div class="form-control-holder">
                      <label for="">Çapraz Soruyu Seçin</label>
                      <select
                        [(ngModel)]="question.relatedQuestionIndex"
                        (change)="handleRelatedQuestion(question)"
                      >
                        <option value="">Seçiniz</option>
                        <option
                          *ngFor="
                            let relatedQuestion of surwey.questions;
                            let rqIndex1 = index
                          "
                          [value]="rqIndex1"
                          [attr.disabled]="
                            rqIndex1 == i || relatedQuestion.sort != 'range'
                              ? true
                              : null
                          "
                        >
                          {{ relatedQuestion.question.text }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="question.relatedQuestion">
                      <div class="form-control-holder">
                        <label for=""
                          >Fark kaç ve kaçtan büyük olursa geçersiz say</label
                        >
                        <select
                          [(ngModel)]="question.relatedQuestionError"
                          (change)="show(question.relatedQuestionError)"
                        >
                          <option value="">Seçiniz</option>
                          <option
                            *ngFor="
                              let relatedQuestionOption of question
                                .relatedQuestion.question.options;
                              let rqIndex2 = $index
                            "
                            [value]="relatedQuestionOption.value"
                          >
                            {{ relatedQuestionOption.value }}
                          </option>
                        </select>
                      </div>
                      <div *ngIf="question.relatedQuestionError">
                        <div class="form-control-holder">
                          <label for="">Soru Metni Giriniz</label>
                          <input [(ngModel)]="question.question.text" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="question.isRelated == 'false'">
                    <div class="form-control-holder">
                      <label for="">Soru Şekli</label>
                      <select [(ngModel)]="question.sort">
                        <option value="multiple">Çoktan Seçmeli</option>
                        <option value="range">Derecelendirmeli</option>
                      </select>
                    </div>
                    <div *ngIf="question.sort">
                      <div *ngIf="question.sort == 'multiple'">
                        <div class="form-control-holder">
                          <label for="">Kaç Şıklı?</label>
                          <select
                            [(ngModel)]="question.question.optionCount"
                            (change)="changeOptionCount(question)"
                          >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                        <div *ngIf="question.question.optionCount">
                          <div class="form-control-holder">
                            <label for="">Kaç Şık Seçilebilsin</label>
                            <select [(ngModel)]="question.question.valueCount">
                              <option
                                [value]="i"
                                *ngFor="
                                  let item of question.question.options;
                                  let i = index
                                "
                              >
                                {{ i + 1 }}
                              </option>
                            </select>
                          </div>
                          <div *ngIf="question.question.valueCount !== null">
                            <div class="form-control-holder">
                              <label for="">Soru Metnini Girin</label>
                              <input
                                type="text"
                                [(ngModel)]="question.question.text"
                              />
                            </div>
                            <div
                              class="form-control-holder"
                              *ngFor="
                                let item of question.question.options;
                                let ix = index
                              "
                            >
                              <label for=""
                                >{{ ix + 1 }}.Soru Şıkkını Girin</label
                              >
                              <input
                                type="text"
                                [(ngModel)]="question.question.options[ix].text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="question.sort == 'range'">
                        <div class="form-control-holder">
                          <label for="">Kaç Dereceli?</label>
                          <select
                            [(ngModel)]="question.question.optionCount"
                            (change)="changeOptionCount(question)"
                          >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                          </select>
                        </div>
                        <div *ngIf="question.question.optionCount">
                          <div class="form-control-holder">
                            <label for="">Soru Metnini Girin</label>
                            <input
                              type="text"
                              [(ngModel)]="question.question.text"
                            />
                          </div>
                          <div
                            class="form-control-holder"
                            *ngFor="
                              let item of question.question.options;
                              let ix = index
                            "
                          >
                            <label for=""
                              >{{ ix + 1 }}.Dereceyi İsimlendirin</label
                            >
                            <input
                              type="text"
                              [(ngModel)]="question.question.options[ix].value"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <app-button
        type="small remove"
        text="Soruyu Sil"
        (click)="removeQuestion(i)"
      ></app-button>
    </div>
  </ng-template>

  <app-button
    class="form-button"
    text="Yeni Soru Ekle"
    (click)="addQuestion()"
  ></app-button>
  <app-button
    class="form-button"
    text="Anketi Kaydet"
    type="fancy"
    (click)="saveSurwey()"
  ></app-button>
</div>
