<div class="holder">
    <h1>Faydalı Bilgiler</h1>
    <p>Markayı hedef müşteriye tanıtırken nasıl bir yol izlenmeli?</p>
    <p>İletişim kanallarının çeşitlendiği, tüketicilerle temas kurulan noktaların arttığı, teknolojinin gelişmesi ile birlikte markaların insanlara çok farklı yollarla ulaşabildiği, içerik ve mesaj bombardımanının yaşandığı günümüzde doğru tüketiciye, doğru zamanda, doğru kanalla ve doğru mesajla ulaşmak ve onu gerçek anlamda “yakalamak” her zamankinden daha da kritik hale gelmiş durumda.</p>
    <p>Aslında hepimiz aynı temel arzuları ve ihtiyaçları / motivasyonları paylaşıyoruz. Davranışlarımızı etkileyen motivasyonlar, bulunduğumuz duruma, çevreye, yılın zamanına, haftanın gününe, günün saatine vs göre değişiyor. Bunu anlamak markalar için, insanların hayatına hangi noktada dokunabileceği, hangi noktada dokunamayacağı, onları yakalayabileceği en uygun durum, zaman, kanalın ne olduğunu belirlemek açısından önemli.</p>
    <p>İnsanların gerçek ihtiyaçlarını ve bilinç altında yatan motivasyonlarını ortaya çıkartmak ve anlamak çok da kolay değil elbette. Motivasyonları baz alan stratejilerin de çok sağlam temeller üzerine oturamama riski var.</p>
    <p>Tüketicilerin ihtiyaçlarını ve motivasyonlarını anlamak, bu motivasyonlara göre segmentleri belirlemek ve segmentlerin büyüklüklerini ve ayrıştırıcı özelliklerini ortaya çıkartmak, kategoriyi anlamak ve (yeniden) tanımlamak açısından son derece önemli ve vazgeçilmez olsa da, markaları bu motivasyonlarla ilişkilendirmek de markayı büyütmek, portföyü yönetmek, inovasyon çalışmalarına yön vermek anlamında bir o kadar kritik.</p>
    <p>Tüketicilerin ihtiyaçlarını ve motivasyonlarını anlamak kadar, her motivasyon alanı içinde, markaların bu ihtiyaca uygun olabilmek için sahip olması gereken özellikleri de anlamak, markayı büyütmek ve geliştirmek anlamında önemli. Bir başka deyişle, tüketiciler belli bir motivasyonla seçim yaparken ya da karar alırken markalarda ne gibi fonksiyonal özellikler arıyor, ne tip duygusal faydalar görmek istiyor, kendisini nasıl hissettirmesini bekliyor? Markada görmek istedikleri somut ve rasyonel öğelerin yanı sıra soyut ve duygusal öğeler neler? Censydiam yaklaşımı, her motivasyon alanı içinde, marka seçiminde etkili olan farklı katmanları ortaya çıkartıyor: fonksiyonel özellikler, sosyal kimlik, duygusal faydalar ve kişilik özellikleri.</p>
    <p>Tüketicilerin ihtiyaçlarını ve motivasyonlarını anlamak, bu motivasyonlara göre segmentleri belirlemek ve segmentlerin büyüklüklerini ve ayrıştırıcı özelliklerini ortaya çıkartmak, kategoriyi anlamak ve (yeniden) tanımlamak açısından son derece önemli ve vazgeçilmez olsa da, markaları bu motivasyonlarla ilişkilendirmek de markayı büyütmek, portföyü yönetmek, inovasyon çalışmalarına yön vermek anlamında bir o kadar kritik.</p>
    <p>Sonuç olarak, markalar yolculuklarına üründen, kanaldan, iletişimden, hatta markanın kendisinden de değil, insan ihtiyacından (“renginden”), tüketicileri neyin motive ettiğinden, onları satın almaya yönlendiren temel “NEDEN”den başlamalı. “NEDEN” ile başlayan yolculuk kim, ne, nerede, nasıl, ne zaman ile devam etmeli.</p>

</div>