<header class="header">
  <img src="../../../assets/images/logo.svg" alt="Bilgesin" />
</header>
<div class="uc-container">
  <div class="uc-container--text">
    <span
      >Yapım aşamasındayız.<br />
      20.03.2023’de hizmetinizdeyiz.</span
    >
    <br />
    <button type="button" (click)="showRootLogin = true">
      Giriş yapmak için tıklayınız.
    </button>
  </div>
  <img
    class="uc-container--image"
    src="../../../assets/images/home-image.png"
    alt="Yapım aşamasındayız"
  />
</div>
<section class="uc-seperator">
  <img src="../../../assets/images/section-background.svg" alt="" />
</section>

<div class="popup-holder" *ngIf="showRootLogin">
  <div class="popup-container">
    <div class="popup-header">
      <div class="popup-header-title">Hoşgeldiniz</div>
      <img
        src="/assets/images/popup-close.svg"
        class="popup-header-close"
        (click)="showRootLogin = false"
      />
    </div>
    <div class="popup-content">
      <div class="form-control-holder">
        <label for="email">e-Posta</label>
        <input type="email" id="email" [(ngModel)]="email" #ctrl="ngModel" />
      </div>
      <div class="form-control-holder">
        <label for="password">Şifre</label>
        <app-password-input
          id="password"
          [(model)]="password"
        ></app-password-input>
      </div>
      <div class="login-button-holder">
        <app-button
          type="simple"
          text="Giriş Yap"
          (click)="rootLogin()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
