<div class="holder header-holder">
  <div class="mobile-menu">
    <img
      class="menu-toggler"
      style="cursor: pointer"
      (click)="isMenuShown = !isMenuShown"
      src="../../../assets/images/menu-icon.svg"
    />
    <div class="mobile-menu-content" *ngIf="isMenuShown">
      <img
        class="menu-close-button"
        style="cursor: pointer"
        (click)="isMenuShown = false"
        src="../../../assets/images/close-icon.png"
      />
      <app-menu
        [isMobileMenu]="true"
        [user]="user"
        (closeMenu)="isMenuShown = false"
      ></app-menu>
    </div>
    <div
      class="overlay"
      *ngIf="isMenuShown"
      (click)="isMenuShown = false"
    ></div>
  </div>
  <app-logo imgClass="header-logo"></app-logo>
  <div class="desktop-menu">
    <app-menu></app-menu>
  </div>
  <div *ngIf="user" class="welcome-text-holder">
    <span class="welcome-text">
      <img
        src="../../../assets/images/user-icon.png"
        class="welcome-text-image"
      />
      <span *ngIf="user.name"> Hoşgeldin, {{ fixNameLength(user.name) }}</span>
      <span *ngIf="!user.name"> Hoşgeldin</span>
    </span>
    <div class="menu">
      <a href="/my-earnings">Kazanımlarım</a>
      <a (click)="logout()">Çıkış Yap</a>
    </div>
  </div>
</div>
