import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UserService } from "./services/user.service";
import Hotjar from "@hotjar/browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "bilgesin";
  state = "";
  _isLayout = true;
  get isLayout() {
    return this._isLayout;
  }
  set isLayout(value: boolean) {
    this._isLayout = value;
    setTimeout(() => {
      this.updateBodyPaddingTop();
    }, 250);
  }

  msgText =
    "Merhaba, Bilgesin.com aracılığı ile iletişime geçiyorum. Size danışmak istediğim bir konu var.";

  constructor(router: Router, userService: UserService) {
    userService.controlUnderConstruction();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.state = val.url;
        this.isLayout = true;
        if (
          val.url == "/login" ||
          val.url == "/forgot-password" ||
          val.url.indexOf("/admin") > -1 ||
          val.url == "/email-verification-success" ||
          val.url === "/under-construction"
        ) {
          this.isLayout = false;
        } else {
          this.isLayout = true;
        }
        userService.controlUnderConstruction();
      }
    });
    userService.user.subscribe((x) => {
      if (x) {
        this.isLayout = true;
      }
    });
    setTimeout(() => {
      // window['FB'].login();
    }, 3000);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.updateBodyPaddingTop();
    }, 250);

    this.addGtm();
    this.checkReferrer();
    this.addHotjar();
  }

  checkReferrer() {
    if (environment.hostName() !== "bilgesiniz.com") return;
    if (document.referrer.indexOf("google.com") === -1) return;

    window.location.href = "https://bilgesin.com";
  }

  addGtm() {
    if (environment.hostName() !== "bilgesin.com") {
      return;
    }

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PVWRQBD');
    `;
    s.id = "gtm-head";
    document.getElementsByTagName("head")[0].appendChild(s);

    const nos = document.createElement("noscript");
    nos.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PVWRQBD"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    nos.id = "gtm-body";
    document.getElementsByTagName("body")[0].appendChild(nos);
  }

  addHotjar() {
    if (environment.hostName() !== "bilgesin.com") {
      return;
    }

    const siteId = 3806990;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.updateBodyPaddingTop();
  }

  updateBodyPaddingTop() {
    const headerHolderElm = document.querySelector(".header-holder");
    const pt = this.isLayout ? (headerHolderElm?.clientHeight || 1) - 1 : 0;
    document.querySelector("body").style.paddingTop = `${pt}px`;
  }
}
