<div class="holder" *ngIf="!selectedCompany">
  <h1>Firmalar</h1>
  <span class="message-holder" *ngIf="message">
    {{ message }}
  </span>
  <div>
    <button
      (click)="newCompany()"
      class="btn btn-primary"
      style="margin-bottom: 30px"
    >
      Yeni Firma Ekle
    </button>
    <table class="table table-hover table-bordered">
      <thead>
        <tr>
          <th>Firma Adı</th>
          <th>Anket Doldurulma Sayısı</th>
          <th>Kullanıcı Bilgileri Modülü Açık mı?</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of companies">
          <td>{{ item.name }}</td>
          <td>{{ item.surveyFillerCount || 0 }}</td>
          <td>
            <input
              type="checkbox"
              [(ngModel)]="item.isUserInfoModuleActive"
              (change)="saveCompanyChangesNow(item)"
            />
          </td>
          <td>
            <div class="table-actions">
              <button
                class="btn btn-primary btn-xs"
                (click)="selectCompany(item)"
              >
                Görüntüle
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="holder" *ngIf="selectedCompany">
  <h1>{{ selectedCompany.name }}</h1>
  <span class="message-holder" *ngIf="message">{{ message }}</span>
  <div class="form-control-holder">
    <label for="">Firma Adı</label>
    <input type="text" [(ngModel)]="selectedCompany.name" />
  </div>

  <div class="form-control-holder">
    <label for="">Firma Slug Url</label>
    <input type="text" [(ngModel)]="selectedCompany.slugUrl" />
  </div>

  <div class="form-control-holder">
    <label for="">Firma İli</label>
    <input type="text" [(ngModel)]="selectedCompany.cityName" />
  </div>

  <div class="form-control-holder">
    <label for="">Firma İlçesi</label>
    <input type="text" [(ngModel)]="selectedCompany.townName" />
  </div>

  <div class="form-control-holder">
    <label for="">Firma Sektörü</label>
    <select [(ngModel)]="selectedCompany.sector">
      <ng-container *ngFor="let sector of sectors">
        <option
          [attr.selected]="selectedCompany.sector == sector._id"
          [value]="sector._id"
        >
          {{ sector.name }}
        </option>
      </ng-container>
    </select>
  </div>

  <div class="form-control-holder">
    <label for="">Firma Email</label>
    <input type="text" [(ngModel)]="selectedCompany.email" />
  </div>

  <div class="form-control-holder">
    <label for="">Firma Şifre</label>
    <input type="text" [(ngModel)]="selectedCompany.password" />
  </div>
  <div class="form-control-holder">
    <label for="">Firma Logosu</label>
    <div
      style="display: flex; flex-direction: row; margin-bottom: 3rem; gap: 1rem"
    >
      <div *ngIf="selectedCompany.logo">
        <img
          [src]="selectedCompany.logo"
          alt=""
          style="width: 250px; height: auto"
        />
      </div>
      <input
        type="file"
        [(ngModel)]="selectedCompany.logo"
        (change)="handleUpload($event)"
      />
    </div>
  </div>
  <div class="form-control-holder">
    <label for="isUserInfoModuleActive">
      Kullanıcı Bilgileri Modülü Açık mı?
    </label>
    <input
      id="isUserInfoModuleActive"
      type="checkbox"
      [(ngModel)]="selectedCompany.isUserInfoModuleActive"
    />
  </div>
  <app-button
    type="link"
    text="Firmayı Sil"
    (click)="removeCompany()"
  ></app-button>
  <app-button
    text="Firma Bilgilerini Kaydet"
    (click)="saveCompanyChanges()"
  ></app-button>
</div>
