import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { finalize, take } from "rxjs/operators";
import { verifyPhoneNumber } from "src/app/helpers/helpers";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  _show = false;
  get show() {
    return this._show;
  }
  @Input()
  set show(val: boolean) {
    this._show = val;
    if (val) {
      if (verifyPhoneNumber(this.phoneInput || "", false)) {
        this.sendResetPasswordCode();
      }
    }
  }

  _phone = "";
  get phone() {
    return this._phone;
  }
  @Input()
  set phone(val: string) {
    this._phone = val;
    this.phoneInput = val;
  }

  @Output() hide = new EventEmitter<any>();
  @Input() landingPageKey: string;

  state: "phone" | "code" = "phone";
  phoneInput = "";

  passwordSentDate = new Date();
  passwordSmsSecond = 0;
  passwordInterval;
  passwordSending = false;

  code = "";

  get resendCodeText() {
    if (this.passwordSmsSecond >= 0) {
      return `${this.passwordSmsSecond}sn. - Şifreyi yeniden gönder`;
    }
    return "Şifreyi yeniden gönder";
  }

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.passwordInterval = setInterval(() => {
      const now = new Date();
      this.passwordSmsSecond =
        60 -
        parseInt(
          ((now.getTime() - this.passwordSentDate.getTime()) / 1000).toString()
        );
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.passwordInterval) {
      clearInterval(this.passwordInterval);
    }
  }

  sendResetPasswordCode() {
    if (!verifyPhoneNumber(this.phoneInput)) {
      return;
    }

    this.passwordSending = true;
    this.state = "code";
    this.http
      .post("/forgot", {
        type: "phone",
        phone: this.phoneInput,
      })
      .pipe(
        take(1),
        finalize(() => {
          setTimeout(() => (this.passwordSending = false), 1000);
        })
      )
      .subscribe((res: any) => {
        if (!res.status) {
          alert(res.message);
          this.state = "phone";
        } else {
          this.passwordSentDate = new Date();
        }
      });
  }

  renewPassword() {
    if (!this.code) {
      alert("Girmiş olduğunuz kod geçersizdir.");
      return false;
    }

    this.http
      .post("/renewpassword", {
        type: "phone",
        password: this.code,
        code: this.code,
        phone: this.phoneInput,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res.status) {
          alert("Şifreniz başarıyla değiştirildi");
          this.hide.emit({
            resetStatus: true,
            password: this.code,
            phone: this.phoneInput,
          });
        } else {
          alert(res.message);
        }
      });
  }
}
