import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { scrollToTop } from "src/app/helpers/helpers";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-size-ozel",
  templateUrl: "./size-ozel.component.html",
  styleUrls: ["./size-ozel.component.scss"],
})
export class SizeOzelComponent implements OnInit {
  data = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    scrollToTop();

    this.http
      .get("/getStatistics")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.data = res.slice(Math.max(res.length - 3, 1));
      });
  }
}
