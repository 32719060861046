<div class="holder">
  <div class="links-holder">
    <ul>
      <li><b>Kurumsal</b></li>
      <li>
        <a title="Hakkımızda" routerLink="/hakkimizda" routerLinkActive="active"
          >Hakkımızda</a
        >
      </li>
      <li>
        <a
          title="Neden Bilgesin"
          routerLink="/neden-bilgesin"
          routerLinkActive="active"
          >Neden Bilgesin</a
        >
      </li>
      <li>
        <a
          title="Nasıl Kazanırım"
          routerLink="/nasil-kazanirim"
          routerLinkActive="active"
        >
          Nasıl Kazanırım
        </a>
      </li>
      <li>
        <a
          title="Markalar İçin"
          routerLink="/markalar-icin"
          routerLinkActive="active"
        >
          Markalar İçin
        </a>
      </li>
      <li>
        <a
          title="Yönetim Ekibimiz"
          routerLink="/yonetim-ekibimiz"
          routerLinkActive="active"
        >
          Yönetim Ekibimiz
        </a>
      </li>
      <li>
        <a
          title="İş Ortağımız Olmak İster Misiniz?"
          routerLink="/is-ortagimiz-olmak-ister-misiniz"
          routerLinkActive="active"
        >
          İş Ortağımız Olmak İster Misiniz?
        </a>
      </li>
      <li>
        <a title="Kariyer" routerLink="/kariyer" routerLinkActive="active">
          Kariyer
        </a>
      </li>
    </ul>
    <ul>
      <li><b>Müşteri Hizmetleri</b></li>
      <li>
        <a title="İletişim" routerLink="/iletisim" routerLinkActive="active">
          İletişim
        </a>
      </li>
      <li>
        <a
          title="Kullanım Koşulları"
          routerLink="/kullanim-kosullari"
          routerLinkActive="active"
        >
          Kullanım Koşulları
        </a>
      </li>
      <li>
        <a
          title="Gizlilik Politikası"
          routerLink="/gizlilik-politikasi"
          routerLinkActive="active"
        >
          Gizlilik Politikası
        </a>
      </li>
      <li>
        <a
          title="Kişisel Verilerin Korunması"
          routerLink="/kisisel-verilerin-korunmasi"
          routerLinkActive="active"
        >
          Kişisel Verilerin Korunması
        </a>
      </li>
      <li>
        <a
          title="Musteri Kisisel Verileri Aydinlatma Metni"
          routerLink="/musteri-kisisel-verileri-aydinlatma-metni"
          routerLinkActive="active"
        >
          Müşteri Kişisel Verileri Aydınlatma Metni
        </a>
      </li>
    </ul>
    <ul>
      <li><b>Bizi takip edin</b></li>
      <li>
        <a
          title="Bizden Haberler"
          routerLink="/bizden-haberler"
          routerLinkActive="active"
        >
          Bizden Haberler
        </a>
      </li>
      <li>
        <a
          title="Blog"
          routerLink="/blog"
          routerLinkActive="active"
          target="_blank"
          >Blog</a
        >
      </li>

      <!-- <li>
        <a
          title="Faydali Bilgiler"
          routerLink="/faydali-bilgiler"
          routerLinkActive="active"
          >Faydalı Bilgiler</a
        >
      </li> -->
    </ul>
    <ul>
      <li><b>Bilgesin'i Keşfet</b></li>
      <li *ngIf="!user">
        <a
          title="Giriş Yap / Kayıt Ol"
          routerLink="/login"
          routerLinkActive="active"
        >
          <b>Giriş Yap / Kayıt Ol</b>
        </a>
      </li>
      <li>
        <button
          class="search-button"
          type="button"
          (click)="searchModalVisible = true"
        >
          <img
            src="/assets/images/search-icon.png"
            alt="Search"
            height="20px"
          />
          Bilgesin'de Arayın
        </button>
      </li>
    </ul>
  </div>
  <div class="bottom">
    <a class="logo-holder" href="javascript:;" (click)="navigateToCampaigns()">
      <img src="../../../assets/images/logo.svg" alt="" />
    </a>
    <span>© {{ copyrightYear }} • Bilgesin Inc • All Rights Reserved</span>
    <div class="social-logos">
      <a href="https://www.instagram.com/bilgesin_com/" target="_blank">
        <img src="/assets/images/instagram-logo.svg" alt="IN" />
      </a>
      <a href="https://www.facebook.com/bilgesincom" target="_blank">
        <img src="/assets/images/facebook-logo.svg" alt="FB" />
      </a>
      <a href="https://www.linkedin.com/company/bilgesin-com/" target="_blank">
        <img src="/assets/images/linkedin.png" alt="LinkedIn" />
      </a>
    </div>
  </div>
</div>

<app-search-modal [(visible)]="searchModalVisible"></app-search-modal>
