<div class="holder">
    <h1>Neden Bilgesin?</h1>
    <div class="holder-inner">
        <img src="/assets/images/how-to-win.jpg" />
        <div class="seperator">
            <p>Bilgesin aracılığı ile markalarla düşünce ve fikirlerinizi paylaşarak indirim kazanabilirsiniz. </p>
            <p>Fikirleriniz ve düşünceleriniz değerlidir. Bütün uzun yolculukların bir küçük adımla başladığını unutmayın.
                Şubelerini, mağazalarını, internet sitelerini ziyaret ettiğiniz ve reklamlarını gördüğünüz markalar sizlerin
                fikirleri ve düşünceleri sayesinde daha iyi bir noktaya gelirler. Sizlerin paylaştıkları, yeni ürün veya hizmet
                oluşturmalarına ve mevcut olanları geliştirmelerini sağlayacaktır. </p>
            <p>Bilgesin herkesin birbirine ihtiyacı olduğu ve fayda sağlayabileceği düşüncesi temeliyle kazan kazan
                kültürüyle kurulmuştur. </p>
            <p>Hepiniz bilgesiniz. Bildiklerinizi kendinize saklamayın, bizlere iletin. Bu sayede markaların kendilerini
                geliştirmesini sağlayarak indirim fırsatlarını hak ederek kazanın 😊</p>
        </div>
    </div>
</div>