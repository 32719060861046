import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-admin-cozum-gerektiren-konu",
  templateUrl: "./admin-cozum-gerektiren-konu.component.html",
  styleUrls: ["./admin-cozum-gerektiren-konu.component.scss"],
})
export class AdminCozumGerektirenKonuComponent implements OnInit {
  data: any[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http.get("/cozum-gerektiren-konu-basliklari").subscribe((res: any) => {
      this.data = res.items;
    });
  }

  getOtherTitles() {
    let lengths = [];
    for (const item of this.data) {
      lengths.push(item.referenceActions.length);
    }
    const max = Math.max(...lengths);
    let titles = [];
    for (let i = 0; i < max; i++) {
      titles.push(`Aksiyon Alındı`);
      titles.push(`Referans`);
    }
    return titles;
  }

  getOtherValues(item) {
    let values = [];
    for (const action of item.referenceActions) {
      values.push(action.actionTaken);
      values.push(action.reference);
    }
    return values;
  }
}
