import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-admin-company-info',
  templateUrl: './admin-company-info.component.html',
  styleUrls: ['./admin-company-info.component.scss']
})
export class AdminCompanyInfoComponent implements OnInit {

  message = "";
  company = {
    name: "",
    sector: "",
    logo: "",
  };
  imagePreview: any;
  file: any;

  constructor(
    private _sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.http.get('/getAdminCompanyInfo').pipe(take(1)).subscribe((res: any) => {
      this.company.name = res.name;        
      this.imagePreview = res.logo;
    });
  }

  fileChanged(e) {
      this.file = e.target.files[0];
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.imagePreview = fileReader.result;
      }
      fileReader.readAsDataURL(this.file);
  }

  getBackground(image) {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  saveCompanyInfo() {
    this.http.post('/saveAdminCompanyInfo', {
      name: this.company.name,
      logo: this.imagePreview,
    }).pipe(take(1)).subscribe((res: any) => {
      this.message = "Firma Bilgileri başarıyla kaydedildi!";
    });
  }
}
