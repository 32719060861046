<button (click)="onAdd()" class="btn btn-primary" style="margin-bottom: 30px">
  Yeni Ekle
</button>

<table class="table table-hover table-bordered">
  <thead>
    <tr>
      <th>Kupon Kodu</th>
      <th>Oluşturma Tarihi</th>
      <th>İşlemler</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of coupons">
      <td>{{ item.couponCode }}</td>
      <td align="center">{{ item.createdOn | date : "dd.MM.yyyy" }}</td>
      <td>
        <div class="table-actions">
          <button class="btn btn-warning btn-xs" (click)="onEdit(item)">
            Düzenle
          </button>
          <button class="btn btn-danger btn-xs" (click)="onDelete(item)">
            Sil
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="modal-wrapper" *ngIf="modalVisible">
  <div class="modal-container">
    <div class="modal-header">
      <h3>Form Kuponu</h3>
      <div class="modal-close" (click)="modalVisible = false"></div>
    </div>

    <div class="modal-body">
      <div class="form-control-holder">
        <label for="">Kupon Kodu <b>*</b></label>
        <input type="text" [(ngModel)]="selected.couponCode" />
      </div>
      <button (click)="save()" class="btn btn-primary" style="margin-top: 15px">
        KAYDET
      </button>
    </div>
  </div>
</div>
