<div class="holder">
    <h1>Firma Bilgileri</h1>
    <span class="message-holder" *ngIf="message">{{message}}</span>
    <div class="form-control-holder">
        <label for="">Firma Adı</label>
        <input type="text" [(ngModel)]="company.name">
    </div>
    
    <div *ngIf="imagePreview" [style.background-image]="getBackground(imagePreview)" class="image-preview"></div>
    
    <div class="form-control-holder">
        <label for="">Logo</label>
        <input type="file" [(ngModel)]="company.logo" (change)="fileChanged($event)">
    </div>
    <app-button type="fancy" text="Firma Bilgilerini Kaydet" (click)="saveCompanyInfo()"></app-button>
</div>