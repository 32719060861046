import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
})
export class LogoComponent implements OnInit {
  user: any;

  @Input() imgClass: string;

  constructor(private router: Router, public userService: UserService) {}

  ngOnInit(): void {
    this.userService.user.subscribe((x) => {
      this.user = x;
    });
  }

  goToHome() {
    this.router.navigate(["/"]);
  }
}
