import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Input() page: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() totalCount: number;
  @Input() rowCount: number;

  get pageCount() {
    if (!this.totalCount || !this.rowCount) {
      return 0;
    }
    return Math.ceil(this.totalCount / this.rowCount);
  }

  get pageItems() {
    const output = [];
    for (let i = 0; i < this.pageCount; i++) {
      output.push(i + 1);
    }
    return output;
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  change(newPage: number) {
    this.pageChange.emit(newPage);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { page: (newPage + 1).toString() },
      queryParamsHandling: "merge",
    });
  }
}
