import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-facebook-privacy",
  templateUrl: "./facebook-privacy.component.html",
  styleUrls: [],
})
export class FacebookPrivacyComponent implements OnInit {
  constructor() {}

  hostName = environment.hostName();

  ngOnInit(): void {
    scrollToTop();
  }
}
