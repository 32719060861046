<button
  class="{{ type }}"
  [attr.title]="text"
  [disabled]="disabled"
  [ngClass]="{ disabled: disabled }"
>
  <div
    [ngClass]="{
      'curvy-inner': type.indexOf('curvy') > -1
    }"
    [innerHTML]="text"
  >
    {{ text }}
  </div>
</button>
