<div class="holder" *ngIf="showReport">
  <h1>Raporlar</h1>
  <div class="filter">
    <div class="row">
      <div class="form-control">
        <label for="">Rapor Türü</label>
        <select [(ngModel)]="reportType" (change)="isReportReady = false">
          <option value="">Seçiniz</option>
          <option value="survey">Anket</option>
          <option value="question">Soru</option>
        </select>
      </div>
      <div class="form-control">
        <label for="">Anket</label>
        <select
          [(ngModel)]="selectedSurveyId"
          (change)="handleQuestions()"
          [disabled]="reportType === ''"
        >
          <option value="">Seçiniz</option>
          <option *ngFor="let item of surveys" [value]="item._id">
            {{ item.survey.name }}
          </option>
        </select>
      </div>
      <!-- Rapor turu soru -->
      <div class="form-control">
        <div>
          <label for="">Soru</label>
          <select
            [disabled]="reportType !== 'question'"
            [(ngModel)]="selectedQuestionIndex"
            (change)="isReportReady = false"
          >
            <option value="">Seçiniz</option>
            <option *ngFor="let item of questions; index as i" [value]="i">
              {{ item.question.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-control">
        <label for="">Tarih Aralığı</label>
        <input
          type="text"
          ngxDaterangepickerMd
          [(ngModel)]="selectedDate"
          class="form-control"
          (change)="isReportReady = false"
        />
      </div>

      <div class="form-control">
        <label for="">Frekans</label>
        <select
          [(ngModel)]="selectedFrequency"
          (change)="isReportReady = false"
        >
          <option value="">Seçiniz</option>
          <option value="week">Hafta</option>
          <option value="month">Ay</option>
        </select>
      </div>
      <div class="form-control" *ngIf="showDistributeOptions">
        <label for="">Şıklar Dağıtılsın Mı?</label>
        <select
          [(ngModel)]="distributeOptions"
          (change)="isReportReady = false"
        >
          <option value="">Seçiniz</option>
          <option value="yes">Evet</option>
          <option value="no">Hayır</option>
        </select>
      </div>
      <div class="form-control" style="padding-top: 30px">
        <app-button text="Raporu Getir" (click)="getReport()"></app-button>
      </div>
    </div>
  </div>
  <br />
  <div *ngIf="isReportReady">
    <div class="excel-download-container">
      <app-button text="Excel İndir" (click)="downloadExcel()"></app-button>
    </div>
    <div *ngIf="reportType == 'survey'">
      <div class="data-holder">
        <div class="chart-holder">
          <ag-charts-angular [options]="optionsFill"> </ag-charts-angular>
        </div>
        <div class="table-holder">
          <table>
            <thead>
              <tr>
                <th>Tarih</th>
                <th>Sayı</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of optionsFill.data">
                <td>{{ item["tableTitle"] }}</td>
                <td>{{ item["count"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="data-holder">
        <div class="chart-holder">
          <ag-charts-angular [options]="optionsAge"> </ag-charts-angular>
        </div>
        <div class="table-holder">
          <table>
            <thead>
              <tr>
                <th>Tarih</th>
                <th>0-18</th>
                <th>18-25</th>
                <th>25-35</th>
                <th>35-50</th>
                <th>50+</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of optionsAgeData">
                <td>{{ item["tableTitle"] }}</td>
                <td>{{ item["0"] }}</td>
                <td>{{ item["1"] }}</td>
                <td>{{ item["2"] }}</td>
                <td>{{ item["3"] }}</td>
                <td>{{ item["4"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="data-holder">
        <div class="chart-holder">
          <ag-charts-angular [options]="optionsGenderAvg"> </ag-charts-angular>
        </div>
        <div class="table-holder">
          <table>
            <thead>
              <tr>
                <th>Tarih</th>
                <th>Erkek</th>
                <th>Kadın</th>
                <th>Diğer</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of optionsGender.data">
                <td>{{ item["tableTitle"] }}</td>
                <td>{{ item["man"] }}</td>
                <td>{{ item["woman"] }}</td>
                <td>{{ item["other"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="reportType == 'question' && selectedQuestion?.kind !== 'open'">
      <div class="data-holder">
        <div class="chart-holder">
          <ag-charts-angular [options]="optionsQuestion"></ag-charts-angular>
        </div>
        <div class="chart-holder" *ngIf="showDistributeOptions">
          <ag-charts-angular [options]="optionsQuestionPie"></ag-charts-angular>
        </div>
        <div class="table-holder">
          <table>
            <thead>
              <tr>
                <th>Tarih</th>
                <th *ngFor="let item of optionsQuestion.series[0].yNames">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of optionsQuestion.data">
                <td>{{ item["date"] }}</td>
                <td *ngFor="let key of optionsQuestion.series[0].yKeys">
                  {{ item[key + "_"] || 0 }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="reportType == 'question' && selectedQuestion?.kind === 'open'">
      <div class="data-holder">
        <h3 style="text-align: center; margin: 10px 0">{{ openTitle }}</h3>
        <div class="table-holder">
          <table>
            <thead>
              <tr>
                <th>Tarih</th>
                <th>Cevap</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of openQuestionData">
                <td>{{ item["date"] }}</td>
                <td>{{ item["answer"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="holder" *ngIf="!showReport">
  <h1>
    Güncelleme sebebi ile bu modüle şu anda erişim sağlanamamaktadır. Bu modül
    19.08.2024 itibari ile tekrar kullanıma açılacaktır.
  </h1>
</div>
