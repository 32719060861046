<div class="holder">
    <h1>Gizlilik Politikası</h1>
    <p>Bilgesin Bilişim (“Bilgesin”) olarak, hizmetlerimizden yararlanan kullanıcılarının (“Kullanıcı”) bilgilerinin korunmasına ve gizliliğine önem veriyoruz. Bu Gizlilik Politikası (“Politika”) Bilgesin’in topladığı bilgileri nasıl kullandığı, kullanıcıların sorumlulukları ve Bilgesin’in gizli bilgilerin korunmasına ilişkin kuralları hakkında kullanıcıları bilgilendirmeyi amaçlamaktadır.</p>
    <h3>1. Topladığımız Bilgiler</h3>
    <p>Bilgesin hizmetlerinizden yararlanmanız durumunda, üyelik kaydınızı gerçekleştirmek, talep ettiğiniz ürün/hizmetleri size temin etmek için belirli bilgileriniz toplanmaktadır.</p>
    <p>Kullanıcılar, sipariş vermek, talep, öneri ve şikayetlerini Bilgesin’e iletmek istediklerinde veya sair nedenlerle Bilgesin’e bilgilerini sağlamaktadır. Bu durumda, kullanıcıların ad-soyad, iletişim bilgileri, talep, öneri şikâyet bilgileri gibi belirli bilgileri toplanmaktadır.</p>
    <h3>2. Bilgileri Kullanma Amaçlarımız</h3>
    <p>Bilgesin, kullanıcılara ilişkin topladığı bilgileri başlıca aşağıda belirtilen amaçlarla kullanılmaktadır:</p>
    <ul>
        <li>Kullanıcılara hizmet sunmak</li>
        <li>Kullanıcılar ile iletişime geçmek, kullanıcıların talep ve şikayetlerini sonuçlandırmak,
        </li>
        <li>Hizmetlerimizin tanıtımını sağlamak; reklam ve kampanya süreçlerini yürütmek,
        </li>
        <li>Kullanıcı geri bildirimlerine göre ürün ve hizmetlerimizi iyileştirmek/geliştirmek,
        </li>
        <li>Hizmetlerimizi kullanıcıların tercih, kullanım alışkanlıkları ve ilgi alanlarına göre özelleştirmek,
        </li>
        <li>Hizmetlerimizi sunduğumuz ortamlardaki sistemsel sorunları tanımlanmak ve gidermek; bilgi güvenliği süreçlerini yürütmek,</li>
        <li>Tanımlama teknolojileri vasıtasıyla kullanıcı deneyimlerini ölçümlemek, hizmetlerimizi sunduğumuz ortamların performansını geliştirmek.</li>
    </ul>
    <p>Bu kısımda belirtilen amaçlar, kullanıcılara ilişkin toplanan bilgilerin genel kullanılma amaçlarını ifade etmektedir. Bilgesin’in müşteri kişisel verilerini işleme amaçları ve kişisel verilerin işlenmesi hakkında diğer konularda bilgi almak için lütfen 
        Müşteri Kişisel Verilerinin Korunması Politikası'nı inceleyiniz.</p>
        <h3>3. Bilgilerinizin Üçüncü Kişiler Aktarılması
        </h3>
        <p>Bilgesin’e sağladığınız bilgiler, yukarıda belirtilen amaçların gerçekleştirilebilmesi için üçüncü kişilere aktarılabilir (örneğin teknik destek hizmetinin alınabilmesi amacıyla bilgilerinizin bu konuda hizmet veren firma ile paylaşılması gerekebilir). Bu kapsamda bilgileriniz, iş ortaklarımız ve ürün/hizmet tedariki kapsamında destek alınan tedarikçiler ile paylaşılabilecektir. Bununla birlikte, yasal yükümlülüklerimizi yerine getirebilmek için bilgilerinizi paylaşmamız talep edildiğinde, bilgileriniz yetkili kişi, kurum ve kuruluşlar ile de paylaşılabilecektir.</p>
        <p>Kullanıcıların sağladığı bilgiler, kullanıcılara sunulan metinlerde belirtilen amaçlar ve hizmetin sunulabilmesi için gereken durumlar haricinde üçüncü kişilere aktarılmayacaktır.</p>
        <h3>4. Bilgilerinizin Saklanması Ve Korunması
        </h3>
        <p>Bilgileriniz, bilgileri toplama amacımızın gerektirdiği süre kadar saklanmaktadır. Mevzuatta bilgilerinizi saklamamız için özel bir süre öngörülüyor ise, bu süreye uygunluk sağlanmaktadır. Bilgesin, bilgilerinizin muhafaza edildiği sistemleri, sunucuları ve veri tabanlarını, veri güvenliği tehditlerine (siber saldırı, hash vb.) karşı korumak için gerekli teknik ve idari tedbirleri almaktadır.</p>
        <p>Kullanıcı, Bilgesin tarafından sunulan hizmetlerden yararlanabilmek amacıyla kullandığı sistem erişim araçlarının (kullanıcı ismi, şifre vb.) güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması ile ilgili tüm sorumluluğun tamamen kendisine ait olduğunu kabul etmektedir.</p>
        <h3>Bilgilerinizin Doğruluğu ve Güncelliği</h3>
        <p>Kullanıcıların sağladığı bilgilerin doğruluğu ve güncelliği kullanıcıların sorumluluğundadır. Bilgilerinizin gerçeğe uygun olarak tarafımıza iletilmesi ve bilgilerinizde bir değişiklik olması durumunda güncellenmesi gerekmektedir. Bilgesin ile paylaşmış olduğunuz bilgileri, Bilgesin müşteri hizmetlerini arayarak dileğiniz zaman güncelleyebilirsiniz.
            Bilgesin’e bir başkası adına bilgi sağladığınızda, bu bilgilerin doğru olduğunu, bilgileri Bilgesin ile paylaşma hakkına sahip olduğunuzu/bu konuda yetkilendirildiğinizi taahhüt etmiş sayılırsınız.</p>
            <h3>6. Üçüncü Taraflara Yapılan Yönlendirmeler</h3>
            <p>Bilgesin, Bilgesin tarafından işletilmeyen üçüncü taraf bağlantılara yönlendirmeler yapabilir. İlgili bağlantılardan herhangi birini ziyaretiniz sırasında, ziyaret ettiğiniz üçüncü tarafın gizlilik politikaları ve kullanım koşullarına tabi olursunuz. Bilgesin söz konusu üçüncü tarafların politika ve uygulamalarından sorumlu değildir.</p>
            <h3>7. Mobil Uygulamada, Web Sitesinde ve Bu Politikada Yapılan Değişiklikler
            </h3>
            <p>Bilgesin, sunulan hizmetleri, bilgileri, görselleri ve sair unsurları önceden bildirimde bulunmaksızın değiştirme, kullanımını askıya alma veya durdurma hakkını saklı tutmaktadır.
                Bilgesin, bu Politika üzerinde değişiklik ve güncelleme yapma hakkını saklı tutmaktadır. Politika’nın güncel versiyonunu takip etmek, kullanıcının sorumluluğundadır.</p>
                <h3>8. Sorumluluk
                </h3>
                <p>Hizmetlerimizin elektronik ortamda sunulması sırasında oluşabilecek herhangi bir hata, kesinti, gecikme, virüs, hat ve/veya sistem arızası sonucunda ortaya çıkabilecek doğrudan veya dolaylı zarar ve masraflardan Bilgesin sorumlu değildir.</p>
                
</div>