import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit, OnDestroy {
  user;
  state = 0;
  statisticsCount = "";
  adminCompany;

  _menuOpen = false;
  get menuOpen() {
    return this._menuOpen;
  }
  set menuOpen(val: boolean) {
    this._menuOpen = val;
    const bodyClass = document.querySelector("body").classList;
    if (val) {
      bodyClass.remove("sidebar-hide");
    } else {
      bodyClass.add("sidebar-hide");
    }
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    public userService: UserService
  ) {
    this.userService.user.subscribe((x) => {
      this.user = x;
    });
    if (!this.menuOpen) {
      document.querySelector("body").classList.add("sidebar-hide");
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem("adminState")) {
      this.state = Number(localStorage.getItem("adminState"));
      if (this.user && this.user.isAdmin && this.state >= 20) {
        this.state = 10;
      }
    }

    if (this.user?.isAdmin) {
      this.http
        .get("/getStatisticsCount")
        .pipe(take(1))
        .subscribe((res: any) => {
          this.statisticsCount = res.count;
        });

      this.http
        .get("/getAdminCompanyInfo")
        .pipe(take(1))
        .subscribe((res: any) => {
          this.adminCompany = res;
        });
    }
  }

  ngOnDestroy(): void {
    document.querySelector("body").classList.remove("sidebar-hide");
  }

  changeState(state) {
    this.state = state;
    localStorage.setItem("adminState", state);
  }

  logout() {
    localStorage.removeItem("user");
    this.router.navigate(["/login"]);
  }
}
