import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-kariyer",
  templateUrl: "./kariyer.component.html",
  styleUrls: ["./kariyer.component.scss"],
})
export class KariyerComponent implements OnInit {
  hostName = environment.hostName();

  constructor() {}

  ngOnInit(): void {
    scrollToTop();
  }
}
