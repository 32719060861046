<div class="holder">
  <h1>Kariyer</h1>
  <p>
    Bilgesin’in güçlü ve dinamik ekibine katılmak ister misiniz? Sizler de kazan
    kazan felsefesini Türkiye’de ve Dünyada yaygınlaştırarak insanlığa katkı
    sağlamak isterseniz özgeçmişinizi
    <a href="mailto:kariyer@{{ hostName }}"> kariyer@{{ hostName }} </a>’a
    gönderin. Size en kısa sürede geri dönüş sağlayacağız.
  </p>
</div>
