<button (click)="onAdd()" class="btn btn-primary" style="margin-bottom: 30px">
  Yeni Ekle
</button>

<table class="table table-hover table-bordered">
  <thead>
    <tr>
      <th>Şirket</th>
      <th>Öğe Sayısı</th>
      <th>İşlemler</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of data">
      <td>{{ companyName(item.companyId) }}</td>
      <td>{{ item.items.length }}</td>
      <td>
        <div class="table-actions">
          <button class="btn btn-warning btn-xs" (click)="onEdit(item)">
            Düzenle
          </button>
          <button class="btn btn-danger btn-xs" (click)="onDelete(item)">
            Sil
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="modal-wrapper" *ngIf="modalVisible">
  <div class="modal-container">
    <div class="modal-header">
      <h3>Çözüm Gerektiren Konu Başlıkları</h3>
      <div class="modal-close" (click)="modalVisible = false"></div>
    </div>

    <div class="modal-body">
      <div class="form-control-holder">
        <label for="">Şirket <b>*</b></label>
        <select [(ngModel)]="selected.companyId">
          <option *ngFor="let company of companies" [value]="company._id">
            {{ company.name }}
          </option>
        </select>
      </div>

      <button
        class="btn btn-success btn-xs"
        style="margin-bottom: 15px"
        (click)="onAddItem()"
      >
        Öğe Ekle
      </button>

      <div class="card" *ngFor="let item of selected.items; let i = index">
        <div class="card-body">
          <button class="btn btn-danger btn-xs" (click)="onDeleteItem(i)">
            Sil
          </button>

          <div class="form-control-holder">
            <label for="">{{ i + 1 }}. Başlık <b>*</b></label>
            <input type="text" [(ngModel)]="item.title" />
          </div>

          <div class="form-control-holder">
            <label for="">Referans</label>
            <input type="text" [(ngModel)]="item.reference" />
          </div>

          <div class="form-control-holder">
            <label for="">Demografi</label>
            <input type="text" [(ngModel)]="item.demography" />
          </div>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Aksiyon Alındı</th>
                <th>Referans</th>
                <th>İşlemler</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let referenceAction of item.referenceActions;
                  let j = index
                "
              >
                <td>
                  <div class="form-control-holder">
                    <input
                      type="text"
                      [(ngModel)]="referenceAction.actionTaken"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-control-holder">
                    <input
                      type="text"
                      [(ngModel)]="referenceAction.reference"
                    />
                  </div>
                </td>
                <td>
                  <button
                    class="btn btn-danger btn-xs"
                    (click)="onDeleteReferenceAction(i, j)"
                  >
                    Sil
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <button
            class="btn btn-success btn-xs"
            (click)="onAddReferenceAction(i)"
          >
            Referans Ekle
          </button>
        </div>
      </div>

      <br />
      <button (click)="save()" class="btn btn-primary" style="margin-top: 15px">
        KAYDET
      </button>
    </div>
  </div>
</div>
