import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-bizden-haberler",
  templateUrl: "./bizden-haberler.component.html",
  styleUrls: ["./bizden-haberler.component.scss"],
})
export class BizdenHaberlerComponent implements OnInit {
  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title:
        "Bizden Haberler - Anket doldurarak para kazanmak için Bilgesin'de buluşalım - Bilgesin",
      description:
        "Anket doldurarak para kazanma yorum yapma ile sağlanır. Güvenilir para kazanma sitelerini bulmak için sayfalarımızı inceleyin.",
    });
  }
}
