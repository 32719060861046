<div class="title-container">
  <h2 style="margin-bottom: 0">Pazanova</h2>
  <div style="display: flex">
    <app-button text="Excel İndir" (click)="downloadExcel()"></app-button>
    &nbsp;&nbsp;&nbsp;
    <app-button
      text="Karşılaştırmalı Excel İndir"
      (click)="downloadComparedExcel()"
    ></app-button>
  </div>
</div>

<div *ngFor="let lp of landingPages" class="lp-container">
  <h5>{{ lp.key.toUpperCase() }}</h5>
  <span>
    <b>{{ lp.pageViewCount }}</b> Gösterim -
    <b>{{ lp.statistics.length }}</b> Dolduran
  </span>
  <a [href]="lp.fullUrl" target="_blank">
    {{ lp.fullUrl }}
  </a>

  <table class="table table-hover table-bordered">
    <thead>
      <tr>
        <th>Telefon No</th>
        <th>Tarih Saat</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let stat of lp.statistics; index as i">
        <td>{{ i + 1 }}. {{ stat.userPhone }}</td>
        <td>{{ stat.createdOn | date : "dd.MM.yyyy HH:mm" }}</td>
      </tr>
    </tbody>
  </table>
</div>
