<div class="holder">
    <h1>Sorular</h1>
    <span class="message-holder" *ngIf="message">
        {{message}}
    </span>
    <div *ngIf="state==''">
        <ul>
            <li *ngFor="let item of sectors"><span style="margin-right:2rem">{{item.name}}</span><span
                    (click)="selectSector(item)">Görüntüle</span></li>
        </ul>
        <app-button text="yeni ekle" (click)="newQuestion()"></app-button>
    </div>

    <div *ngIf="state=='new'">
            <h1>Yeni Soru</h1>
            <span class="message-holder" *ngIf="message">{{message}}</span>
            <div class="form-control-holder">
                <label for="">Soru Tipi - {{question.type}}</label>
                <select [(ngModel)]="question.type">
                    <option value="database">Veri Tabanı</option>
                    <option value="new">Yeni Soru</option>
                </select>
            </div>
            <div class="form-control-holder">
                <label for="">Soru Tarzı - {{question.kind}}</label>
                <select [(ngModel)]="question.kind">
                    <option value="open">Açık Uçlu</option>
                    <option value="close">Kapalı Uçlu</option>
                </select>
            </div>
            <div *ngIf="question.kind == 'close'">
                <div class="form-control-holder" >
                    <label for="">Soru Şekli - {{question.sort}}</label>
                    <select [(ngModel)]="question.sort">
                        <option value="multiple">Çoktan Seçmeli</option>
                        <option value="range">Derecelendirmeli</option>
                    </select>
                </div>
            </div>

            <div *ngIf="question.type && question.kind">
                <div *ngIf="question.type == 'database'">
                    <div class="form-control-holder">
                        <label for="">Soruyu Seçin</label>
                        <select [(ngModel)]="question.question"></select>
                    </div>
                </div>
                <div *ngIf="question.type == 'new'">
                    <div *ngIf="question.kind == 'open'">
                        <div class="form-control-holder">
                            <label for="">Soru Metnini Girin</label>
                            <input type="text" [(ngModel)]="question.question.text">
                        </div>
                    </div>
                    <div *ngIf="question.kind == 'close'">
                        <div *ngIf="question.sort == 'multiple'">
                            <div class="form-control-holder">
                                <label for="">Kaç Şıklı?</label>
                                <select [(ngModel)]="question.question.optionCount" (change)="changeOptionCount()">
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                            <div class="form-control-holder" *ngIf="question.question.optionCount">
                                <label for="">Kaç Şık Seçilebilsin</label>
                                <select [(ngModel)]="question.question.valueCount">
                                    <option [value]="i" *ngFor="let item of question.question.options; let i = index">{{i+1}}</option>
                                </select>
                            </div>
                            <div *ngIf="question.question.optionCount && question.question.valueCount">
                                <div class="form-control-holder">
                                    <label for="">Soru Metnini Girin</label>
                                    <input type="text" [(ngModel)]="question.question.text">
                                </div>
                                <div class="form-control-holder"
                                    *ngFor="let item of question.question.options; let i = $index">
                                    <label for="">Soru Şıkkını Girin</label>

                                    <input type="text" [(ngModel)]="question.question.options[i]">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="question.kind == 'ranged'">
                            <div class="form-control-holder">
                                <label for="">Kaç Derece?</label>
                                <select [(ngModel)]="question.question.rangeCount"></select>
                            </div>
                            <div *ngIf="question.question.rangeCount">
                                <div class="form-control-holder">
                                    <label for="">Soru Metnini Girin</label>
                                    <input type="text" [(ngModel)]="question.question.text">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <app-button text="Yeni Seçenek Ekle" type="link" *ngIf="question.type == 'CoktanSecmeli'"
                (click)="addOption()"></app-button>

            <hr>

            <app-button class="form-button" text="Soruyu Sil" type="link" (click)="removeQuestion()"></app-button>

            <app-button class="form-button" text="Soruyu Kaydet" (click)="saveQuestion()"></app-button>

    </div>
</div>