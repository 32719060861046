<table class="table table-hover table-bordered">
  <thead>
    <tr>
      <th>Başlık</th>
      <th>Demografi</th>
      <th>Referans</th>
      <th *ngFor="let item of getOtherTitles()">
        {{ item }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of data">
      <td>{{ item.title }}</td>
      <td>{{ item.demography }}</td>
      <td>{{ item.reference }}</td>
      <td *ngFor="let other of getOtherValues(item)">
        {{ other }}
      </td>
    </tr>
  </tbody>
</table>
