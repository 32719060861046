<div class="holder">
  <h1>Kullanım Koşulları</h1>
  <p>
    NOT: BU KULLANIM KOŞULLARI YALNIZCA TÜRKİYE’DE BULUNAN KULLANICILARA İLİŞKİNDİR. WEBSİTESİNİ TÜRKİYE DIŞINDA KULLANMANIZ HALİNDE BULUNDUĞUNUZ LOKASYONA GÖRE FARKLI KULLANIM KOŞULLARI GEÇERLİ OLACAKTIR. WEBSİTESİ BULUNDUĞUNUZ LOKASYONA GÖRE SİZİN İÇİN GEÇERLİ OLACAK KULLANIM KOŞULLARINI GÖSTERECEKTİR.
  </p>
  <b>1. Tanımlar</b>
  <br />
  <br />
  <p>Bilgesin: Bilgesin Bilişim.</p>
  <p>Kullanıcı: İnternet sitesi üzerinden anketleri doldurmak ve diğer hizmetleri için internet sitesini kullananları.</p>
  <p>Taraf(lar): Kullanıcı ve/veya Bilgesin. </p>
  <p>Koşullar: Bu kullanım koşullarının tamamı.</p>
  <p>
    Uygulama: Bilgesin’e ait mobil ve diğer internet bağlantısı olan cihazlarda kullanılabilen Bilgesin’in sunduğu ürünlerin/hizmetlerin sipariş edilebildiği tüm fikri ve sınai hakları Bilgesin’e ait uygulama.
  </p>
  <p>Sözleşme: Taraflar arasında iş bu kullanım koşulları dahilinde, kullanıcının internet sitesini kullanımı ile yürürlüğe giren hizmet sözleşmesini </p>
  <p>Hizmet: Bilgesin’in hizmet verdiği bölgelerde, İnternet Sitesi’nde yer alan seçenekler arasından, Kullanıcı tarafından seçilen ve Bilgesin tarafından, Kullanıcı’ye sunulan hizmet veya mal.</p>
  <p>
    İnternet Sitesi: Bilgesin’e ait mobil ve diğer internet bağlantısı olan cihazlardan erişilebilen Bilgesin’in sunduğu ürünlerin/hizmetlerin sipariş edilebildiği tüm fikri ve sınai hakları Bilgesin’e ait www.bilgesiniz.com, www.bilgesin.com internet sitesi.
  </p>
  <b>2. Başvuru ve Üyelik </b>
  <br />
  <br />
  <p>
    2.1. Kullanıcılar, İnternet Sitesi’nde kayıt için gerekli olan bölümleri doldurup, GSM numarasını veya e-posta adresini onayladıktan sonra, şifresini girerek, işbu koşullar dahilinde İnternet Sitesi’ni kullanmaya başlayabilir.
  </p>
  <p>
    2.2. Kullanıcı, başvuru esnasında verdiği tüm bilgilerin daima ve her bakımdan eksiksiz, gerçeğe uygun ve güncel olduğunu kabul eder. Kullanıcı, bilgilerini her zaman İnternet Sitesi aracılığıyla güncelleyebilir.
  </p>
  <b>3. İnternet Sitesi’nin Kullanımı</b><br /><br />
  <p>
    3.1. Kullanıcı, Hizmet’e ilişkin tüm şikayetlerini Bilgesin’in Kullanıcı hizmetlerine info@bilgesiniz.com e-posta adresi üzerinden aktarabilir. 
  </p>
  <p>
    3.2. Bilgesin’in sunduğu hizmetten yararlanan Kullanıcı, İnternet Sitesi’ni hukuka ve amacına uygun şekilde kullanmayı ve İnternet Sitesi dahilinde yaptığı her işlem ve eylemdeki hukuki sorumluluğunun kendisine ait olduğunu kabul eder. Kullanıcı’nin İnternet Sitesi aracılığıyla ve/veya dahilinde, Koşullar’a ve hukuka aykırı olarak gerçekleştirdiği herhangi bir işlem, eylem ve/veya faaliyetleri nedeniyle Bilgesin doğrudan veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz.
  </p>
  <p>
    3.3. Bilgesin, Kullanıcı’dan kaynaklanan teknik aksaklıklar sebebiyle hizmetin geçici ya da kalıcı olarak durdurulması, durumunda yükümlülüklerini ifa edememesi nedeniyle sorumlu tutulamayacak ve kendisinden herhangi bir nam altında herhangi bir talepte bulunulamayacaktır.

  </p>
  <p>
    3.4 Kullanıcı, İnternet Sitesi üzerinde alınan tüm hizmetlerim, yapılan alışverişlerin kişisel kullanım ve tüketim amaçlı olarak son kullanıcıya yapıldığını, tekrar satış amaçlı olmadığını kabul eder.

  </p>
  <p>
    3.5 İnternet Sitesi’nde paylaşılan içeriklere/yorumlara ilişkin olarak; içeriklerin yorumların doğruluk, kalite, hukuka uygunluk, yerindelik, güvenilirlik, nitelik başta olmak üzere bütün özelliklerinden yalnızca Kullanıcı sorumludur.

  </p>
  <p>
    3.6 Yorumların hukuka uygun olup olmadığını veya bu Kullanım Koşulları’nı ihlal edip etmediğini (yasadışı içeriklerin tarafımıza bildirildiği durumlar gibi) belirlemek ve yasal yükümlülüklere uyum sağlamak adına, Bilgesin yorumları inceleyebilecektir. Ayrıca yasaları ve/veya bu işbu Koşulları ihlal ettiği düşünülen içerikler, Bilgesin tarafından değiştirilebilir, silinebilir, bu içeriklere yönelik erişim/görüntüleme engellenebilir. Bilgesin’in yorumları yayınlamama hakkı saklıdır.

  </p>
  <p>
    3.28. Kullanıcıler, İnternet Sitesi’nde gerçekleştireceği işlemlerde aşağıdaki kapsamda yorum, paylaşım ve diğer içerikler paylaşamazlar:
  </p>
  <ul>
    <li>Sakıncalı veya yasalara aykırı her türlü içerik.</li>
    <li>
      Diğer kullanıcılar ve/veya üçüncü kişiler için zarar verici, hakaret içerikli, kişilik haklarını zedeleyen ve/veya kırıcı içerikler.

    </li>
    <li>Herhangi bir kişi veya kurumu tehdit eden içerikler.</li>
    <li>Herhangi bir kişiye yönelik saldırgan veya zarar verici içerikler</li>
    <li>Kullanıcıların genelini rahatsız eden/edebilecek olan içerikler.</li>
    <li>
      Tek bir kullanıcı tarafından sürekli olarak paylaşılan, konu ve sayfa içeriği ile alakasız içerikler.

    </li>
    <li>
      Bir veya birden çok kullanıcı tarafından paylaşılan birbirinin aynısı içerikler (spam).
    </li>
    <li>Bir başka marka veya kurum adına reklam içerikleri.</li>
    <li>
      Yasa dışı girişimleri destekleyen ve teşvik eden her türlü yorum, bağlantı ve içerikler.

    </li>
    <li>
      Herhangi bir üçüncü şahıs veya kurumun haklarını ihlal eden her türlü içerik.

    </li>
  </ul>
  <br />
  <p>4. Sona Erme</p>
  <p>4.1. Kullanıcı, İnternet Sitesi kullanımına istediği zaman son verebilir.
  </p>
  <p>
    4.2. Kullanıcı’nin işbu belirtilen Koşullar’a, ilgili mevzuata veya iyi niyet/ahlak kurallarına aykırı davrandığının tespiti halinde, Bilgesin Kullanıcı’nin üyeliğini askıya alma veya İnternet Sitesi’nden/Uygulama’dan tamamen çıkarma hakkını saklı tutar.
  </p>
  <p>5. Kişisel Verilerin Korunması
  </p>
  <p>5.1. Kullanıcı Kişisel Verilerinin Korunması Politikası
  </p>
  <p>
    Bilgesin, özel hayatın gizliliği ve kişisel verilerinizin korunması herşeyden önce gelir. Sağlanan hizmet, kişisel veri niteliğinde minimum bilginin kullanılmasını gerektirecek şekilde tasarlanmıştır. İnternet sitesi (www.bilgesin.com) kullanıcılara ait kişisel veri niteliğinde telefon numarası ve e-posta adresi haricinde başka hiçbir veriyi, toplamaz, saklamaz ve üçüncü şahıslarla paylaşmaz. Telefon numarası bilgisi hizmetin kullanımından sonra şifrelenerek saklanır, Bilgesin çalışanları dahil herkesin erişimine bu veri kapatılır. Üçüncü şahıslarla paylaşılmaz.  

  </p>
  <p>5.2. Kişisel Verilerinizin Korunmasına İlişkin Aydınlatma Metni
  </p>
  <p>
    İşbu Aydınlatma Metni, yerel mevzuatımızda yer alan 6698 sayılı Kişisel Verilerin Korunması Kanunu (<b>“KVKK”</b>) ile Avrupa Birliği Genel Veri Koruma Tüzüğü (<b>“GDPR”</b>) uyarınca, Bilgesin’e ait internet sitesini kullananlara (Kullanıcılara) ait kişisel verilerin işlenmesi, saklanması, aktarılması ve imhasına ilişkin usul ve esaslar hakkında veri sahiplerinin aydınlatılması amacıyla hazırlanmıştır.

  </p>
  <p>5.2.1-Hangi Kişisel Verileri İşliyoruz? 
  </p>
  <p>
    Kullanıcıların kişisel verileri ile özel nitelikli kişisel verileri, KVKK m.4 ve GDPR m.5 çerçevesinde, KVKK 5. ve 6. maddeleri ile GDPR 6. ve 9. maddelerinde belirtilen Kişisel Veri İşleme Şartları ve Amaçları doğrultusunda ve işbu Aydınlatma Metninde yer alan amaçlar kapsamında kalmak üzere; hukuka uygun şekilde, işleme amacıyla bağlantılı, sınırlı ve ölçülü olarak ve veri güvenliğinin sağlanması amacıyla mevzuatta öngörülen her türlü teknik ve idari tedbirler alınarak işlenecektir. 

  </p>
  <p>a-İletişim Bilgileriniz:  telefon numaranız (bildirdiğiniz ev/iş yeri sabit ve/veya mobil telefon numaralarınız), e-posta adresiniz
  </p>
  <p>5.2.2. Kişisel Verilerinizi Hangi Amaçlarla İşliyor ve Aktarıyoruz?
  </p>
  <p>Yukarıda yer alan Kişisel ve Özel Nitelikli Kişisel Verileriniz; Bilgesin olarak www.bilgesin.com, www.bilgesiniz.com (İnternet Sitesi) ile ilgili sair hukuki yükümlülüklerimizi yerine getirmek, kullanıcılara sunduğumuz hizmetin planlanması ve yönetimi, hizmetlerimizi geliştirmek amacıyla analiz yapma, sizlere hizmetlerimize ilişkin faturalandırma yapılması, kimliğinizin doğrulanması, suistimal ve yetkisiz işlemlerin izlenmesi ve engellenmesi, tarafımızca sunulan hizmetlere ilişkin olarak her türlü soru ve şikâyetinize cevap verebilme; anlaşmalı kurumlarla size sunulan indirim ve sair avantajlara ilişkin sağlayıcılarla finansal mutabakat sağlanması; doğabilecek hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği ilgili yargı mercilerine ve ilgili kamu görevlilerine bilgi verebilmek, gerektiğinde yukarıda belirttiğimiz amaçlar dahilinde yetkili kamu kurum ve kuruluşları ile yürütmekte olduğumuz bilimsel ve sektörel çalışmalarımız esnasında, anonimleştirmenin gerekli olduğu aşamalarda anonimleştirme sağlanarak aktarılabilecektir. 
  </p>
  <b>5.2.3. Kişisel Verilerinizin Toplanma Yöntemleri Ve Bunlara İlişkin Hukuki Sebepler Nelerdir? 
  </b>
  <p>Yukarıda belirtilen kişisel verileriniz 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen nedenler ve ayrıca;
  </p>
  <p><b>a)</b> Hukuka ve dürüstlük kurallarına uygun olmak, 
  </p>
  <p><b>b)</b> Doğru ve gerektiğinde güncel olmak, 
  </p>
  <p><b>c)</b> Belirli, açık ve meşru amaçlar ile uyumlu olmak, 
  </p>
  <p><b>d)</b> İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olmak, 
  </p>
  <p><b>e)</b> İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilmek üzere otomatik olan (ör., kamera kayıtları) ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla (ör., tarafınızdan doldurulan formlar) işlenecek, kaydedilecek, depolanacak, muhafaza edilecek, sınıflandırılacak, aktarılabilecek ve saklama süresi sonunda imha edilecektir. 
  </p>
  <b>5.2.4. Kişisel Verilerin İşlenmesine Dair Haklarınız Nelerdir ve Bu Haklara Nasıl Erişebilirsiniz? 
  </b>
  <p>Anayasa’nın 20. maddesinde herkesin, kendisiyle ilgili kişisel veriler hakkında bilgilendirilme hakkına sahip olduğu ortaya konulmuş olmakla, kişisel veri sahibinin 6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamındaki hakları, KVKK’nın 11. maddesinde sayılmıştır. KVKK kapsamındaki haklarınıza ilişkin taleplerinizi “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ” Madde 5’e göre Bilgesin’e, (info@bilgesin.com) mail adresine yazılı olarak iletebilirsiniz. 
  </p>
  <p>5.3. Kullanıcı, İnternet Sitesi’nde oluşturulmuş her bir kullanıcı için yalnızca bir adet GSM numarası ve e-posta adresi tanımlanabileceğini, tanımlanan GSM numarasının ve e-posta adresinin değiştirilemeyeceğini, yeni bir GSM numarasının ve e-posta adresinin ancak İnternet Sitesi üzerinde yeni bir kullanıcı oluşturulması halinde tanımlatılabileceğini, kayıtlı GSM numarası ve e-posta adresi ile yapacağı her türlü işlemden bizzat kendisinin sorumlu olduğunu; kabul, beyan ve taahhüt eder.
  </p>
  <p>6. Fikri Mülkiyet Hakları</p>
  <p>
    6.1. Bilgesin'in; Bilgesin hizmetleri, fikri mülkiyet hakları, Bilgesin ticari markaları, Bilgesin ticari görünümü veya İnternet Sitesi’nin tasarım, metin, imge, html kodu ve diğer kodlar da dahil fakat bunlarla sınırlı olmamak kaydıyla, tüm unsurları, her tür ayni ve şahsi hakları, ticari bilgi ve birikim’e yönelik tüm hakları münhasıran Bilgesin’e aittir.

  </p>
  <p>
    6.2. Kullanıcı, Bilgesin hizmetlerini ve Bilgesin'in yukarıda sayılan çalışmalarını ticari amaçla kullanamaz, çoğaltamaz, dağıtamaz veya bunlardan türetilmiş çalışmalar yapamaz veya hazırlayamaz.

  </p>
  <p>
    6.3. Kullanıcı, İnternet Sitesi dahilinde bulunan, Bilgesin ve/veya başka bir üçüncü kişilere ait resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini kabul eder.

  </p>
  <p>7. Değişiklikler</p>
  <p>
    Bilgesin, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu Koşullar’ı, uygun göreceği herhangi bir zamanda, İnternet Sitesi üzerinden ilan ederek değiştirebilir.
  </p>
  <p>8. Mücbir Sebepler</p>
  <p>
    Hava muhalefetleri, ulaşım kesintisi, altyapı ve internet arızaları, yangın, deprem, sel baskını, diğer doğal afetler ile salgın hastalık gibi olağanüstü olayları, kargaşa, yaygın şiddet hareketleri, grev veya resmi makamların düzenlemeleri dahil ancak bunlarla sınırlı olmaksızın yükümlülüklerini yerine getirmesini tarafsız olarak engelleyebilecek veya geciktirebilecek nitelikteki durumlarda Bilgesin, işbu koşullar ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle sorumlu tutulamaz.

  </p>
  <p>9. Uygulanacak Hukuk Ve Yetki</p>
  <p>
    9.1. İşbu Koşullar Türkiye Cumhuriyeti yasalarına tabi olacak ve onlara göre yorumlanacaktır.

  </p>
  <p>
    9.2. Taraflar, Koşullar’dan doğan ihtilaflarda mevzuat çerçevesinde belirlenen parasal sınırlar dahilinde Kullanıcı ve Bilgesin’in kayıtlı adresinin bulunduğu yerdeki tüketici hakem heyetlerinin, parasal sınırları aşan uyuşmazlıklarda Kullanıcı’nin ve Bilgesin’in bulunduğu yerdeki tüketici mahkemelerinin yetkili olacağını kabul etmiştir.

  </p>
  <p>
    9.3. Uyuşmazlığın tüketici uyuşmazlığı olmaması halinde, İstanbul Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri yetkili olacaktır.

  </p>
  <p>10. Delil Sözleşmesi</p>
  <p>
    Taraflar, Koşullar’dan doğabilecek her türlü ihtilafta Bilgesin’in resmi defter ve ticari kayıtlarıyla, veritabanında ve sunucularında tuttuğu elektronik bilgilerin, bilgisayar ve ses kayıtlarının, delil teşkiledeceğini, bu maddenin Hukuk Muhakemeleri Kanunu’nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.
  </p>
  <p>11. Yürürlük</p>
  <p>
    Kullanıcı İnternet Sitesi üzerinden üyelik oluşturması ve/veya İnternet Sitesi’nin kullanması esnasında işbu Koşullar’a tabi olacağını kabul eder.
  </p>
</div>
