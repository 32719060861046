<div class="holder">
  <h1>BLOG</h1>

  <div class="blog-list">
    <a
      class="blog-item"
      *ngFor="let item of items"
      href="/blog/{{ item.slugUrl }}"
    >
      <div
        class="blog-item--img"
        style="background-image: url('{{ item.imageUrl }}');"
        *ngIf="item.imageUrl"
      ></div>
      <img
        [src]="item.imageUrl"
        [alt]="item.title"
        class="d-none"
        *ngIf="item.imageUrl"
      />
      <h3 class="blog-item--title">
        {{ item.title }}
      </h3>
      <span class="blog-item--description">
        {{ item.description }}
      </span>
      <span class="blog-item--date">
        {{ item.createdOn | date : "dd MMMM yyyy" }}
      </span>
    </a>
  </div>
</div>

<app-pagination
  [(page)]="page"
  (pageChange)="get()"
  [totalCount]="totalCount"
  [rowCount]="limit"
></app-pagination>
