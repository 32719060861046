import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-gizlilik-politikasi",
  templateUrl: "./gizlilik-politikasi.component.html",
  styleUrls: ["./gizlilik-politikasi.component.scss"],
})
export class GizlilikPolitikasiComponent implements OnInit {
  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title:
        "Gizlilik Politikası - Bilgesin'le kişisel verileriniz güvende - Bilgesin",
      description:
        "Türkçe internetten para kazanma siteleri içerisinde en güvenilir olanı Bilgesin.com. Ücretsiz para kazandıran sitelerden Bilgesin.com'da bütün aşamalar KVKK'ya uygun düzenlenmiştir.",
    });
  }
}
