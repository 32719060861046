<nav class="pagination-nav">
  <ul class="pagination justify-content-center">
    <li class="page-item" [ngClass]="{ disabled: page <= 0 }">
      <button type="button" class="page-link" (click)="change(page - 1)">
        Geri
      </button>
    </li>
    <li
      class="page-item"
      *ngFor="let item of pageItems"
      [ngClass]="{ active: item === page + 1 }"
    >
      <button type="button" class="page-link" (click)="change(item - 1)">
        {{ item }}
      </button>
    </li>
    <li class="page-item" [ngClass]="{ disabled: page >= pageCount - 1 }">
      <button type="button" class="page-link" (click)="change(page + 1)">
        İleri
      </button>
    </li>
  </ul>
</nav>
