import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import {
  landingPageConfig,
  scrollToTop,
  yasomiCompanyId,
} from "src/app/helpers/helpers";

@Component({
  selector: "app-survey-thanks",
  templateUrl: "./survey-thanks.component.html",
  styleUrls: ["./survey-thanks.component.scss"],
})
export class SurveyThanksComponent implements OnInit, OnDestroy {
  navState: any;
  conf: any;
  _yasomiCompanyId = yasomiCompanyId;
  productLinksModalShowing = false;

  get logoSrc() {
    return `/assets/lp/${this.conf?.key}/logo.png`;
  }

  get linkButtonText() {
    if (this.navState?.landingPageKey) {
      return this.conf?.linkButtonText || "";
    }
    return "Ankete Git";
  }

  constructor(private _sanitizer: DomSanitizer, private router: Router) {}

  ngOnInit(): void {
    scrollToTop();
    this.navState = JSON.parse(localStorage.getItem(this.router.url) || "{}");
    if (this.navState?.landingPageKey) {
      localStorage.removeItem(this.router.url);
      this.conf = landingPageConfig.find(
        (m) => m.key === this.navState.landingPageKey
      );
    } else {
      this.conf = landingPageConfig.find(
        (m) =>
          this.router.url.indexOf(m.lpUrl) > -1 ||
          this.router.url.indexOf(m.slugUrl) > -1
      );
    }

    if (this.conf) {
      document.body.classList.add(this.conf.key);
    }
  }

  ngOnDestroy(): void {
    if (this.conf) {
      document.body.classList.remove(this.conf.key);
    }
  }

  goLink() {
    if (!this.conf) {
      return;
    }

    if (this.navState?.landingPageKey) {
      window.open(this.conf.linkButtonHref, "_blank");
    } else {
      this.router.navigateByUrl(
        this.router.url.replace("/tesekkurler/", "").replace("/tesekkurler", "")
      );
    }
  }

  getBackground() {
    return this._sanitizer.bypassSecurityTrustStyle(
      `url(${this.navState?.qrBackground})`
    );
  }
}
