import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";

@Component({
  selector: "app-faydali-bilgiler",
  templateUrl: "./faydali-bilgiler.component.html",
  styleUrls: ["./faydali-bilgiler.component.scss"],
})
export class FaydaliBilgilerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    scrollToTop();
  }
}
