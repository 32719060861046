import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root-companies",
  templateUrl: "./root-companies.component.html",
  styleUrls: ["./root-companies.component.scss"],
})
export class RootCompaniesComponent implements OnInit {
  message = "";
  companies = [];
  sectors = [];

  selectedCompany;
  selectedCompanyUser = {
    name: "",
  };

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getSectors();
    this.getCompanies();
  }

  getCompanies() {
    this.http
      .get("/getCompanies")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.companies = res;
      });
  }

  getSectors() {
    this.http
      .get("/getSectors")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.sectors = res;
      });
  }

  selectCompany(data) {
    this.selectedCompany = data;
  }

  newCompany() {
    this.selectedCompanyUser = { name: "" };
    this.selectedCompany = {
      name: "",
      logo: "",
      sector: "",
      email: "",
      password: "",
      cityName: "",
      townName: "",
      slugUrl: "",
      isUserInfoModuleActive: false,
    };
  }

  handleUpload(event) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.selectedCompany.logo = reader.result;
      };
    }
  }

  saveCompanyChanges() {
    this.selectedCompanyUser.name = this.selectCompany.name;
    this.http
      .post("/saveCompany", {
        ...this.selectedCompany,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.message = "Firma Başarıyla Güncellendi";
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  }

  saveCompanyChangesNow(item) {
    this.http
      .post("/saveCompany", {
        ...item,
      })
      .pipe(take(1))
      .subscribe((res: any) => {});
  }

  removeCompany() {
    this.http
      .post("/removeCompany", {
        id: this.selectedCompany._id,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.message = "Firma Başarıyla Silindi";
      });
  }
}
