import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { fixStringLength, scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-campaign-detail",
  templateUrl: "./campaign-detail.component.html",
  styleUrls: ["./campaign-detail.component.scss"],
})
export class CampaignDetailComponent implements OnInit {
  // data = [
  //   {
  //     id: "",
  //     name: "",
  //     description: "Marks & Spencer’dan müşteri memnuniyet anketini dolduranlara tüm ürünlerde %10 indirim.",
  //     logo: "../../../assets/images/sample-brand-logo.png",
  //     promotion_type: 1,
  //     promotion_amount: "10"
  //   }
  // ];

  survey;
  surveyId: string;
  breadcrumbData = [];
  surveyUrl = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private metaService: MetaService
  ) {
    this.surveyId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    scrollToTop();
    this.getSurvey();
  }

  getSurvey() {
    this.http
      .post("/getSurvey", {
        id: this.surveyId,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.survey = res;
        this.surveyUrl = `/${
          this.survey.company?.slugUrl || this.survey.company?._id
        }/${this.survey.slugUrl || this.survey._id.toString()}`;

        this.breadcrumbData = [{ name: "Anasayfa", href: "/" }];
        if (res.company?.name) {
          this.breadcrumbData.push({
            name: fixStringLength(res.company.name, 50),
          });
        }
        this.breadcrumbData.push({
          name: fixStringLength(res.name, 50),
          href: this.surveyUrl,
          active: true,
        });

        this.setMeta();
      });
  }

  setMeta() {
    const companyName = this.survey.company?.name || "";
    this.metaService.set({
      title: `${companyName} şirketinin anketini doldurun, görüşlerinizle değer katın ve kampanyadan faydalanın.`,
      description: `Burada herkes kazanır. Düşünce ve fikirlerinizi paylaşın hem ${companyName} hem siz kazanın. Aynı zamanda ${companyName} şirketinin gelişimine katkıda bulunun.`,
    });
  }

  goToSurvey() {
    this.router.navigate([this.surveyUrl]);
  }
}
