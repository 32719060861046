<div class="holder" *ngIf="survey">
  <h1>{{ survey.name }} - Hediye ve SMS Yazıları</h1>
  <span class="message-holder" *ngIf="message">
    {{ message }}
  </span>

  <div class="form-control-holder">
    <div style="display: flex; justify-content: start; align-items: center">
      <input
        style="width: 30px; height: 30px"
        type="checkbox"
        id="isCustomGiftMessageActive"
        [(ngModel)]="survey.isCustomGiftMessageActive"
      />
      <label
        style="text-align: center; margin-left: 1rem; margin-right: 1rem"
        for="isCustomGiftMessageActive"
        >Özel hediye ve sms yazısı kullanılsın mı?</label
      >
    </div>
  </div>

  <div class="form-control-holder" *ngIf="survey.isCustomGiftMessageActive">
    <label>Özel yazıda kullanılabilecek parametreler;</label>
    <div class="instruction-text">
      <b>1:</b> [discountCode] = İndirim kodunun yazılacağı yeri belirtir
    </div>
    <div class="instruction-text">
      <b>2:</b> [discountDescription] = İndirim oranı ve türünün yazılacağı yeri
      belitir
    </div>
  </div>

  <div class="form-control-holder" *ngIf="survey.isCustomGiftMessageActive">
    <label for="customGiftText">Özel Hediye Mesajı Yazınız</label>
    <div class="instruction-text">
      <b>Örnek:</b> Tebrikler, anketiniz başarıyla tamamlandı ve
      [discountDescription] kazandınız. İşte indirim kodunuz: [discountCode]
    </div>
    <textarea
      id="customGiftText"
      [(ngModel)]="survey.customGiftText"
      row="20"
      cols="10"
      style="min-height: 100px"
    ></textarea>
  </div>

  <div class="form-control-holder" *ngIf="survey.isCustomGiftMessageActive">
    <label for="customSmsText">Özel SMS Mesajı Yazınız</label>
    <div class="instruction-text">
      <b>Örnek:</b> Tebrikler, anketiniz başarıyla tamamlandı ve
      [discountDescription] kazandınız. İşte indirim kodunuz: [discountCode]
    </div>
    <textarea
      id="customSmsText"
      [(ngModel)]="survey.customSmsText"
      row="20"
      cols="10"
      style="min-height: 100px"
    ></textarea>
  </div>

  <app-button
    class="form-button"
    text="Kaydet"
    type="fancy"
    (click)="saveTexts()"
  ></app-button>
</div>
