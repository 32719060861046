import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { verifyPhoneNumber } from "src/app/helpers/helpers";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-admin-verify-discount-code",
  templateUrl: "./admin-verify-discount-code.component.html",
  styleUrls: ["./admin-verify-discount-code.component.scss"],
})
export class AdminVerifyDiscountCodeComponent implements OnInit {
  phoneNumber = "";
  discountCode = "";
  verifyResultList = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  verify() {
    if (!verifyPhoneNumber(this.phoneNumber.toString())) {
      return;
    }

    this.verifyResultList = [];
    this.http
      .post("/verifyDiscountCode", {
        phoneNumber: this.phoneNumber,
        discountCode: this.discountCode,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res.error) {
          alert(res.message);
        } else {
          this.verifyResultList = res;
        }
      });
  }

  canUseDiscount(item) {
    if (item.hasSameSurvey || item.hasSameSurveyAndItHasBeenUsed) {
      return false;
    }

    if (item.discountState === "NOT_USED") {
      const diff = moment(item.campaignExpireDate).diff(moment(), "d");
      return diff >= 0;
    }

    return false;
  }

  toggleDiscountState(item, newDiscountState: string) {
    this.http
      .post("/toggleDiscountState", {
        _id: item._id,
        newDiscountState,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.verify();
      });
  }
}
