import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { exportExcel } from "src/app/helpers/helpers";

@Component({
  selector: "app-admin-pazanova",
  templateUrl: "./admin-pazanova.component.html",
  styleUrls: ["./admin-pazanova.component.scss"],
})
export class AdminPazanovaComponent implements OnInit {
  landingPages = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get("/pazanova/statistics")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.landingPages = res;
      });
  }

  downloadExcel() {
    const data = [];
    for (const lp of this.landingPages) {
      for (const stat of lp.statistics) {
        data.push({
          Marka: lp.key.toUpperCase(),
          "Telefon No": stat.userPhone,
          "Tarih Saat": stat.createdOn,
        });
      }
    }
    exportExcel(data, "pazanova-bilgesin");
  }

  downloadComparedExcel() {
    this.http
      .get("/pazanova/compared-statistics")
      .pipe(take(1))
      .subscribe((res: any) => {
        exportExcel(
          res.map((m) => ({
            Başlık: m.title,
            "Tarih - Saat": moment(m.date).format("DD.MM.YYYY HH:mm"),
          })),
          "pazanova-bilgesin-karsilastirmali"
        );
      });
  }
}
