<button (click)="onAdd()" class="btn btn-primary" style="margin-bottom: 30px">
  Yeni Ekle
</button>

<table class="table table-hover table-bordered">
  <thead>
    <tr>
      <th>Başlık</th>
      <th>Açıklama</th>
      <th>Slug Url</th>
      <th>Oluşturma Tarihi</th>
      <th>İşlemler</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of blogs">
      <td>{{ item.title }}</td>
      <td>
        {{ item.description }}
      </td>
      <td align="center">
        {{ item.slugUrl }}
      </td>
      <td align="center">{{ item.createdOn | date : "dd.MM.yyyy" }}</td>
      <td>
        <div class="table-actions">
          <a
            class="btn btn-primary btn-xs"
            href="/blog/{{ item.slugUrl }}"
            target="_blank"
            >Görüntüle</a
          >
          <button class="btn btn-warning btn-xs" (click)="onEdit(item)">
            Düzenle
          </button>
          <button class="btn btn-danger btn-xs" (click)="onDelete(item)">
            Sil
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="modal-wrapper" *ngIf="modalVisible">
  <div class="modal-container">
    <div class="modal-header">
      <h3>BLOG</h3>
      <div class="modal-close" (click)="modalVisible = false"></div>
    </div>

    <div class="modal-body">
      <div class="form-control-holder">
        <label for="">Başlık <b>*</b></label>
        <input type="text" [(ngModel)]="selected.title" />
      </div>
      <div class="form-control-holder">
        <label for="">Açıklama <b>*</b></label>
        <input type="text" [(ngModel)]="selected.description" />
      </div>
      <div class="form-control-holder">
        <label for="">Slug Url <b>*</b></label>
        <input type="text" [(ngModel)]="selected.slugUrl" />
      </div>
      <div class="form-control-holder">
        <label for="">Fotoğraf</label>
        <input type="file" (change)="fileUpload($event)" />
        <img
          [src]="selected.imageUrl"
          width="auto"
          height="100"
          *ngIf="selected.imageUrl"
          style="margin-bottom: 15px"
        />
      </div>
      <div class="form-control-holder">
        <label for="">HTML İçerik <b>*</b></label>
        <ckeditor
          class="html-editor"
          [editor]="Editor"
          [(ngModel)]="selected.htmlContent"
        ></ckeditor>
      </div>
      <div class="form-control-holder">
        <label for="">Meta Başlık <b>*</b></label>
        <input type="text" [(ngModel)]="selected.metaTitle" />
      </div>
      <div class="form-control-holder">
        <label for="">Meta Açıklama <b>*</b></label>
        <input type="text" [(ngModel)]="selected.metaDescription" />
      </div>
      <div class="form-control-holder">
        <label for="">Kazanmaya Başla Butonu İşlevi</label>
        <select [(ngModel)]="selected.surveyUrl">
          <option [value]="''">Varsayılan</option>
          <option *ngFor="let survey of surveys" [value]="survey.url">
            {{ survey.name }}
          </option>
        </select>
      </div>

      <button (click)="save()" class="btn btn-primary" style="margin-top: 15px">
        KAYDET
      </button>
    </div>
  </div>
</div>
