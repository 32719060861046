<div class="holder {{ isScreenLandscape }}">
  <section class="head">
    <div class="text-and-action-button-holder">
      <h1>Burada Herkes<br />Kazanır</h1>
      <h2>
        Bilgesin aracılığı ile markalarla düşünce ve fikirlerinizi <br />
        paylaşarak, markaların mevcut kampanyalarına
        <span class="underline">ilave</span> <br />
        <span class="underline">indirimler/hediyeler</span> kazanabilirsiniz.
      </h2>
      <app-button
        type="bold"
        text="Soruları Yanıtla<br/>Kazanmaya Başla"
        (click)="navigateToCampaigns()"
        class="nav-to-campaign-button"
      ></app-button>
    </div>
    <div class="image-holder">
      <img src="../../../assets/images/home-image.png" alt="" />
    </div>
  </section>
  <section class="home-seperator">
    <img src="../../../assets/images/section-background.svg" alt="" />
  </section>
  <section class="mid" id="campaigns-section">
    <div class="content" id="campaigns-section-content">
      <h2 class="flowchart-container-title">Nasıl Kazanırım?</h2>
      <div class="flowchart-container">
        <div class="flowchart-item">
          <div class="flowchart-item--img">
            <img src="/assets/images/flow-chart/1.svg" />
          </div>
          <h3 class="flowchart-item--title">Marka Seç</h3>
          <span class="flowchart-item--text">
            Kampanyasından faydalanmak ve görüşlerinizle değer katmak
            istediğiniz markayı seçin.
          </span>
        </div>
        <div class="flowchart-arrow">
          <img src="/assets/images/flow-chart/right-arrow.svg" />
        </div>
        <div class="flowchart-item">
          <div class="flowchart-item--img">
            <img src="/assets/images/flow-chart/2.svg" />
          </div>
          <h3 class="flowchart-item--title">
            Anketi Yanıtlayarak İndirim/Hediye Kazan
          </h3>
          <span class="flowchart-item--text">
            Soruları içtenlikle yanıtlayın. Anket sonunda indirim/hediye
            kodunuzu alın.
          </span>
        </div>
        <div class="flowchart-arrow">
          <img src="/assets/images/flow-chart/right-arrow.svg" />
        </div>
        <div class="flowchart-item">
          <div class="flowchart-item--img">
            <img src="/assets/images/flow-chart/3.svg" />
          </div>
          <h3 class="flowchart-item--title">İndirim/Hediye Kodunu Kullan</h3>
          <span class="flowchart-item--text">
            İndirim/hediye kodunuzu, dilerseniz markanın websitesinde veya
            uygulamasında, dilerseniz de markanın mağazasında kullanarak
            kazanmaya başlayın.
          </span>
        </div>
        <div class="flowchart-button-item">
          <app-button
            type="bold"
            text="Soruları Yanıtla<br/>Kazanmaya Başla"
            (click)="navigateToCampaignsTabs()"
          ></app-button>
        </div>
      </div>
      <ul class="tabs" id="campaigns-tabs">
        <li
          *ngFor="let item of tabs"
          [class.active]="item.active"
          (click)="changeTab(item)"
        >
          {{ item.name }}
        </li>
      </ul>
      <!-- <div class="search-area">
        <input type="text" placeholder="Kampanya / Firma Adı İle Ara" [(ngModel)]="filterText" />
        <img class="search-icon" src="../../../assets/images/search-icon.svg" alt="" />
      </div> -->
      <div class="search-filter-area" *ngIf="filters">
        <div class="form-control-holder">
          <select [(ngModel)]="filterSector">
            <option value="">Sektör Seçiniz</option>
            <option
              *ngFor="let item of filters.sectors"
              [value]="item._id"
              [disabled]="item.campaignCount <= 0"
            >
              {{ item.name }}
              {{ item.campaignCount <= 0 ? "(Kampanya sona erdi)" : "" }}
            </option>
          </select>
        </div>
        <!-- <div class="form-control-holder">
          <select [(ngModel)]="filterCity">
            <option value="">İl Seçiniz</option>
            <option *ngFor="let item of filters.cities" [value]="item.name">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="form-control-holder">
          <select [(ngModel)]="filterTown" [disabled]="!filterCity">
            <option value="">İlçe Seçiniz</option>
            <option *ngFor="let item of filtersTowns" [value]="item.name">
              {{ item.name }}
            </option>
          </select>
        </div> -->
      </div>
      <div class="results-area">
        <ul>
          <li
            *ngFor="let item of campaigns"
            [routerLink]="generateCampaignSlugUrl(item)"
          >
            <div class="logo-holder">
              <div
                class="campaign-logo-bg"
                *ngIf="item.logo"
                style="background-image: url('{{ item.logo }}')"
              ></div>
              <div
                class="campaign-logo-bg"
                *ngIf="!item.logo"
                style="
                  background-image: url('../../../assets/images/logo-dark.svg');
                "
              ></div>
            </div>
            <div class="content-holder">
              <div class="cta-top">
                {{ item.sectorName }} <br />
                {{ cityTownName(item) }}
              </div>
              <span
                ><b>{{ item.name }}</b></span
              >
              <div class="cta-holder">
                <app-button type="simple" text="Kazanmaya Başla!"></app-button>
              </div>
            </div>
            <div class="discount-holder">
              <app-button
                *ngIf="item.discountType !== 'Hediye'"
                type="curvy"
                text="{{ item.discountAmount }} {{
                  item.discountAmountType
                }} indirim"
              ></app-button>
              <app-button
                *ngIf="item.discountType === 'Hediye'"
                [type]="getGiftFontClass(item)"
                text="{{ item.discountDescription?.trim() }}"
              ></app-button>
              <span class="last-date-text" *ngIf="item.campaignExpireDateTime">
                Son Katılım
                {{ item.campaignExpireDateTime | date : "dd.MM.yyyy" }}
              </span>
              <span class="last-date-text" style="font-weight: normal">
                Ayrıntılı Bilgi
              </span>
            </div>
          </li>
          <li
            class="no-campaign"
            *ngIf="!campaignsLoading && campaigns.length === 0"
          >
            <img src="../../../assets/images/logo-dark.svg" alt="" />
            <span
              >Şu an için size uygun bir indirim bulunmuyor ama bizi takipte
              kalın!</span
            >
          </li>
          <li class="loader-container" *ngIf="campaignsLoading">
            <app-loader></app-loader>
          </li>
        </ul>
      </div>
      <!-- Old Items Start -->
      <ul class="tabs">
        <li
          *ngFor="let item of oldItemsTabs"
          [class.active]="item.active"
          (click)="changeOldItemsTab(item)"
        >
          {{ item.name }}
        </li>
      </ul>
      <!-- <div class="search-area">
        <input type="text" placeholder="Kampanya / Firma Adı İle Ara" [(ngModel)]="filterText" />
        <img class="search-icon" src="../../../assets/images/search-icon.svg" alt="" />
      </div> -->
      <div class="search-filter-area" *ngIf="oldItemsFilters">
        <!-- <div class="form-control-holder">
          <select [(ngModel)]="oldItemsFilterSector">
            <option value="">Sektör Seçiniz</option>
            <option
              *ngFor="let item of oldItemsFilters.sectors"
              [value]="item._id"
            >
              {{ item.name }}
            </option>
          </select>
        </div> -->
        <!-- <div class="form-control-holder">
          <select [(ngModel)]="filterCity">
            <option value="">İl Seçiniz</option>
            <option *ngFor="let item of filters.cities" [value]="item.name">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="form-control-holder">
          <select [(ngModel)]="filterTown" [disabled]="!filterCity">
            <option value="">İlçe Seçiniz</option>
            <option *ngFor="let item of filtersTowns" [value]="item.name">
              {{ item.name }}
            </option>
          </select>
        </div> -->
      </div>
      <div class="results-area old-items-results-area">
        <ul>
          <li
            *ngFor="let item of filteredOldItemsCampaigns"
            routerLink="/{{ item.companySlugUrl }}/{{
              item.slugUrl || item.formId
            }}"
            style="opacity: 0.4"
          >
            <div class="logo-holder">
              <div
                class="campaign-logo-bg"
                *ngIf="item.logo"
                style="background-image: url('{{ item.logo }}')"
              ></div>
              <div
                class="campaign-logo-bg"
                *ngIf="!item.logo"
                style="
                  background-image: url('../../../assets/images/logo-dark.svg');
                "
              ></div>
            </div>
            <div class="content-holder">
              <div class="cta-top">
                {{ item.sectorName }} <br />
                {{ cityTownName(item) }}
              </div>
              <span
                ><b>{{ item.name }}</b></span
              >
              <div class="cta-holder">
                <app-button type="simple" text="Kazanmaya Başla!"></app-button>
              </div>
            </div>
            <div class="discount-holder">
              <app-button
                *ngIf="item.discountType !== 'Hediye'"
                type="curvy"
                text="{{ item.discountAmount }} {{
                  item.discountAmountType
                }} indirim"
              ></app-button>
              <app-button
                *ngIf="item.discountType === 'Hediye'"
                [type]="getGiftFontClass(item)"
                text="{{ item.discountDescription?.trim() }}"
              ></app-button>
            </div>
            <div class="last-date-holder">
              <span class="last-date-text" *ngIf="item.campaignExpireDateTime">
                Kampanya Geçerlilik Tarihi:
                {{ item.createdOn | date : "dd.MM.yyyy" }} –
                {{ item.campaignExpireDateTime | date : "dd.MM.yyyy" }}
              </span>
              <span class="last-date-text last-date-info-text">
                Ayrıntılı Bilgi
              </span>
            </div>
          </li>
          <li
            class="no-campaign"
            *ngIf="!oldItemsCampaignsLoading && oldItemsCampaigns.length === 0"
          >
            <img src="../../../assets/images/logo-dark.svg" alt="" />
            <span>Geçmiş fırsat bulunamadı...</span>
          </li>
          <li class="loader-container" *ngIf="oldItemsCampaignsLoading">
            <app-loader></app-loader>
          </li>
        </ul>
        <app-button
          type="bold"
          text="Daha Fazla Göster"
          (click)="oldItemsShowAll = true"
          class="old-items-show-more-button"
          *ngIf="
            oldItemsCampaigns.length > 3 &&
            !oldItemsCampaignsLoading &&
            !oldItemsShowAll
          "
        ></app-button>
      </div>
      <!-- Old Items End -->
    </div>
  </section>
  <section>
    <app-customer-reviews></app-customer-reviews>
  </section>
</div>

<app-home-video-modal></app-home-video-modal>
