import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-product-links-modal",
  templateUrl: "./product-links-modal.component.html",
  styleUrls: ["./product-links-modal.component.scss"],
})
export class ProductLinksModalComponent implements OnInit {
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  _companyId: string;
  @Input()
  set companyId(value: string) {
    this._companyId = value;
    this.handle();
  }
  get companyId() {
    return this._companyId;
  }

  title = "";
  products = [];

  constructor() {}

  ngOnInit(): void {}

  handle() {
    this.title = "%3 ilave indirim kazanacağınız seçili ürünler:";
    this.products = [
      {
        title:
          "Yasomi Sm 1504bml Beyaz Led Ekranlı Kıyma Çekme Aparatlı Blender Stand Mikser Mutfak Şefi 1500w",
        href: "https://www.yasomi.com.tr/urun/yasomi-sm-1504bml-beyaz-led-ekranli-kiyma-cekme-aparatli-blender-stand-mikser-mutfak-sefi-1500w",
      },
      {
        title:
          "Yasomi X13 Profesyonel iyonik Saç Kurutma ve Fön Makinesi (Beyaz)",
        href: "https://www.yasomi.com.tr/urun/yasomi-x13-profesyonel-iyonik-sac-kurutma-ve-fon-makinesi-beyaz",
      },
      {
        title:
          "Yasomi Depilatör Optimal Teknoloji Sınırsız Atım Safir Buz Soğutma Sistem 9Kademeli Epilasyon Cihazı",
        href: "https://www.yasomi.com.tr/urun/yasomi-depilator-optimal-teknoloji-sinirsiz-atim-safir-buz-sogutma-sistem-9kademeli-epilasyon-cihazi",
      },
      {
        title:
          "Yasomi Optive X5 1080P Full HD WIFI ve Bluetooth Destekli Uzaktan Kumandalı Projeksiyon Cihazı",
        href: "https://www.yasomi.com.tr/urun/yasomi-optive-x5-1080p-full-hd-wifi-ve-bluetooth-destekli-uzaktan-kumandali-projeksiyon-cihazi",
      },
      {
        title:
          "Yasomi FruitForce 8 Segment 180W Katı Meyve ve Sebze Sıkacağı (turuncu)",
        href: "https://www.yasomi.com.tr/urun/yasomi-fruitforce-8-segment-180w-kati-meyve-ve-sebze-sikacagi",
      },
      {
        title:
          "Yasomi First Class- Özel Sapphire Buz Başlık Teknolojili Sınırsız Atımlı Ipl",
        href: "https://www.yasomi.com.tr/urun/yasomi-first-class-ozel-sapphire-buz-baslik-teknolojili-sinirsiz-atimli-ipl",
      },
      {
        title:
          "Yasomi Relaxon x10 Katlanabilir Elektrikli Tam Vücut Isıtmalı Masaj Yatağı",
        href: "https://www.yasomi.com.tr/urun/yasomi-relaxon-x10-katlanabilir-elektrikli-tam-vucut-isitmali-masaj-yatagi",
      },
      {
        title:
          "Yasomi Fith-Row Katlanabilir hidrolik Silindir Çok İşlevli Fitness ve Kardiyo Kürek Makinesi",
        href: "https://www.yasomi.com.tr/urun/yasomi-fith-row-katlanabilir-hidrolik-silindir-cok-islevli-fitness-ve-kardiyo-kurek-makinesi",
      },
      {
        title:
          "Yasomi Ai99 Pro Mavi Buz Başlıklı Cilt Gençleştirme Ve Akne Giderici Sınırsız Atım Ipl Epilasyon",
        href: "https://www.yasomi.com.tr/urun/yasomi-ai99-pro-mavi-buz-baslikli-cilt-genclestirme-ve-akne-giderici-sinirsiz-atim-ipl-epilasyon",
      },
      {
        title:
          "Yasomi Ysx Uzaktan Kumandalı & Titreşimli Bluetooth Hoparlör Destekli 4 Programlı Spor Masaj Aleti (Kırmızı)",
        href: "https://www.yasomi.com.tr/urun/yasomi-ysx-uzaktan-kumandali-titresimli-bluetooth-hoparlor-destekli-4-programli-spor-masaj-aleti",
      },
      {
        title: "Yasomi Drypet WB102 Evcil Hayvan Kurutma Makinesi (Yeşil)",
        href: "https://www.yasomi.com.tr/urun/yasomi-drypet-wb102-evcil-hayvan-kurutma-makinesi-yesil",
      },
    ];
  }
}
