<div class="holder">
  <h1>Kişisel Verilerin Korunması</h1>
  <p>
    Kişisel Verilerinin Korunması Politikası (“Politika”), Bilgesin Perakende
    Lojistik A.Ş. (“Bilgesin”) olarak, müşterilerimizin kişisel verilerini (bir
    kişiyi tanımlayan ya da onu tanımlamaya yarayan her türlü bilgi) nasıl
    koruduğumuz, kişisel verileri işlerken uyum sağladığımız kurallar ve veri
    işleme süreçlerimiz hakkında bilgiler içermektedir.
  </p>
  <p>
    Politikanın birinci kısmında, kişisel verileri işlerken uyum sağladığımız
    ilkeler ve dayandığımız hukuki sebepler gibi, teknik kurallara yer verdik.
    İkinci kısımda ise, müşterilerimizin kişisel verilerini işlediğimiz
    süreçlere ilişkin açıklamalara yer verdik.
  </p>
  <p>
    Politika’da kullandığımız terimlerin açıklamaları için metnin son kısmına
    göz atabilirsiniz. Kişisel verilerinizin korunması ve işlenmesine ilişkin
    her türlü sorunuz için <a href="mailto:info@{{ hostName }}"></a>
  </p>
  <b>1. Kişisel Veri İşleme Kuralları</b>
  <br />
  <br />
  <ul>
    <li>Kişisel Veri İşleme İlkeleri</li>
    <li>Kişisel Veri İşlemenin Hukuki Sebepleri</li>
    <li>Açık Rıza Sorulurken Dikkate Alınan Unsurlar</li>
    <li>Kişisel Veri Güvenliği</li>
    <li>İlgili Kişilerin Hakları</li>
  </ul>
  <br />
  <b>2. Kişisel Veri İşleme Süreçleri</b>
  <br />
  <br />
  <ul>
    <li>Örnek Veri İşleme Süreçleri ve Açıklamaları</li>
    <li>İşlenen Kişisel Veriler</li>
    <li>Kişisel Veri İşleme Amaçları</li>
    <li>Kişisel Verilerin Aktarılması</li>
  </ul>
  <br />
  <b>1. Kişisel Veri İşleme Kuralları</b>
  <br />
  <br />
  <p>
    Kişisel verilerinizi işlerken aşağıda belirtilen veri işleme ilkelerine
    uygunluk sağlıyoruz.
  </p>
  <ul>
    <li>
      Hukuka ve dürüstlük kurallarına uygun olma.Veri işleme faaliyetlerimizi
      mevzuat düzenlemelerine ve iyi niyet ilkelerine uygun şekilde yürütüyoruz.
    </li>
    <li>
      Doğru ve gerektiğinde güncel olma.Kişisel verilerinizin doğru ve güncel
      olmasını temin edecek kanalları her zaman açık tutuyoruz.
    </li>
    <li>
      Belirli, açık ve meşru amaçlar için işlenme.Kişisel verilerin hangi
      amaçlarla işleneceğini belirliyor ve bu amaçları şeffaf ve anlaşılır bir
      biçimde bilginize sunuyoruz.
    </li>
    <li>
      İşleme amaçlarıyla bağlantılı, sınırlı ve ölçülü olma. Amacın
      gerçekleştirilmesiyle ilgili olmayan veya ihtiyaç duyulmayan kişisel
      verileri işlemiyor, muhtemel ihtiyaçların karşılanmasına yönelik kişisel
      veri işleme faaliyetleri yürütmüyoruz.
    </li>
    <li>
      İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre
      kadar muhafaza edilme. Kişisel verilerin saklanması için mevzuatta
      öngörülmüş bir süre varsa bu süreye uygunluk sağlıyor; eğer böyle bir süre
      öngörülmemişse kişisel verileri sadece işleme amaçları için gereken süre
      boyunca saklıyoruz.
    </li>
  </ul>
  <h3>Kişisel Veri İşlemenin Hukuki Sebepleri</h3>
  <p>
    Kişisel verilerinizi, <b>Kişisel Verilerin Korunması Kanunu</b>’nun 5. ve 6.
    maddelerinde belirtilen hukuki sebeplere dayalı olarak işliyoruz. Bu hukuki
    sebepleri, ilgili kişilere sunduğumuz aydınlatma metinlerinde açıkça
    belirtiyoruz. Müşterilerimizin kişisel verilerini işlerken dayandığımız
    hukuki sebepler aşağıdaki şekildedir:
  </p>
  <ul>
    <li>
      Kişisel veri işlemenin Bilgesin ile aranızdaki sözleşmenin kurulması veya
      ifası için gerekli olması.
    </li>
    <li>
      Kişisel verilerin bir hakkın tesisi, kullanılması veya korunması için
      işlenmesi.
    </li>
    <li>
      Kişisel verilerin hukuki yükümlülüklerimizi yerine getirmek için
      işlenmesi.
    </li>
    <li>Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması.</li>
    <li>
      İlgili kişinin kişisel verilerinin işlenebilmesi için açık rızasını
      sağlamış olması.
    </li>
  </ul>
  <p>
    Bir veri işleme faaliyetinin meşru menfaatlerimiz için zorunlu olup
    olmadığını tespit ederken ayrıca bir değerlendirme yapıyoruz. Bu
    değerlendirmeyi yaparken,
    <b>Kurul’un 25/03/2019 tarihli ve 2019/78 Sayılı Kararı</b>’nda belirtilen
    kriterleri esas alıyor; kişinin temel hak ve hürriyetleri ile ortaya çıkacak
    meşru menfaati karşılaştırarak denge testi gerçekleştiriyoruz.
  </p>
  <p>
    Kişisel verilerin işlenebilmesi için Kanun’da belirtilen hukuki sebeplerden
    en az biri bulunuyorsa, kişinin açık rızasını sormuyoruz. Yalnızca Kanun’da
    yer alan hukuki sebepler bulunmadığında ilgili kişinin kişisel verilerinin
    işlenmesi için açık rızasının bulunup bulunmadığını soruyoruz.
  </p>
  <h3>Açık Rıza Sorulurken Dikkate Alınan Unsurlar</h3>
  <p>
    Açık rıza, Kanun’da “belirli bir konuya ilişkin, bilgilendirilmeye dayanan
    ve özgür iradeyle açıklanan rıza” olarak ifade edilmektedir. Bilgesin olarak
    ilgili kişilerin açık rızalarını sorarken, aşağıdaki üç unsuru göz önünde
    bulunduruyoruz:
  </p>
  <ul>
    <li>
      Belirli bir konuya ilişkin olma→ İlgili kişilerin açık rızasını, spesifik
      veri işleme faaliyeti/faaliyetleri için soruyor ve rıza metinlerinin
      anlaşılır olmasını sağlıyoruz.
    </li>
    <li>
      Bilgilendirmeye dayanma → Rıza metinleri ve aydınlatma metinlerini
      birlikte/aynı kanal üzerinde sunuyor, ilgili kişinin veri işleme
      faaliyetinin sonuçlarını anlamasına destek oluyoruz.
    </li>
    <li>
      Özgür iradeyle açıklanmış olma → Kişilerin rızalarını sorarken iradesini
      sakatlayacak yanıltıcı ifadelerden kaçınıyoruz.
    </li>
  </ul>
</div>
