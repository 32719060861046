<!-- Input field -->
<div
  class="input-container"
  [ngClass]="{
    'survey-input-container': survey
  }"
>
  <input
    type="{{ showPassword ? 'text' : 'password' }}"
    [id]="id"
    [placeholder]="placeholder || ''"
    class="password-input {{ class }} {{ landingPageKey }}"
    [ngClass]="{
      'survey-input': survey
    }"
    [ngModel]="model"
    (ngModelChange)="setModel($event)"
    [name]="name"
  />
  <!-- Show password toggle icon -->
  <button
    class="password-toggle-button"
    (click)="togglePasswordVisibility()"
    title="Şifre Gör"
  >
    <img
      src="/assets/images/{{ showPassword ? 'eye-off.svg' : 'eye.svg' }}"
      alt="Toggle Password Visibility"
      class="eye-icon"
    />
  </button>
</div>
