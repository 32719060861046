<div class="holder">
  <h1>Sektöre Özel Soru Veritabanı</h1>
  <div style="display: flex">
    <select [(ngModel)]="sectorId" (change)="filterQuestions()">
      <option value="">Seçiniz</option>
      <option *ngFor="let item of sectors" [value]="item._id">
        {{ item.name }}
      </option>
    </select>
    <div style="margin-left: 20px"></div>
    <select [(ngModel)]="questionType" (change)="filterQuestions()">
      <option value="tumu">Tümü</option>
      <option value="open">Açık uçlu</option>
      <option value="range">Dereceli</option>
      <option value="multiple">Çoktan Seçmeli</option>
    </select>
  </div>
  <br />
  <table *ngIf="sectorId === mustSectorId">
    <thead>
      <tr>
        <th>Soru Tipi</th>
        <th>Soru Metni</th>
        <th>Seçenekler</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of filteredQuestions">
        <td>
          <span *ngIf="item.kind == 'close'">
            <span *ngIf="item.sort == 'multiple'">Çoktan Seçmeli</span>
            <span *ngIf="item.sort != 'multiple'">Dereceli</span>
          </span>
          <span *ngIf="item.kind != 'close'"> Açık Uçlu </span>
        </td>
        <td>{{ item.question.text }}</td>
        <td>
          <ng-container *ngIf="item.kind == 'close'">
            <ng-container *ngFor="let option of item.question.options">
              <span *ngIf="option.text != ''">{{ option.text }}</span>
              &nbsp;&nbsp;
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="sectorId !== mustSectorId">
    <h3>
      Bu modülü görüntülemek için yetkiniz bulunamadı. Yönetici ile iletişime
      geçebilirsiniz.
    </h3>
  </div>
</div>
