import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  take,
} from "rxjs/operators";

@Component({
  selector: "app-search-modal",
  templateUrl: "./search-modal.component.html",
  styleUrls: ["./search-modal.component.scss"],
})
export class SearchModalComponent implements OnInit {
  _visible: boolean;
  @Input() set visible(value: boolean) {
    this._visible = value;
    if (value) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 50);
    }
  }
  get visible() {
    return this._visible;
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchControl = new FormControl();
  searchList = [];
  loading = false;
  searchResponseText = "";
  @ViewChild("searchInput") searchInput!: ElementRef;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((q: string) => {
        this.get(q);
      });
  }

  get(q: string) {
    this.loading = true;
    this.http
      .post("/search", {
        q,
      })
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res: any) => {
        this.searchList = res.results;
        this.searchResponseText = res.responseText;
      });
  }

  navigateTo(item) {
    this.visibleChange.emit(false);
    this.router.navigate([item.href]);
  }
}
