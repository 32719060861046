import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root-sectors",
  templateUrl: "./root-sectors.component.html",
  styleUrls: ["./root-sectors.component.scss"],
})
export class RootSectorsComponent implements OnInit {
  message = "";
  sectors = [];

  selectedSector;
  selectedQuestion;
  selectedQuestionIndex;

  constructor(
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getSectors();
  }

  getSectors() {
    this.http.get('/getSectors').pipe(take(1)).subscribe((res: any) => {
      this.sectors = res;
    });
  }

  selectSector(data) {
    this.selectedSector = data;
  }

  newSector() {
    this.selectedSector = {
      name: "",
      questions: [],
    };
  }

  saveSectorChanges() {
    this.http.post('/saveSector', {
      ...this.selectedSector
    }).pipe(take(1)).subscribe((res: any) => {
      this.message = "Sektör Başarıyla Güncellendi";
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });
  }

  removeSector() {
    this.http.post('/removeSector', {
      id: this.selectedSector._id
    }).pipe(take(1)).subscribe((res: any) => {
      this.message = "Sektör Başarıyla Silindi";
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });
  }

  showQuestion(data, index) {
    this.selectedQuestion = data;
    this.selectedQuestionIndex = index;
  }

  newQuestion() {
    this.selectedQuestion = {
      type: "new",
      kind: "",
      sort: "",
      isConnected: null,
      connectedQuestion: null,
      connectedQuestionIndex: null,
      isRelated: null,
      relatedQuestion: null,
      relatedQuestionIndex: null,
      minimumValue: 0,
      relatedQuestionError: 0,
      question: {
        text: "",
        optionCount: 0,
        options: [],
        valueCount: 1,
        rangeCount: 10,
      },
    };
    this.selectedQuestionIndex = this.selectedSector.questions.length
      ? this.selectedSector.questions.length
      : 0;
  }

  changeOptionCount(question) {
    question.question.options = [];
    for (let i = 0; i < question.question.optionCount; i++) {
      question.question.options.push({ text: "", value: i + 1 });
    }
  }

  removeQuestion(index) {
    this.http.post('/removeSectorQuestion', {
      sectorId: this.selectedSector._id, 
      index: index
    }).pipe(take(1)).subscribe((res: any) => {
      this.message = "Soru Başarıyla Silindi!";
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });
  }

  saveQuestion() {
    this.selectedSector.questions[this.selectedQuestionIndex] = this.selectedQuestion;
    this.http.post('/saveSector', {
      ...this.selectedSector
    }).pipe(take(1)).subscribe((res: any) => {
      this.message = "Soru Başarıyla Kaydedildi!";
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });
  }
}
