<div class="holder">
  <h1>Yönetim Ekibimiz</h1>
  <div class="content">
    <div class="team-holder" *ngFor="let item of teamDatas">
      <b> {{ item.name }} </b>
      <i>{{ item.title }}</i>
      <p>
        <img src="/assets/team/{{ item.image }}" [alt]="item.name" />
        <span>{{ item.desc }}</span>
      </p>
    </div>
  </div>
</div>
