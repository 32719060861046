import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-hakkimizda",
  templateUrl: "./hakkimizda.component.html",
  styleUrls: ["./hakkimizda.component.scss"],
})
export class HakkimizdaComponent implements OnInit {
  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title:
        "Hakkımızda - En çok kazandıran ve en güvenilir anket sitesidir - Bilgesin",
      description:
        "Markalaların müşteri yorumlarını, son kullanıcıların indirimle para kazandığı güvenilir sitedir. Türk para kazanma siteleri arasındadır. Türkçe anket doldurarak para kazanma fırsatı sunar.",
    });
  }
}
