<div class="modal-wrapper" *ngIf="visible">
  <div class="modal-container">
    <div class="modal-header">
      <h3>{{ title }}</h3>
      <div class="modal-close" (click)="visibleChange.emit(false)"></div>
    </div>

    <div class="modal-body">
      <div class="links-wrapper">
        <a
          class="links-wrapper--item"
          *ngFor="let item of products"
          [href]="item.href"
          target="_blank"
        >
          {{ item.title }}
        </a>
      </div>
    </div>
  </div>
</div>
