<div class="modal-wrapper" *ngIf="modalVisible">
  <div class="modal-container">
    <div class="modal-body">
      <div class="modal-close" (click)="modalVisible = false"></div>
      <video
        [src]="videoSrc"
        [poster]="posterSrc"
        controls="true"
        #videoPlayer
        (play)="onChangeEvt('play')"
        (pause)="onChangeEvt('pause')"
        preload="metadata"
      ></video>
      <img
        class="play-btn"
        src="/assets/images/play-circle.svg"
        (click)="playVideoPlayer()"
        *ngIf="!playing"
      />
    </div>
  </div>
</div>
