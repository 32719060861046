<h1 style="margin-bottom: 15px">Arama Logları ({{ totalCount }})</h1>

<table class="table table-hover table-bordered">
  <thead>
    <tr>
      <th>Aranan Metin</th>
      <th>Aranma Sayısı</th>
      <th>İlk Arama Tarihi</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of searchLogs">
      <td>
        {{ item.query }}
      </td>
      <td>
        {{ item.count }}
      </td>
      <td align="center">
        {{ item.createdOn | date : "dd.MM.yyyy" }}
      </td>
    </tr>
  </tbody>
</table>

<app-pagination
  [(page)]="page"
  (pageChange)="get()"
  [totalCount]="totalCount"
  [rowCount]="limit"
></app-pagination>
