import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root-forms',
  templateUrl: './root-forms.component.html',
  styleUrls: ['./root-forms.component.scss']
})
export class RootFormsComponent implements OnInit {

  contactforms = [];
  companyforms = [];

  constructor(
    private http: HttpClient
  ) { 

  }

  ngOnInit(): void {
    this.getDatas();
  }

  getDatas() {
    this.http.get('/getContactForms').pipe(take(1)).subscribe((res: any) => {
      this.contactforms = res;
    });
    this.http.get('/getCompanyForms').pipe(take(1)).subscribe((res: any) => {
      this.companyforms = res;
    });
  }

}
