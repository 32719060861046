import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { finalize, take } from "rxjs/operators";
import { exportExcel } from "src/app/helpers/helpers";

@Component({
  selector: "app-root-user-reports",
  templateUrl: "./root-user-reports.component.html",
  styleUrls: ["./root-user-reports.component.scss"],
})
export class RootUserReportsComponent implements OnInit {
  isReportReady = false;
  selectedDate = null;
  companyId = "";
  data = [];
  loading = false;
  companies = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getCompanies();
  }

  getCompanies() {
    this.http
      .get("/getCompanies")
      .pipe(take(1))
      .subscribe((res: any[]) => {
        this.companies = res;
      });
  }

  getReport() {
    this.isReportReady = false;
    this.loading = true;

    this.http
      .get("/user-reports", {
        params: {
          startDate: this.selectedDate?.startDate
            ? this.selectedDate.startDate.toISOString()
            : "",
          endDate: this.selectedDate?.endDate
            ? this.selectedDate.endDate.toISOString()
            : "",
          companyId: this.companyId,
        },
      })
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res: any[]) => {
        this.data = res || [];
        this.isReportReady = true;
      });
  }

  async downloadExcel() {
    if (!this.isReportReady) {
      return;
    }

    const output = [];

    for (const item of this.data) {
      output.push({
        ["Telefon No"]: item.phoneNumber,
        ["Anket Adı"]: item.surveyName,
        ["Tarih"]: moment(item.createdOn).format("DD.MM.YYYY HH:mm"),
        ["Şirket"]: item.companyName,
      });
    }

    exportExcel(output, "kullanici-raporlari");
  }
}
