import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-for-brands",
  templateUrl: "./for-brands.component.html",
  styleUrls: ["./for-brands.component.scss"],
})
export class ForBrandsComponent implements OnInit {
  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title: "Markalar İçin - Bilgesin",
      description: "Markalar İçin",
    });
  }
}
