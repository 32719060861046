import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-customer-reviews",
  templateUrl: "./customer-reviews.component.html",
  styleUrls: ["./customer-reviews.component.scss"],
})
export class CustomerReviewsComponent implements OnInit {
  reviews: any[] = [];

  sliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    margin: 15,
    responsive: {
      0: {
        items: 1,
      },
      680: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
    nav: false,
  };

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.http
      .get("/customer-reviews")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.reviews = res;
        this.sliderOptions.dotsEach = res.length / 3;
      });
  }
}
