import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";

@Component({
  selector: "app-root-home-survey-sorting",
  templateUrl: "./root-home-survey-sorting.component.html",
  styleUrls: ["./root-home-survey-sorting.component.scss"],
})
export class RootHomeSurveySortingComponent implements OnInit {
  surveys = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.http
      .get("/findCampaigns", {
        params: {
          q: "",
          type: "",
          city: "",
          town: "",
          sector: "",
        },
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.surveys = res?.data;
        for (const item of this.surveys) {
          item.homeOrderNew =
            typeof item.homeOrder === "undefined"
              ? undefined
              : Number(item.homeOrder);
        }
      });
  }

  async dataChange(item) {
    if (item.homeOrder === item.homeOrderNew) {
      return;
    }

    await this.http
      .post("/saveSurveyPartially", {
        id: item.formId,
        homeOrder: item.homeOrderNew,
      })
      .toPromise();
  }

  async submitUpdates() {
    for (const item of this.surveys) {
      await this.dataChange(item);
    }
    this.get();
  }
}
