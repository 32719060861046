import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { scrollIntoView } from "src/app/helpers/helpers";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  @Input() isMobileMenu = false;
  @Input() user = null;
  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {}

  logout() {
    this.userService.logout();
  }

  navigateToCampaigns() {
    this.closeMenu.emit();
    if (document.getElementById("campaigns-section-content")) {
      scrollIntoView("#campaigns-section-content");
    } else {
      this.router.navigate(["/"]).then((val) => {
        if (val) {
          setTimeout(() => {
            scrollIntoView("#campaigns-section-content");
          }, 250);
        }
      });
    }
  }
}
