<div class="holder">
  <h1>Nasıl Kazanırım?</h1>
  <div class="content">
    <div class="flowchart-container">
      <div class="flowchart-item">
        <div class="flowchart-item--img">
          <img src="/assets/images/flow-chart/1.svg" />
        </div>
        <h3 class="flowchart-item--title">Marka Seç</h3>
        <span class="flowchart-item--text">
          Kampanyasından faydalanmak ve görüşlerinizle değer katmak istediğiniz
          markayı seçin.
        </span>
      </div>
      <div class="flowchart-arrow">
        <img src="/assets/images/flow-chart/right-arrow.svg" />
      </div>
      <div class="flowchart-item">
        <div class="flowchart-item--img">
          <img src="/assets/images/flow-chart/2.svg" />
        </div>
        <h3 class="flowchart-item--title">
          Anketi Yanıtlayarak İndirim/Hediye Kazan
        </h3>
        <span class="flowchart-item--text">
          Soruları içtenlikle yanıtlayın. Anket sonunda indirim/hediye kodunuzu
          alın.
        </span>
      </div>
      <div class="flowchart-arrow">
        <img src="/assets/images/flow-chart/right-arrow.svg" />
      </div>
      <div class="flowchart-item">
        <div class="flowchart-item--img">
          <img src="/assets/images/flow-chart/3.svg" />
        </div>
        <h3 class="flowchart-item--title">İndirim/Hediye Kodunu Kullan</h3>
        <span class="flowchart-item--text">
          İndirim/hediye kodunuzu, dilerseniz markanın websitesinde veya
          uygulamasında, dilerseniz de markanın mağazasında kullanarak kazanmaya
          başlayın.
        </span>
      </div>
      <div class="flowchart-button-item">
        <app-button
          type="bold"
          text="Soruları Yanıtla<br/>Kazanmaya Başla"
          (click)="navigateToCampaignsTabs()"
        ></app-button>
      </div>
    </div>
  </div>

  <div class="video-container">
    <video
      [src]="videoSrc"
      [poster]="posterSrc"
      controls="true"
      preload="metadata"
      (play)="onChangeEvt('play')"
      (pause)="onChangeEvt('pause')"
      #videoPlayer
    ></video>
    <img
      class="play-btn"
      src="/assets/images/play-circle.svg"
      (click)="playVideoPlayer()"
      *ngIf="!playing"
    />
  </div>
</div>
