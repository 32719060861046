<div class="customer-reviews">
  <div class="container">
    <h3>Kullanıcı Yorumları</h3>
    <owl-carousel-o [options]="sliderOptions">
      <ng-container *ngFor="let item of reviews">
        <ng-template carouselSlide [id]="item._id">
          <div class="slider-item">
            <div class="slider-item--image">
              <img
                src="/assets/images/customer-reviews/{{ item.imageName }}"
                [alt]="item.title"
              />
            </div>

            <div class="slider-item--title">
              {{ item.title }}
            </div>

            <div class="slider-item--content">
              {{ item.content }} ({{ item.date | date : "dd.MM.YYYY" }})
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
