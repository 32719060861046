import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-acik-riza",
  templateUrl: "./acik-riza.component.html",
  styleUrls: ["./acik-riza.component.scss"],
})
export class AcikRizaComponent implements OnInit {
  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title: "Aydınlatma ve Açık Rıza Metni - Bilgesin",
      description:
        "Sitemizin sayfalarını dikkatlice okuyun. Bu sayede yazılımımızda gezinerek ödüllere ulaşın.",
    });
  }
}
