<div class="holder">
  <h1>AYDINLATMA VE AÇIK RIZA ONAMI</h1>
  <p>
    <b>Bilgesin.com (BİLGESİN)</b>’a ait “Kişisel Verilerin İşlenmesine İlişkin
    Aydınlatma Metnini” ve metinde açıkça belirtilen mevzuata ilişkin “tüm
    haklarımı” sözlü ve yazılı olarak anlayabildiğim dilde okudum, anladım,
    haklarım konusunda bilgilendim. Aydınlatma Metnini kabul ederek, BİLGESİN’î
    Aydınlatma Metni kapsamında işlemesine rıza gösterdiğim Kişisel Verilerimden
    kimlik bilgilerim ile iletişim bilgilerimin; BİLGESİN tarafından reklam,
    pazarlama, tanıtım, işbirliği uygulamaları kapsamında ve bu faaliyetlerin
    uygulamasının geliştirilmesi amacıyla; süreklilik arz eden hizmetlerde,
    tarafıma bilgilendirme ve hatırlatma yapılabilmesi amacıyla ve ayrıca,
    BİLGESİN’e ilişkin yeniliklerden bizzat haberdar edilmem amacıyla
    işlenmesine; sunulan hizmetlere ilişkin yenilik ve gelişmelerin
    bildirilmesi, süreklilik arz eden hizmetlerde gerekli tarihlerinin
    hatırlatmaların yapılabilmesi, özel günlerde kutlama, tebrik amacıyla
    tarafıma SMS, e-posta ve benzeri yöntemlerle mesajlar gönderilmesine ve
    mobil iletişim kurulmasına, bahsedilen bu verilerimin işlenmesine,
    saklanmasına, kullanılmasına ve yurtiçi ve yurtdışında bu amaçla sınırlı
    olmak kaydıyla ve Aydınlatma Metni’nde belirtilen şartlarda aktarılmasına
    açık rıza gösteriyorum. Göstermiş olduğum bu açık rızanın, aynı zamanda
    gerek BILGESIN ve gerekse vermis olduğum bu açık rıza vesilesi ile BİLGESİN
    tarafından verilerimin yurt içi ve yurt dışında aktarılacagı üçüncü şahıslar
    tarafından, tarafıma gönderilecek ticari iletiler bakımından da geçerli bir
    izin ve rıza olduğunu kabul ediyorum.
  </p>
</div>
