<div class="holder" *ngIf="survey">
  <h1>{{ survey.name }} - İndirim Kodları</h1>
  <span class="message-holder" *ngIf="message">
    {{ message }}
  </span>

  <div class="form-control-holder">
    <div style="display: flex; justify-content: start; align-items: center">
      <input
        style="width: 30px; height: 30px"
        type="checkbox"
        id="isCustomDiscountActive"
        [(ngModel)]="survey.isCustomDiscountActive"
      />
      <label
        style="text-align: center; margin-left: 1rem; margin-right: 1rem"
        for="isCustomDiscountActive"
        >Özel indirim kodu kullanılsın mı?</label
      >
    </div>
  </div>

  <div class="form-control-holder" *ngIf="survey.isCustomDiscountActive">
    <label for="customDiscountCodeType">Özel indirim kodu Türü Seçiniz</label>
    <select
      id="customDiscountCodeType"
      [(ngModel)]="survey.customDiscountCodeType"
      placeholder="Seçiniz"
    >
      <option value="FIXED">SABİT KOD</option>
      <option value="ONE_USAGE_CODES">TEK KULLANIMLIK KODLAR</option>
    </select>
  </div>

  <ng-template
    [ngIf]="
      survey.isCustomDiscountActive && survey.customDiscountCodeType === 'FIXED'
    "
  >
    <div class="form-control-holder">
      <label for="customDiscountCode">Sabit Kod Giriniz</label>
      <input
        type="text"
        id="customDiscountCode"
        [(ngModel)]="survey.customDiscountCode"
      />
    </div>
  </ng-template>

  <ng-template
    [ngIf]="
      survey.isCustomDiscountActive &&
      survey.customDiscountCodeType === 'ONE_USAGE_CODES'
    "
  >
    <div class="form-control-holder">
      <label for="customDiscountCodes"
        >İndirim Kodları ({{ customDiscountCodesCount }})
        <small>Virgül ile ayırınız</small></label
      >
      <textarea
        id="customDiscountCodes"
        [(ngModel)]="survey.customDiscountCodes"
        row="20"
        cols="10"
        style="min-height: 100px"
      >
      </textarea>
    </div>

    <div class="form-control-holder">
      <label for="usedCustomDiscountCodes"
        >Kullanılmış İndirim Kodları ({{ usedCustomDiscountCodesCount }})
        <small>Virgül ile ayırınız</small></label
      >
      <textarea
        id="usedCustomDiscountCodes"
        [(ngModel)]="survey.usedCustomDiscountCodes"
        row="10"
        cols="10"
      >
      </textarea>
    </div>
  </ng-template>

  <app-button
    class="form-button"
    text="Kaydet"
    type="fancy"
    (click)="saveDiscountCodes()"
  ></app-button>
</div>
