import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-password-input",
  templateUrl: "./password-input.component.html",
  styleUrls: ["./password-input.component.scss"],
  providers: [],
})
export class PasswordInputComponent {
  showPassword: boolean = false;

  @Input() placeholder?: string;
  @Input() id?: string;
  @Input() name?: string;
  @Input() class?: string;
  @Input() survey?: boolean;
  @Input() landingPageKey?: string;

  private _model: string;
  get model() {
    return this._model;
  }
  @Input()
  set model(val: string) {
    console.log();
    if (this._model == val) return;

    this._model = val;
  }
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  setModel(val: string) {
    this.model = val;
    this.modelChange?.emit(val);
  }
}
