<div class="holder">
  <h1>Markalar İçin</h1>
  <div class="content">
    <h3>Müşterilerinizi mutlu hissettirerek onları daha iyi tanıyın!</h3>
    <div class="for-brands">
      <img src="/assets/images/for-brands.png" alt="Markalar İçin" />
    </div>
    <div class="text-holder">
      Bilgesin, markalara yenilikçi bir dijital pazarlama çözümü sunarken, kendi
      müşteri havuzuna yeni müşteri ekleme imkânı da sağlamaktadır.
      <br /><br />
      Bilgesin ekibi olarak, Bilgesin’i tercih eden markalar için;
      <br /><br />
      <ul>
        <li>Müşteri memnuniyeti,</li>
        <li>İletişim stratejileri,</li>
        <li>Rekabet analizi,</li>
        <li>
          Gelişen pazar dinamikleri ve markaların ihtiyacına uygun birçok konu
          üzerine araştırmalar gerçekleştiriyoruz.
        </li>
      </ul>
      <br /><br />
      Canlı araştırma sonuçlarını, markaların kendine tanımlanan panel üzerinden
      7 gün 24 saat takip edebilmesinin yanı sıra var olan yazılımlarına da
      entegre olabilmesiyle kullanım kolaylığı sağlamaktadır.
      <br /><br />
      Bilgesin araştırma ve yazılımında;
      <br /><br />
      <ul class="tick-marker">
        <li>
          Günlük ve/veya dönemsel dinamiklere göre anket sorularınız anlık
          olarak güncellenebilir.
        </li>
        <li>
          Araştırma sonuçları, yapay zekâ destekli yazılımımızla birlikte
          periyodik olarak işlenip raporlara dönüştürülür.
        </li>
        <li>
          Tamamen gerçek ve doğrulanmış kişiler ile hedef kitlenize en uygun
          katılımcı kitlesini yakalanır.
        </li>
        <li>
          Geniş ve çeşitli üye havuzu sayesinde yeni müşteri kazanımı ve doğru
          bir alışverişçi deneyimi yaşanır.
        </li>
      </ul>
      <br /><br />
      Bilgesin ailesine katılmak ve daha fazla bilgi almak için aşağıdaki form
      ile bizlerle iletişime geçebilirsiniz.
      <br /><br />
      <a [routerLink]="['/is-ortagimiz-olmak-ister-misiniz']">
        http://bilgesin.com/is-ortagimiz-olmak-ister-misiniz
      </a>
    </div>
  </div>
</div>
