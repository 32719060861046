import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { fixStringLength } from "src/app/helpers/helpers";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  user: any;
  isMenuShown = false;
  windowWidth = 0;

  @HostListener("window:resize", [])
  private onResize() {
    this.windowWidth = window.innerWidth;
  }

  constructor(private userService: UserService, private router: Router) {
    router.events.subscribe((val) => {
      this.isMenuShown = false;
    });
  }

  ngOnInit(): void {
    this.userService.user.subscribe((x) => {
      this.user = x;
    });
  }

  logout() {
    this.userService.logout();
  }

  fixNameLength(name?: string) {
    if (!name) {
      return "";
    }
    return fixStringLength(name, 50);
  }
}
