import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-yonetim-ekibimiz",
  templateUrl: "./yonetim-ekibimiz.component.html",
  styleUrls: ["./yonetim-ekibimiz.component.scss"],
})
export class YonetimEkibimizComponent implements OnInit {
  teamDatas = [
    {
      name: "Kaan Öztürk",
      title: "Kurucu / Satış&Pazarlama",
      image: "kaan-ozturk.png",
      desc: "Bilkent Üniversitesi’nde EndüstriMühendisliği okuduktan sonra yüksek lisansını Boğaziçi Üniversitesi’nde tamamlamıştır. Continental Türkiye’de Filo Operasyonları Müdürü olarak çalıştıktan sonra 2018’den beri girişimcilik  yapmaktadır.",
    },
    {
      name: "Yunus Sarıbulut",
      title: "Şirket Ortağı / Bilgi Teknolojileri ",
      image: "yunus-saribulut.png",
      desc: "Atatürk Endüstri Meslek Lisesini bitirdikten sonra direkt iş hayatına başlamıştır. Web ve mobil yazılımın bütün aşamalarında uzmanlaşmıştır. OtelZ, ZeplinCar, Biletsepeti.com gibi birçok şirkette ve projede, kilit görevlerde çalışmıştır.",
    },
    {
      name: "Mehmet Kavi",
      title: "Melek Yatırımcı / Finans&Strateji",
      image: "mehmet-kavi.png",
      desc: "Yıldız Teknik Üniversitesi’nde Elektronik&Haberleşme Mühendisliği’ni bitirmiş ve aynı bölümde yüksek lisansını tamamlamıştır. Otokod Teknoloji A.Ş’nin CEO’su olarak çalışma hayatına devam etmektedir. TRAngels bünyesinde ve bireysel olarak melek yatırımcılık da yapmaktadır.",
    },
  ];

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title: "Yönetim Ekibimiz - Bilgesin",
      description: "Yönetim Ekibimiz",
    });
  }
}
