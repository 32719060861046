<div class="holder">
  <app-breadcrumb [data]="breadcrumbData"></app-breadcrumb>
  <div class="content" *ngIf="survey">
    <ng-container *ngIf="survey?.company?.logo">
      <img [src]="survey.company.logo" alt="" />
    </ng-container>
    <ng-container *ngIf="!survey?.company?.logo">
      <img src="../../../assets/images/logo-dark.svg" alt="" />
    </ng-container>
    <div class="text-and-button">
      <div>
        <h1>{{ survey.name }}</h1>
        <span [innerHTML]="survey.campaignDescription"></span>
      </div>
      <app-button
        type="simple"
        text="Kazanmaya Başla!"
        (click)="goToSurvey()"
      ></app-button>
    </div>
  </div>
  <hr />
  <!-- <div class="bottom">
    <h3>Bu kampanyaları da beğenebilirsiniz</h3>
    <ul>
      <li *ngFor="let item of data">
        <ng-container *ngIf="item.logo">
          <img [src]="item.logo" alt="" />
        </ng-container>
        <ng-container *ngIf="!item.logo">
          <img src="../../../assets/images/logo-dark.svg" alt="" />
        </ng-container>
      </li>
    </ul>
  </div> -->
</div>
