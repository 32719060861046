<div class="holder">
  <div>
    <h1>İş Ortağımız Olmak İster misiniz</h1>
    <p>
      Bilgesin ile siz de kurumunuzu büyütmek ve geliştirmek isterseniz,
      aşağıdaki formu doldurup bize gönderin. En kısa sürede sizlere mutlaka
      geri dönüş sağlayacağız.
    </p>
  </div>
  <div class="form-holder">
    <form action="/" (submit)="onSubmit($event)">
      <div>
        <label for="">Ad Soyad <small>*</small></label>
        <input type="text" name="name" required />
      </div>
      <div>
        <label for="">Göreviniz <small>*</small></label>
        <input type="text" name="job" required />
      </div>
      <div>
        <label for="">Telefon</label>
        <input type="tel" name="tel" />
      </div>
      <div>
        <label for="">E-Posta <small>*</small></label>
        <input type="email" name="email" required />
      </div>
      <div>
        <label for="">Firma Adı <small>*</small></label>
        <input type="text" name="company" required />
      </div>
      <div>
        <label for="">Firma Resmi Ünvanı</label>
        <input type="text" name="legalname" />
      </div>
      <div>
        <label for="">Kupon kodunuz var mı?</label>
        <input type="text" name="couponCode" />
      </div>
      <div>
        <label for="">Bilgesin'e nasıl ulaştınız?</label>
        <textarea name="text"> </textarea>
      </div>
      <button type="submit">Tamamla</button>
    </form>
  </div>
</div>
