import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
  selector: "app-admin-survey-discount-codes",
  templateUrl: "./admin-survey-discount-codes.component.html",
  styleUrls: ["./admin-survey-discount-codes.component.scss"],
})
export class AdminSurveyDiscountCodesComponent implements OnInit {
  survey: any;
  surveyId = "";
  message = "";

  get customDiscountCodesCount() {
    if (!this.survey) {
      return "...";
    }
    return (
      this.survey.customDiscountCodes
        ?.split(",")
        .map((m) => m.trim())
        .filter((m) => m).length || "0"
    );
  }

  get usedCustomDiscountCodesCount() {
    if (!this.survey) {
      return "...";
    }

    return (
      this.survey.usedCustomDiscountCodes
        ?.split(",")
        .map((m) => m.trim())
        .filter((m) => m).length || "0"
    );
  }

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.surveyId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.http
      .post("/getSurvey", {
        id: this.surveyId,
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.survey = res;
        if (
          this.survey.customDiscountCodes &&
          this.survey.isCustomDiscountActive &&
          !this.survey.customDiscountCodeType
        ) {
          this.survey.customDiscountCodeType = "ONE_USAGE_CODES";
        }

        if (!this.survey.customDiscountCodes) {
          this.survey.customDiscountCodes = "";
        }
        if (!this.survey.usedCustomDiscountCodes) {
          this.survey.usedCustomDiscountCodes = "";
        }
      });
  }

  saveDiscountCodes() {
    this.http
      .post("/saveSurveyPartially", {
        id: this.surveyId,
        isCustomDiscountActive: this.survey.isCustomDiscountActive,
        customDiscountCodes: this.survey.customDiscountCodes,
        usedCustomDiscountCodes: this.survey.usedCustomDiscountCodes,
        customDiscountCodeType: this.survey.customDiscountCodeType,
        customDiscountCode: this.survey.customDiscountCode,
      })
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.message = "İndirim kodları başarılı şekilde kaydedildi!";
          this.get();
          setTimeout(() => {
            this.message = "";
          }, 2000);
        },
        (err) => {
          this.message = "Hata oluştu!";
          setTimeout(() => {
            this.message = "";
          }, 2000);
        }
      );
  }
}
