<div class="holder">
  <div class="sidebar">
    <div class="sidebar-logo">
      <app-logo></app-logo>
      <img
        src="/assets/images/menu-icon.svg"
        class="sidebar-menu-toggler"
        (click)="menuOpen = !menuOpen"
      />
    </div>
    <ul *ngIf="user?.isAdmin && !userService.isPazanova()">
      <li><a routerLink="./new-survey">Yeni Anket</a></li>
      <li>
        <a routerLink="./statistics"
          >Yaratılmış Anketler ({{
            statisticsCount >= 0 ? statisticsCount : "..."
          }})</a
        >
      </li>
      <li><a routerLink="./company-info">Firma Bilgileri</a></li>
      <li><a routerLink="./questions">Sektöre Özel Soru Veritabanı</a></li>
      <li><a routerLink="./reports">Raporlar</a></li>
      <!-- <li><a routerLink="./verify-discount-code">Şifre Doğrula</a></li> -->
      <li *ngIf="adminCompany?.isUserInfoModuleActive">
        <a routerLink="./admin-user-infos">Kullanıcı Bilgileri Modülü</a>
      </li>
    </ul>
    <ul *ngIf="userService.isPazanova()">
      <li><a routerLink="./pazanova">Pazanova</a></li>
    </ul>
    <ul *ngIf="user?.isRoot">
      <li><a routerLink="./companies">Firmalar</a></li>
      <li><a routerLink="./sectors">Sektörler</a></li>
      <li><a routerLink="./customers">Müşteriler</a></li>
      <li><a routerLink="./forms">İletişim Formları</a></li>
      <li><a routerLink="./form-coupons">Form Kuponları</a></li>
      <li><a routerLink="./blog">Blog</a></li>
      <li>
        <a routerLink="./home-survey-sorting">Ana Sayfa Kampanya Sırala</a>
      </li>
      <li>
        <a routerLink="./user-reports">Kullanıcı Raporları</a>
      </li>
      <li><a routerLink="./search-logs">Arama Logları</a></li>
    </ul>
    <ul>
      <li (click)="logout()">Çıkış Yap</li>
    </ul>
  </div>
  <div class="content admin-content">
    <router-outlet></router-outlet>
  </div>
</div>
