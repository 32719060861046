<ul class="menu-holder">
  <li>
    <a
      title="Anasayfa"
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Anasayfa
    </a>
  </li>
  <li>
    <a
      title="Nasıl Kazanırım?"
      routerLink="/nasil-kazanirim"
      routerLinkActive="active"
    >
      Nasıl Kazanırım?
    </a>
  </li>
  <li>
    <a
      title="Markalar İçin"
      routerLink="/markalar-icin"
      routerLinkActive="active"
    >
      Markalar İçin
    </a>
  </li>
  <li>
    <a title="İletişim" routerLink="/iletisim" routerLinkActive="active">
      İletişim
    </a>
  </li>
  <li *ngIf="isMobileMenu">
    <app-button
      type="bold"
      text="Soruları Yanıtla<br/>Kazanmaya Başla"
      (click)="navigateToCampaigns()"
      class="nav-to-campaign-button"
    ></app-button>
  </li>
  <li *ngIf="isMobileMenu && user" (click)="logout()">
    <a title="cikis yap" href="#">Çıkış Yap</a>
  </li>
</ul>
