import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-iletisim",
  templateUrl: "./iletisim.component.html",
  styleUrls: ["./iletisim.component.scss"],
})
export class IletisimComponent implements OnInit {
  constructor(private http: HttpClient, private metaService: MetaService) {}

  hostName = environment.hostName();

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title:
        "İletişim - Hediyeli anketler için bizimle iletişime geçin - Bilgesin",
      description:
        "Güvenilir Türk anket sitelerinden Bilgesin.com'a ulaşmak çok kolay",
    });
  }

  onSubmit(e) {
    if (e.target.name.value == "") {
      alert("Lütfen isim ve soyisim alanlarını doldurunuz.");
      return false;
    }
    if (e.target.email.value == "") {
      alert("Lütfen email alanini doldurunuz.");
      return false;
    }
    if (e.target.title.value == "") {
      alert("Lütfen konu alanini doldurunuz.");
      return false;
    }
    if (e.target.text.value == "") {
      alert("Lütfen konu alanini doldurunuz.");
      return false;
    }

    this.http
      .post("/contactForm", {
        name: e.target.name.value,
        email: e.target.email.value,
        tel: e.target.tel.value,
        title: e.target.title.value,
        text: e.target.text.value,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        alert(
          "Form başarıyla gönderildi. En kısa sürede size dönüş yapacağız."
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  }
}
