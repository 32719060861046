import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-musteri-kisisel-verileri-aydinlatma-metni",
  templateUrl: "./musteri-kisisel-verileri-aydinlatma-metni.component.html",
  styleUrls: ["./musteri-kisisel-verileri-aydinlatma-metni.component.scss"],
})
export class MusteriKisiselVerileriAydinlatmaMetniComponent implements OnInit {
  hostName = environment.hostName();

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title: "Müşteri Kişisel Verileri Aydınlatma Metni - Bilgesin",
      description:
        "Sitemizin sayfalarını dikkatlice okuyun. Bu sayede yazılımımızda gezinerek ödüllere ulaşın.",
    });
  }
}
