import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
  selector: "app-admin-survey-sms-text",
  templateUrl: "./admin-survey-sms-text.component.html",
  styleUrls: ["./admin-survey-sms-text.component.scss"],
})
export class AdminSurveySmsTextComponent implements OnInit {
  survey: any;
  surveyId = "";
  message = "";

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.surveyId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    this.get();
  }

  get() {
    this.http
      .post("/getSurvey", {
        id: this.surveyId,
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.survey = res;

        if (!this.survey.customSmsText) {
          this.survey.customSmsText = "";
        }
        if (!this.survey.customGiftText) {
          this.survey.customGiftText = "";
        }
      });
  }

  saveTexts() {
    this.http
      .post("/saveSurveyPartially", {
        id: this.surveyId,
        isCustomGiftMessageActive: this.survey.isCustomGiftMessageActive,
        customSmsText: this.survey.customSmsText,
        customGiftText: this.survey.customGiftText,
      })
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.message = "Başarılı şekilde kaydedildi!";
          this.get();
          setTimeout(() => {
            this.message = "";
          }, 2000);
        },
        (err) => {
          this.message = "Hata oluştu!";
          setTimeout(() => {
            this.message = "";
          }, 2000);
        }
      );
  }
}
