import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root-customers",
  templateUrl: "./root-customers.component.html",
  styleUrls: ["./root-customers.component.scss"],
})
export class RootCustomersComponent implements OnInit {
  message = "";
  selectedCustomer;

  customers = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get("/getCustomers")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.customers = res.map((m) => ({
          ...m,
          reports: m.reports.map((r) => ({
            ...r,
            createdOn: new Date(r.createdOn),
          })),
        }));
        for (const customer of this.customers) {
          customer.reports.sort(
            (a, b) => b.createdOn.getTime() - a.createdOn.getTime()
          );
          if (customer.reports.length > 0) {
            customer.report = {
              ...customer.reports[0],
              createdOnString: moment(customer.reports[0].createdOn).format(
                "DD-MM-yyyy HH:mm"
              ),
              anotherReportString:
                customer.reports.length > 1 ? "Evet" : "Hayır",
            };

            let finishTimeInSecondsString = "";
            if (customer.report.finishTimeInSeconds) {
              const minutes = Math.floor(
                customer.report.finishTimeInSeconds / 60
              );
              const remainingSeconds = customer.report.finishTimeInSeconds % 60;
              finishTimeInSecondsString = `${minutes} dk. ${remainingSeconds} sn.`;
            }
            customer.report.finishTimeInSecondsString =
              finishTimeInSecondsString;
            console.log(customer.reports[0], customer.report);
          }
        }
      });
  }

  newCustomer() {
    this.selectedCustomer = {
      id: "",
      name: "",
      email: "",
      password: "",
      age: "",
      gender: null,
      isAdmin: false,
      isRoot: false,
      isActive: true,
    };
  }

  selectCustomer(data) {
    this.selectedCustomer = {
      ...data,
      reports: null,
      report: null,
    };
    delete this.selectedCustomer.reports;
    delete this.selectedCustomer.report;
  }

  saveChanges() {
    this.http
      .post("/fastSaveCustomer", {
        ...this.selectedCustomer,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        alert(res.data);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  }

  removeCustomer() {
    let isConfirmed = confirm(
      "Bu kullaniciyi silmek istediginize emin misinz?"
    );
    if (isConfirmed) {
      this.http
        .post("/fastRemoveCustomer", {
          id: this.selectedCustomer._id,
        })
        .pipe(take(1))
        .subscribe((res: any) => {
          alert(res.data);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  }
}
