<div class="holder" *ngIf="blog">
  <h1>{{ blog.title }}</h1>

  <div class="blog-container">
    <img
      [src]="blog.imageUrl"
      [alt]="blog.title"
      class="blog-image"
      *ngIf="blog.imageUrl"
    />
    <div class="blog-content">
      <p>{{ blog.description }}</p>
      <p [innerHTML]="blog.htmlContent"></p>
    </div>
    <div class="blog-bottom-button">
      <app-button
        type="bold"
        text="Soruları Yanıtla<br/>Kazanmaya Başla"
        (click)="navigateToCampaigns()"
      ></app-button>
    </div>
  </div>
</div>
