import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { scrollIntoView, scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";

@Component({
  selector: "app-nasil-kazanirim",
  templateUrl: "./nasil-kazanirim.component.html",
  styleUrls: ["./nasil-kazanirim.component.scss"],
})
export class NasilKazanirimComponent implements OnInit {
  isMobile = window.innerWidth < 768;
  get videoSrc() {
    return this.isMobile
      ? "/assets/videos/tanitim-mobile.mp4"
      : "/assets/videos/tanitim.mp4";
  }
  get posterSrc() {
    return this.isMobile
      ? "/assets/images/tanitim-mobile.png"
      : "/assets/images/tanitim.png";
  }

  @ViewChild("videoPlayer") videoPlayer!: ElementRef;
  playing: boolean = false;

  constructor(private metaService: MetaService, private router: Router) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title:
        "Nasıl Kazanırım? - Yorumlarınızı paylaşın, kampanya kodu kazanın - Bilgesin",
      description:
        "Ankenti yanıtladıktan sonra kazandığın indirim kuponunun markanın web sitesinde veya mağazasında kullan.Kampanya bitiş tarihini kaçırma. Online anket doldurma artık çok kolay.",
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.isMobile = window.innerWidth < 768;
  }

  navigateToCampaignsTabs() {
    this.router.navigate(["/"]).then((val) => {
      if (val) {
        setTimeout(() => {
          scrollIntoView("#campaigns-tabs");
        }, 250);
      }
    });
  }

  onChangeEvt(evt: "play" | "pause") {
    this.playing = evt === "play";
  }

  playVideoPlayer() {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.play();
  }
}
