import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-kisisel-verilerin-korunmasi",
  templateUrl: "./kisisel-verilerin-korunmasi.component.html",
  styleUrls: ["./kisisel-verilerin-korunmasi.component.scss"],
})
export class KisiselVerilerinKorunmasiComponent implements OnInit {
  hostName = environment.hostName();

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title:
        "Kişisel Verilerin Korunması - Bilgesin'de bütün veriler güvencemiz altında - Bilgesin",
      description:
        "Nakit para kazandıran platformlar içerisinde Bilgesin.com'da kişisel veriler şifrelenir. Soruları cevaplayarak para kazanırken artık güvendesiniz.",
    });
  }
}
