import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-questions',
  templateUrl: './root-questions.component.html',
  styleUrls: ['./root-questions.component.scss']
})
export class RootQuestionsComponent implements OnInit {

  question = {
    type: "",
    kind:"",
    sort: "",
    question: {
      text: "",
      optionCount: 0,
      options: [],
      valueCount: 1,
      rangeCount: 10,
    }
  };
  state= "";
  message = "";

  constructor() { }

  ngOnInit(): void {
  }

  newQuestion() {
    this.state = "new";
  }

  changeOptionCount() {
    this.question.question.options = [];
    for(let i = 0; i < this.question.question.optionCount; i++) {
      this.question.question.options.push({text: "", value: 0});
    }
  }

}
