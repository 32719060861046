<div class="holder">
  <h1>İletişim Formları</h1>
  <div>
    <table>
      <thead>
        <tr>
          <th>name</th>
          <th>email</th>
          <th>tel</th>
          <th>title</th>
          <th>text</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of contactforms">
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.tel }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.text }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br />
  <br />
  <h1>Is Ortagi Formlari</h1>
  <div>
    <table>
      <thead>
        <tr>
          <th>name</th>
          <th>email</th>
          <th>tel</th>
          <th>job</th>
          <th>company</th>
          <th>legalname</th>
          <th>text</th>
          <th>kupon</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of companyforms">
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.tel }}</td>
          <td>{{ item.job }}</td>
          <td>{{ item.company }}</td>
          <td>{{ item.legalname }}</td>
          <td>{{ item.text }}</td>
          <td>{{ item.couponCode || "-" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
