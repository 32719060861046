import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { uploadImage } from "src/app/helpers/helpers";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-root-blogs",
  templateUrl: "./root-blogs.component.html",
  styleUrls: ["./root-blogs.component.scss"],
})
export class RootBlogsComponent implements OnInit {
  blogs: any[] = [];
  totalCount: number = 0;

  selected = {
    title: "",
    imageUrl: "",
    htmlContent: "",
    slugUrl: "",
    metaTitle: "",
    metaDescription: "",
    description: "",
    surveyUrl: "",
  };
  selectedId = "";
  uploadImageFile: File;
  modalVisible = false;
  surveys = [];

  public Editor = ClassicEditor;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.get();
    this.getSurveys();
  }

  get() {
    this.http
      .get("/blog", {
        params: {
          page: "0",
          limit: "9999",
        },
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.blogs = res.data;
        this.totalCount = res.totalCount;
      });
  }

  getSurveys() {
    this.http
      .get("/blog/get-survey-list")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.surveys = res;
      });
  }

  onEdit(item) {
    this.selected = { ...item, surveyUrl: item.surveyUrl || "" };
    this.uploadImageFile = null;
    this.selectedId = item._id.toString();
    this.modalVisible = true;
  }

  onDelete(item) {
    const confirmed = confirm(
      "Blog yazısını silmek istediğinize emin misiniz?"
    );
    if (!confirmed) {
      return;
    }

    this.http
      .delete(`/blog/${item._id}`)
      .pipe(take(1))
      .subscribe((res: any) => {
        alert(res.data);
        this.get();
      });
  }

  onAdd() {
    this.selected = {
      title: "",
      imageUrl: "",
      htmlContent: "",
      slugUrl: "",
      metaTitle: "",
      metaDescription: "",
      description: "",
      surveyUrl: "",
    };
    this.uploadImageFile = null;
    this.selectedId = "";
    this.modalVisible = true;
  }

  fileUpload(ev) {
    const file = ev.target.files[0];
    const reader = new FileReader();
    this.uploadImageFile = file;
    reader.onloadend = () => {
      this.selected.imageUrl = reader.result.toString();
    };
    reader.readAsDataURL(file);
  }

  async save() {
    let showError =
      !this.selected.title ||
      !this.selected.slugUrl ||
      !this.selected.metaTitle ||
      !this.selected.metaDescription ||
      !this.selected.htmlContent ||
      !this.selected.description;
    if (showError) {
      alert("Lütfen boş alan bırakmayınız!");
      return;
    }

    const req = { ...this.selected };
    if (this.uploadImageFile) {
      const imageUrl = await uploadImage(this.uploadImageFile);
      req.imageUrl = imageUrl.toString();
    }

    let action;
    if (this.selectedId) {
      delete (req as any)._id;
      action = this.http.put(`/blog/${this.selectedId}`, req);
    } else {
      action = this.http.post("/blog", req);
    }

    action.pipe(take(1)).subscribe((res: any) => {
      alert(res.data);
      this.modalVisible = false;
      this.get();
    });
  }
}
