import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {

  showRootLogin = false;

  email = '';
  password = '';

  constructor(
    private user: UserService
  ) { }

  ngOnInit() {
  }

  async rootLogin() {
    if(!this.password) {
      return alert('Lütfen tüm alanları doldurunuz');
    }
    if (!this.email) {
      return alert('Lütfen tüm alanları doldurunuz');
    }

    await this.user.underConstructionRootLogin({
      email: this.email,
      password: this.password
    });
  }

}
