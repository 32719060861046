import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { scrollToTop } from "src/app/helpers/helpers";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-admin-new-surwey",
  templateUrl: "./admin-new-surwey.component.html",
  styleUrls: ["./admin-new-surwey.component.scss"],
})
export class AdminNewSurweyComponent implements OnInit {
  surwey = {
    name: "",
    slugUrl: "",
    description: "",
    discountType: "Indirim",
    discountAmount: 10,
    discountAmountType: "",
    discountDescription: "",
    timeLimit: 50,
    customTimeLimit: false,
    questions: [],
    campaignDescription: "",
    campaignExpireDate: {
      startDate: moment().add(30, "d"),
    },
    showOnHomePage: false,
  };
  message = "";
  recomendedTimeLimit;
  dbQuestions = [];

  savedQuestions = {
    open: [],
    close: [],
    multiple: [],
    range: [],
  };

  today = moment();

  edit = false;
  surveyId = "";

  constructor(
    private _sanitizer: DomSanitizer,
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getSectors();
    this.activatedRoute.paramMap.subscribe((res) => {
      if (res.get("id")) {
        this.edit = true;
        this.surveyId = res.get("id");
        this.fetchSurveyToEdit();
      } else {
        this.edit = false;
      }
    });
  }

  fetchSurveyToEdit() {
    this.http
      .post("/getSurvey", {
        id: this.surveyId,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        delete res.company;
        this.surwey = {
          ...res,
          campaignExpireDate: {
            startDate: moment(res.campaignExpireDate),
          },
        };

        for (let i = 0; i < this.surwey.questions.length; i++) {
          this.surwey.questions[i].order = i + 1;
        }
      });
  }

  getSectors() {
    this.http
      .get("/getSectors")
      .pipe(take(1))
      .subscribe((res: any) => {
        const user = this.userService.getUser();
        if (user) {
          const sectorId = "66f2a1c97240c13be0fd5c18"; //user.sectorId.toString()
          const sector = res.find((x) => x._id == sectorId);
          this.savedQuestions.open = this.savedQuestions.open.concat(
            sector.questions.filter((x) => x.kind == "open")
          );
          this.savedQuestions.close = this.savedQuestions.close.concat(
            sector.questions.filter((x) => x.kind == "close")
          );
          this.savedQuestions.multiple = this.savedQuestions.multiple.concat(
            sector.questions.filter((x) => x.sort == "multiple")
          );
          this.savedQuestions.range = this.savedQuestions.range.concat(
            sector.questions.filter((x) => x.sort == "range")
          );
        }
      });
  }

  getBackground(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  addQuestion() {
    const lastQuestion = this.surwey.questions.sort(
      (a, b) => b.order - a.order
    )[0];
    const order = (lastQuestion?.order || 0) + 1;

    this.surwey.questions.push({
      order,
      type: "",
      kind: "",
      sort: "",
      isConnected: null,
      connectedQuestion: null,
      connectedQuestionIndex: null,
      isRelated: null,
      relatedQuestion: null,
      relatedQuestionIndex: null,
      minimumValue: 0,
      relatedQuestionError: 0,
      question: {
        text: "",
        optionCount: 0,
        options: [],
        valueCount: 1,
        rangeCount: 10,
      },
    });

    this.recomendedTimeLimit = 10 * this.surwey.questions.length;

    this.orderQuestions();
  }

  saveSurwey() {
    let showError = false;
    let errorMessage = "";
    if (
      !this.surwey.name ||
      !this.surwey.description ||
      !this.surwey.discountType ||
      !this.surwey.questions ||
      !this.surwey.campaignDescription ||
      !this.surwey.slugUrl
    ) {
      showError = true;
    }
    if (
      this.surwey.discountType !== "Hediye" &&
      !this.surwey.discountAmountType
    ) {
      showError = true;
    }
    if (this.surwey.discountType === "Hediye") {
      if (!this.surwey.discountDescription) {
        showError = true;
      } else if (this.surwey.discountDescription.length > 25) {
        showError = true;
        errorMessage = "Hediye Açıklaması 25 karakteri geçemez!";
      }
    }

    if (showError) {
      scrollToTop();
      this.message = errorMessage || "Lütfen Boş Alanları Doldurunuz!";
      setTimeout(() => {
        this.message = "";
      }, 5000);

      return;
    }

    const expDate = this.surwey.campaignExpireDate.startDate.toDate();
    let req: any = {
      ...this.surwey,
      campaignExpireDate: expDate,
      campaignExpireDateTime: expDate.getTime(),
    };

    if (this.surveyId) {
      delete req._id;
      req.id = this.surveyId.toString();
    }

    this.http
      .post("/saveSurvey", req)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.message = "Anket Başarıyla Kaydedildi!";
        scrollToTop();
        setTimeout(() => {
          this.surwey = {
            name: "",
            slugUrl: "",
            description: "",
            discountType: "",
            discountAmount: 10,
            discountAmountType: "",
            discountDescription: "",
            timeLimit: 0,
            customTimeLimit: false,
            questions: [],
            campaignDescription: "",
            campaignExpireDate: {
              startDate: moment().add(30, "d"),
            },
            showOnHomePage: false,
          };
          window.location.reload();
        }, 1000);
      });
  }

  changeOptionCount(question) {
    question.question.options = [];
    question.question.valueCount = 0;
    for (let i = 0; i < question.question.optionCount; i++) {
      question.question.options.push({ text: "", value: i + 1 });
    }
  }

  handleConnectedQuestion(question) {
    if (question && question.connectedQuestionIndex) {
      question.connectedQuestion =
        this.surwey.questions[parseInt(question.connectedQuestionIndex)];
    }
  }

  handleRelatedQuestion(question) {
    if (question && question.relatedQuestionIndex) {
      question.kind = "close";
      question.sort = "range";
      question.question.options =
        this.surwey.questions[
          parseInt(question.relatedQuestionIndex)
        ].question.options;
      question.question.optionCount =
        this.surwey.questions[
          parseInt(question.relatedQuestionIndex)
        ].question.optionCount;
      question.question.rangeCount =
        this.surwey.questions[
          parseInt(question.relatedQuestionIndex)
        ].question.rangeCount;
      question.question.valueCount =
        this.surwey.questions[
          parseInt(question.relatedQuestionIndex)
        ].question.valueCount;
      question.relatedQuestion =
        this.surwey.questions[parseInt(question.relatedQuestionIndex)];
    }
  }

  handleDbQuestions(type, value) {
    this.dbQuestions = this.surwey.questions.filter((q, i) => q[type] == value);
  }

  handleDBQuestionSelect(index, type) {
    this.surwey.questions[index].question =
      this.savedQuestions[type][
        this.surwey.questions[index].DBquestionIndex
      ].question;
    this.surwey.questions[index].sort =
      this.savedQuestions[type][
        this.surwey.questions[index].DBquestionIndex
      ].sort;
  }

  orderQuestions() {
    this.surwey.questions = this.surwey.questions.sort(
      (a, b) => a.order - b.order
    );
  }

  removeQuestion(idx) {
    this.surwey.questions[idx].hide = true;
    this.orderQuestions();
  }
}
