<div class="holder" *ngIf="!selectedSector && !selectedQuestion">
    <h1>Sektörler</h1>
    <span class="message-holder" *ngIf="message">
        {{message}}
    </span>
    <div>
        <ul>
            <li *ngFor="let item of sectors"><span style="margin-right:2rem">{{item.name}}</span><span
                    (click)="selectSector(item)">Görüntüle</span></li>
        </ul>
        <app-button text="yeni ekle" (click)="newSector()"></app-button>
    </div>
</div>

<div class="holder" *ngIf="selectedSector && !selectedQuestion">
    <h1>{{selectedSector.name}}</h1>
    <span class="message-holder" *ngIf="message">{{message}}</span>
    <div class="form-control-holder">
        <label for="">Sektör Adı</label>
        <input type="text" [(ngModel)]="selectedSector.name">
    </div>

    <app-button type="link" text="Sektörü Sil" (click)="removeSector()"></app-button>
    <app-button text="Sektörü Kaydet" (click)="saveSectorChanges()"></app-button>

    <hr>

    <h2>Sektöre Özel Sorular</h2>

    <div>
        <ul>
            <li *ngFor="let item of selectedSector.questions; let i = index" style="display:flex; flex-direction:row; justify-content: space-between; align-items: center;">{{item.question.text}} 
                <div style="display:flex; flex-direction:row;">
                    <span (click)="showQuestion(item, i)">Görüntüle</span>
                    <span (click)="removeQuestion(i)" style="margin-left: 1rem;">Sil</span>
                </div>
            </li>
        </ul>
        <hr>
        <app-button text="Yeni Soru Ekle" (click)="newQuestion()"></app-button>
    </div>
</div>

<div class="holder" *ngIf="selectedSector && selectedQuestion">

    <h1 for="">Sektöre Özel Soru - {{selectedSector.name}}</h1>

    <div class="form-control-holder">
        <label for="">Soru Tarzı</label>
        <select [(ngModel)]="selectedQuestion.kind">
            <option value="open">Açık Uçlu</option>
            <option value="close">Kapalı Uçlu</option>
        </select>
    </div>
    <div *ngIf="selectedQuestion.kind == 'open'">
        <div class="form-control-holder">
            <label for="">Soru Metni Giriniz</label>
            <input [(ngModel)]="selectedQuestion.question.text">
        </div>
    </div>
    <div *ngIf="selectedQuestion.kind == 'close'">
        <div class="form-control-holder">
            <label for="">Soru Şekli</label>
            <select [(ngModel)]="selectedQuestion.sort">
                <option value="multiple">Çoktan Seçmeli</option>
                <option value="range">Derecelendirmeli</option>
            </select>
        </div>
        <div *ngIf="selectedQuestion.sort">
            <div *ngIf="selectedQuestion.sort == 'multiple'">
                <div class="form-control-holder">
                    <label for="">Kaç Şıklı?</label>
                    <select [(ngModel)]="selectedQuestion.question.optionCount"
                        (change)="changeOptionCount(selectedQuestion)">
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
                <div *ngIf="selectedQuestion.question.optionCount">
                    <div class="form-control-holder">
                        <label for="">Kaç Şık Seçilebilsin</label>
                        <select [(ngModel)]="selectedQuestion.question.valueCount">
                            <option [ngValue]="i" *ngFor="let item of selectedQuestion.question.options; let i = index">
                                {{i+1}}</option>
                        </select>
                    </div>
                    <div *ngIf="selectedQuestion.question.valueCount !== undefined">
                        <div class="form-control-holder">
                            <label for="">Soru Metnini Girin</label>
                            <input type="text" [(ngModel)]="selectedQuestion.question.text">
                        </div>
                        <div class="form-control-holder"
                            *ngFor="let item of selectedQuestion.question.options; let ix = index">
                            <label for="">{{ix+1}}.Soru Şıkkını Girin</label>
                            <input type="text" [(ngModel)]="selectedQuestion.question.options[ix].text">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedQuestion.sort == 'range'">
                <div class="form-control-holder">
                    <label for="">Kaç Dereceli?</label>
                    <select [(ngModel)]="selectedQuestion.question.optionCount"
                        (change)="changeOptionCount(selectedQuestion)">
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                    </select>
                </div>
                <div *ngIf="selectedQuestion.question.optionCount">
                    <div class="form-control-holder">
                        <label for="">Soru Metnini Girin</label>
                        <input type="text" [(ngModel)]="selectedQuestion.question.text">
                    </div>
                    <div class="form-control-holder"
                        *ngFor="let item of selectedQuestion.question.options; let ix = index">
                        <label for="">{{ix+1}}.Dereceyi İsimlendirin</label>
                        <input type="text" [(ngModel)]="selectedQuestion.question.options[ix].value">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <app-button text="Soruyu Kaydet" (click)="saveQuestion()"></app-button>
    </div>
</div>