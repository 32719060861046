<div class="holder">
  <h1>Size Özel Fırsatlar</h1>
  <div class="results-area">
    <ul>
      <ng-container *ngFor="let item of data">
        <li [routerLink]="'/detail/' + (item.slugUrl || item.formId)">
          <div class="logo-holder">
            <ng-container *ngIf="item.logo">
              <img [src]="item.logo" alt="" />
            </ng-container>
            <ng-container *ngIf="!item.logo">
              <img src="../../../assets/images/logo-dark.svg" alt="" />
            </ng-container>
          </div>
          <div class="content-holder">
            <span
              ><b>{{ item.survey.name }}</b
              ><br />{{ item.survey.description }}</span
            >
            <div class="cta-holder">
              <app-button type="simple" text="Kazanmaya Başla!"></app-button>
            </div>
          </div>
          <div class="discount-holder">
            <app-button type="curvy" text="%10 indirim"></app-button>
            <app-button type="link" text="Ayrıntılı Bilgi"></app-button>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
