<div class="holder">
  <h1>Yaratılmış Anketler</h1>
  <table style="width: 100%">
    <thead>
      <tr>
        <th>QR/Id</th>
        <th>Anket Adı</th>
        <th>Anket Tipi</th>
        <th>SMS Gönder</th>
        <th>Anasayfada Gözüksün mü?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of surweys; index as i">
        <td>
          <div
            *ngIf="item.qr"
            [style.background-image]="getBackground(item.qr)"
            class="image-preview"
          ></div>
          <span style="display: block; margin-top: 1rem; text-align: center"
            >id:
            <a href="{{ item.companySlugUrl }}/{{ item.slugUrl }}">{{
              item.slugUrl
            }}</a>
          </span>
          <br />
          <a
            class="discount-code-link"
            [href]="'admin/survey-discount-codes/' + item.formId"
            >İndirim Kodu Tanımla</a
          >
          <br />
          <a
            class="discount-code-link"
            [href]="'admin/survey-sms-text/' + item.formId"
            >Hediye ve SMS Yazısı Tanımla</a
          >
        </td>
        <td>{{ item.survey.name }}</td>
        <td>{{ item.survey.discountType }}</td>
        <td>
          <div class="form-control-holder">
            <label for="phone{{ i }}">Telefon</label>
            <input
              type="text"
              id="phone{{ i }}"
              [(ngModel)]="item.phone"
              title="Lütfen başında 0 olmadan giriniz"
              placeholder="5321231212"
            />
          </div>
          <app-button
            type="simple"
            text="SMS Gönder"
            (click)="sendSurveySms(item.phone, item.formId)"
          ></app-button>
        </td>
        <td>
          <div class="form-control-holder">
            <input
              type="checkbox"
              [(ngModel)]="item.showOnHomePage"
              (ngModelChange)="showOnHomePageChange(item)"
            />
          </div>
        </td>
        <td>
          <span (click)="editSurvey(item)" style="cursor: pointer"
            >Düzenle</span
          >
          <br />
          <br />
          <span
            (click)="removeSurvey(item)"
            style="cursor: pointer; color: #f00"
            >Sil</span
          >
        </td>
      </tr>
      <tr *ngIf="!surweys.length">
        <td colspan="100">
          <span>Üzgünüz Henüz Hiç Anket Oluştumadınız.</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
