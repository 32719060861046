import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import "moment/locale/tr";
import { exportExcel } from "src/app/helpers/helpers";
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-admin-reports",
  templateUrl: "./admin-reports.component.html",
  styleUrls: ["./admin-reports.component.scss"],
})
export class AdminReportsComponent implements OnInit {
  surveys = [];
  questions = [];
  selectedSurveyId = "";
  reportType = "";
  selectedDate = null;
  selectedFrequency = "";
  optionsAge: any;
  optionsFill: any;
  optionsGender: any;
  optionsGenderAvg: any;
  optionsQuestion: any;
  optionsQuestionPie: any;
  companyId = "";
  isReportReady = false;
  selectedQuestionIndex = -1;
  distributeOptions = "";
  optionsAgeData = [];
  openQuestionData = [];
  openTitle = "";
  companyName = "";
  user: any;

  colors = [
    "#f3622e",
    "#f9a71c",
    "#57b757",
    "#41a9c9",
    "#4258c9",
    "#14274E",
    "#888888",
    "#c84164",
    "#9a42c8",
    "#A8D8EA",
    "#AA96DA",
    "#F67280",
    "#FB929E",
    "#FF1E00",
    "#E8F9FD",
    "#355C7D",
    "#88304E",
    "#F6416C",
    "#CDBBA7",
    "#62D2A2",
    "#FDE2E2",
    "#DDE7F2",
    "#E4D8DC",
    "#557571",
    "#3490DE",
    "#F9F9F9",
    "#FF5722",
    "#FBE8E7",
    "#D3E0EA",
    "#F85F73",
    "#CCCCCC",
    "#BBBFCA",
    "#7579E7",
  ];

  constructor(private http: HttpClient, private userService: UserService) {
    const colorLength = this.colors.length;
    for (let i = 0; i < colorLength; i++) {
      const m = this.colors[i];
      const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(m);
      this.colors.push(
        `rgba(${parseInt(rgb[1], 16)}, ${parseInt(rgb[2], 16)}, ${parseInt(
          rgb[3],
          16
        )}, .5)`
      );
    }

    this.optionsQuestion = {
      data: [],
      title: {
        text: "Soru Raporu",
      },
      subtitle: {
        text: "",
      },
      series: [
        {
          type: "column",
          xKey: "date",
          xName: "Tarih",
          yKeys: [],
          yNames: [],
          fills: this.colors,
          strokes: this.colors,
        },
      ],
      legend: {
        position: "top",
      },
    };
    this.optionsQuestionPie = {
      data: [],
      title: {
        text: "Soru Raporu - PIE",
      },
      subtitle: {
        text: "",
      },
      series: [
        {
          type: "pie",
          labelKey: "label",
          angleKey: "value",
          fills: this.colors,
          strokes: this.colors,
          tooltip: {
            renderer: function (params) {
              return {
                content: `%${params.angleValue.toFixed(2)}`,
                title: params.datum[params.labelKey],
              };
            },
          },
        },
      ],
    };
    this.optionsFill = {
      data: [],
      title: {
        text: "Doldurulma",
      },
      subtitle: {
        text: "",
      },
      series: [
        {
          type: "column",
          xKey: "date",
          xName: "Tarih",
          yKey: "count",
          yName: "Sayı",
          label: {},
          fills: this.colors,
          strokes: this.colors,
        },
      ],
      legend: {
        position: "top",
      },
    };
    this.optionsAge = {
      data: [],
      title: {
        text: "Yaş Aralığı",
      },
      subtitle: {
        text: "",
      },
      series: [
        {
          type: "column",
          xKey: "date",
          xName: "Tarih",
          yKey: "age",
          yName: "Ortalama",
          label: {},
          fills: this.colors,
          strokes: this.colors,
        },
      ],
      legend: {
        position: "top",
      },
    };
    this.optionsGender = {
      data: [],
      title: {
        text: "Cinsiyet",
      },
      subtitle: {
        text: "",
      },
      series: [
        {
          type: "column",
          xKey: "date",
          xName: "Tarih",
          yKeys: ["man", "woman", "other"],
          yNames: ["Erkek", "Kadın", "Diğer"],
          label: {},
          fills: this.colors,
          strokes: this.colors,
        },
      ],
      legend: {
        position: "top",
      },
    };
    this.optionsGenderAvg = {
      data: [],
      title: {
        text: "Cinsiyet",
      },
      subtitle: {
        text: "",
      },
      series: [
        {
          type: "column",
          xKey: "date",
          xName: "Tarih",
          yKeys: ["man", "woman", "other"],
          yNames: ["Erkek", "Kadın", "Diğer"],
          fills: this.colors,
          strokes: this.colors,
          label: {
            formatter: ({ value }) => {
              if (value === 0) {
                return "";
              }
              return `${Math.round(value)}%`;
            },
          },
        },
      ],
      legend: {
        position: "top",
      },
      axes: [
        {
          type: "number",
          position: "left",
          min: 1,
          max: 100,
          tick: {
            count: 10,
          },
        },
        {
          type: "category",
          position: "bottom",
        },
      ],
    };

    this.http
      .get("/getAdminCompanyInfo")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.companyName = res.name;
      });
  }

  get selectedSurvey() {
    return this.surveys.find((m) => m._id === this.selectedSurveyId);
  }

  get selectedQuestion() {
    if (this.selectedQuestionIndex > -1) {
      return this.questions[this.selectedQuestionIndex];
    }
    return null;
  }

  get showDistributeOptions() {
    if (this.selectedQuestion) {
      return (
        this.reportType === "question" &&
        this.selectedQuestion.sort === "multiple"
      );
    }
    return false;
  }

  get showReport() {
    if (!this.user) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.userService.user.subscribe((x) => {
      this.user = x;
    });
    moment.locale("tr");
    this.getSurveys();
  }

  getSurveys(): any {
    this.http
      .get("/getStatistics")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.surveys = res;
      });
  }

  handleQuestions(): any {
    this.isReportReady = false;
    this.questions = this.selectedSurvey?.statistics?.questions || [];
  }

  getReport(): any {
    this.isReportReady = false;

    if (!this.reportType) {
      alert("Rapor Türü Seçiniz!");
      return;
    }

    if (!this.selectedSurvey) {
      alert("Anket Seçiniz!");
      return;
    }

    if (!this.selectedDate) {
      alert("Tarih Aralığı Seçiniz!");
      return;
    }

    if (!this.selectedFrequency) {
      alert("Frekans Seçiniz!");
      return;
    }

    if (this.reportType === "question" && !this.selectedQuestion) {
      alert("Soru Seçiniz!");
      return;
    }

    if (this.showDistributeOptions && !this.distributeOptions) {
      alert("Şıklar Dağıtılsın Mı Seçimini Yapınız!");
      return;
    }

    const dateDatas = {};
    const dateDiff = this.selectedDate.endDate.diff(
      this.selectedDate.startDate,
      "days"
    );
    this.openQuestionData = [];
    for (let i = 0; i <= dateDiff; i++) {
      const dt = moment(this.selectedDate.startDate).add(i, "days");
      let key = "";
      let weekNumber = 0;
      if (this.selectedFrequency === "week") {
        weekNumber = parseInt((dt.date() / 7).toString()) + 1;
        if (weekNumber > 4) {
          weekNumber = 4;
        }
        key = `${dt.format("MM.YY")} ${weekNumber}. Haf.`;
      } else {
        key = `${dt.format("MMMM YY")}`;
      }

      if (!dateDatas[key]) {
        dateDatas[key] = {
          dt,
          weekNumber,
          age: 0,
          ageData: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          },
          gender: {
            man: 0,
            woman: 0,
            other: 0,
          },
          fillingCount: 0,
          questionAnswerCount: 0,
          question: {},
        };
      }

      const fillings = this.selectedSurvey.statistics.fillings.filter((m) => {
        const fillingDate = moment(m.createdOn);
        return fillingDate.isSame(dt, "day");
      });

      const optionsSubtitle =
        this.selectedFrequency === "week"
          ? "Haftalık Istatistikler"
          : "Aylık Istatistikler";
      this.optionsAge.subtitle.text = optionsSubtitle;
      this.optionsFill.subtitle.text = optionsSubtitle;
      this.optionsGender.subtitle.text = optionsSubtitle;
      this.optionsGenderAvg.subtitle.text = optionsSubtitle;
      this.optionsQuestion.subtitle.text = optionsSubtitle;
      this.optionsQuestionPie.subtitle.text = optionsSubtitle;
      this.openTitle = optionsSubtitle;

      this.optionsAgeData = [];
      this.optionsFill.data = [];
      this.optionsAge.data = [];
      this.optionsGender.data = [];
      this.optionsGenderAvg.data = [];
      this.optionsQuestion.data = [];
      this.optionsQuestionPie.data = [];
      this.optionsQuestion.series[0].yKeys = [];
      this.optionsQuestion.series[0].yNames = [];

      if (this.reportType === "survey") {
        for (let filling of fillings) {
          if (filling.user) {
            switch (filling.user.gender) {
              case "erkek":
                dateDatas[key].gender.man += 1;
                break;
              case "kadin":
                dateDatas[key].gender.woman += 1;
                break;
              case "diger":
                dateDatas[key].gender.other += 1;
                break;
            }

            if (filling.user?.age) {
              const userAge = moment().year() - moment(filling.user.age).year();

              dateDatas[key].age += userAge;
              if (userAge < 18) {
                dateDatas[key].ageData["0"] += 1;
              } else if (userAge < 25) {
                dateDatas[key].ageData["1"] += 1;
              } else if (userAge < 35) {
                dateDatas[key].ageData["2"] += 1;
              } else if (userAge < 50) {
                dateDatas[key].ageData["3"] += 1;
              } else {
                dateDatas[key].ageData["4"] += 1;
              }
            }
          }
          dateDatas[key].fillingCount += 1;
        }
      } else {
        if (this.selectedQuestion.kind !== "open") {
          for (const opts of this.selectedQuestion.question.options) {
            const optKey = opts.text.trim() || opts.value.toString().trim();
            if (!dateDatas[key].question[optKey]) {
              dateDatas[key].question[optKey] = 0;
            }
          }
        }

        for (let filling of fillings) {
          if (this.selectedQuestion.kind === "open") {
            this.openQuestionData.push({
              date: key,
              answer: filling.answers[this.selectedQuestionIndex],
            });
          } else {
            const selectedQuestionAnswer =
              filling.answers[this.selectedQuestionIndex];
            if (this.showDistributeOptions) {
              let opts = [];
              if (typeof selectedQuestionAnswer !== "undefined") {
                if (typeof selectedQuestionAnswer === "number") {
                  opts = this.selectedQuestion.question.options.filter(
                    (m) => selectedQuestionAnswer == m.value
                  );
                } else {
                  opts = this.selectedQuestion.question.options.filter((m) =>
                    selectedQuestionAnswer.includes(m.value)
                  );
                }
              }
              if (this.distributeOptions === "yes") {
                for (const o of opts) {
                  const optKey = o.text.trim() || o.value.toString().trim();
                  if (dateDatas[key].question[optKey]) {
                    dateDatas[key].question[optKey] += 1;
                  } else {
                    dateDatas[key].question[optKey] = 1;
                  }
                  dateDatas[key].questionAnswerCount += 1;
                }
              } else {
                const optKeyName = opts[0].text.trim() ? "text" : "value";
                const optKey = opts.map((m) => m[optKeyName].trim()).join();
                if (dateDatas[key].question[optKey.trim()]) {
                  dateDatas[key].question[optKey.trim()] += 1;
                } else {
                  dateDatas[key].question[optKey.trim()] = 1;
                }
                dateDatas[key].questionAnswerCount += 1;
              }
            } else {
              if (selectedQuestionAnswer) {
                const opt = this.selectedQuestion.question.options.find(
                  (m) => m.value === selectedQuestionAnswer
                );
                const optKey = opt.value.toString();
                if (dateDatas[key].question[optKey.trim()]) {
                  dateDatas[key].question[optKey.trim()] += 1;
                } else {
                  dateDatas[key].question[optKey.trim()] = 1;
                }
                dateDatas[key].questionAnswerCount += 1;
              }
            }
          }

          dateDatas[key].fillingCount += 1;
        }
      }
    }

    if (this.reportType === "question") {
      for (const key of Object.keys(dateDatas)) {
        if (!this.showDistributeOptions) {
          let divideCount = 0;
          let sum = 0;
          for (const qKey of Object.keys(dateDatas[key].question)) {
            const optItem = this.selectedQuestion.question.options.find(
              (m) => m.value == qKey || m.text == qKey
            );
            const val = dateDatas[key].question[qKey];
            sum += val * optItem.value;
            if (val) {
              divideCount += val;
            }
          }

          let result = 0;
          if (divideCount > 0) {
            result = parseFloat((sum / divideCount).toFixed(2));
          }
          console.log({ key, divideCount, sum, result });
          dateDatas[key].question = {
            ["Ortalama"]: result,
          };
          this.optionsQuestion.axes = [
            {
              type: "number",
              position: "left",
              min: 1,
              max: 11,
              tick: {
                count: 11,
              },
            },
            {
              type: "category",
              position: "bottom",
            },
          ];
          this.optionsQuestion.series[0] = {
            ...this.optionsQuestion.series[0],
            label: {
              formatter: ({ value }) => {
                if (value === 0) {
                  return "";
                }
                return value;
              },
            },
          };
          if (this.optionsQuestion.series[0].tooltip) {
            delete this.optionsQuestion.series[0].tooltip;
          }
        } else {
          this.optionsQuestion.series[0] = {
            ...this.optionsQuestion.series[0],
            tooltip: {
              renderer: function (params) {
                return {
                  content: `${params.xValue} - %${params.yValue.toFixed(2)}`,
                  title: params.yName,
                };
              },
            },
            label: {
              formatter: ({ value }) => {
                if (!value) {
                  return "";
                }
                return `%${value}`;
              },
            },
          };
          this.optionsQuestion.axes = [
            {
              type: "number",
              position: "left",
              min: 1,
              max: 100,
              tick: {
                count: 10,
              },
              label: {
                formatter: (params) => Math.round(params.value) + "%",
              },
            },
            {
              type: "category",
              position: "bottom",
            },
          ];
        }
      }
    }

    for (const key of Object.keys(dateDatas)) {
      const tableTitle =
        this.selectedFrequency === "week"
          ? `${dateDatas[key].dt.format(`MMMM YYYY`)} - ${
              dateDatas[key].weekNumber
            }. Hafta`
          : `${dateDatas[key].dt.format(`MMMM YYYY`)}`;

      if (this.reportType === "survey") {
        this.optionsFill.data.push({
          date: key,
          tableTitle,
          count: dateDatas[key].fillingCount,
        });
        this.optionsAge.data.push({
          date: key,
          tableTitle,
          age:
            dateDatas[key].age > 0
              ? dateDatas[key].age / dateDatas[key].fillingCount
              : 0,
        });
        this.optionsAgeData.push({
          date: key,
          tableTitle,
          ...dateDatas[key].ageData,
        });
        this.optionsGender.data.push({
          date: key,
          tableTitle,
          ...dateDatas[key].gender,
        });
        this.optionsGenderAvg.data.push({
          date: key,
          tableTitle,
          man: (dateDatas[key].gender.man / dateDatas[key].fillingCount) * 100,
          woman:
            (dateDatas[key].gender.woman / dateDatas[key].fillingCount) * 100,
          other:
            (dateDatas[key].gender.other / dateDatas[key].fillingCount) * 100,
        });
      } else {
        let questionData = {
          date: key,
          tableTitle,
          ...dateDatas[key].question,
        };

        for (const qKey of Object.keys(dateDatas[key].question)) {
          const yKeyExists = this.optionsQuestion.series[0].yKeys.some(
            (m) => m === qKey
          );
          if (!yKeyExists) {
            this.optionsQuestion.series[0].yKeys.push(qKey);
          }

          const yNameExists = this.optionsQuestion.series[0].yNames.some(
            (m) => m === qKey
          );
          if (!yNameExists) {
            this.optionsQuestion.series[0].yNames.push(qKey);
          }

          const pieExists = this.optionsQuestionPie.data.find(
            (m) => m.label === qKey
          );
          if (pieExists) {
            pieExists.value += dateDatas[key].question[qKey];
          } else {
            this.optionsQuestionPie.data.push({
              label: qKey,
              value: dateDatas[key].question[qKey],
            });
          }
        }

        for (const qKey of Object.keys(dateDatas[key].question)) {
          questionData[qKey + "_"] = questionData[qKey].toString();
          if (
            questionData[qKey] > 0 &&
            dateDatas[key].questionAnswerCount > 0 &&
            this.showDistributeOptions
          ) {
            questionData[qKey] = parseFloat(
              (
                (questionData[qKey] / dateDatas[key].questionAnswerCount) *
                100
              ).toFixed(2)
            );
          }
        }
        this.optionsQuestion.data.push(questionData);
      }
    }

    let allFillingCount = 0;
    for (let item of this.optionsQuestionPie.data) {
      allFillingCount += item.value;
    }

    if (allFillingCount > 0) {
      for (let item of this.optionsQuestionPie.data) {
        if (item.value > 0) {
          item.value = parseFloat(
            ((item.value / allFillingCount) * 100).toFixed(2)
          );
        }
      }
    }

    setTimeout(() => {
      this.isReportReady = true;
    }, 200);
  }

  async downloadExcel() {
    if (!this.isReportReady) {
      return;
    }

    let output = [];
    const userNumbers = [];
    const dateDiff = this.selectedDate.endDate.diff(
      this.selectedDate.startDate,
      "days"
    );
    let fileName = "";
    if (this.reportType === "survey") {
      fileName = `${this.companyName} - ${
        this.selectedSurvey.survey.name
      } - ${moment(this.selectedDate.startDate).format("DD.MM.YYYY")}-${moment(
        this.selectedDate.endDate
      ).format("DD.MM.YYYY")}`;
    } else {
      fileName = `${this.companyName} - ${
        this.selectedQuestion.question.text
      } - ${moment(this.selectedDate.startDate).format("DD.MM.YYYY")}-${moment(
        this.selectedDate.endDate
      ).format("DD.MM.YYYY")}`;
    }

    let anonIndex = 0;
    for (let i = 0; i <= dateDiff; i++) {
      const dt = moment(this.selectedDate.startDate).add(i, "days");
      const fillings = this.selectedSurvey.statistics.fillings.filter((m) => {
        const fillingDate = moment(m.createdOn);
        return fillingDate.isSame(dt, "day");
      });

      for (let filling of fillings) {
        let userIndex = 0;
        if (!filling.userId) {
          userIndex = Number(anonIndex.toString());
          anonIndex++;
        } else {
          userIndex = userNumbers.findIndex((m) => m === filling.userId);
          if (userIndex === -1) {
            userNumbers.push(filling.userId);
            userIndex = userNumbers.length - 1;
          }
        }

        if (this.reportType === "survey") {
          output.push({
            Tarih: moment(filling.createdOn).format("DD.MM.YYYY HH:mm"),
            "Şirket Adı": this.companyName,
            "Anket Adı": this.selectedSurvey.survey.name,
            Anketör: `Kullanıcı ${userIndex + 1}`,
            Yaş: filling.user?.age || "",
            Cinsiyet: filling.user?.gender?.toUpperCase() || "",
            "Doldurulan Anket Sayısı": 1,
          });
        } else {
          const selectedQuestionAnswer =
            filling.answers[this.selectedQuestionIndex];
          if (this.selectedQuestion.kind === "open") {
            output.push({
              Tarih: moment(filling.createdOn).format("DD.MM.YYYY HH:mm"),
              "Şirket Adı": this.companyName,
              "Anket Adı": this.selectedSurvey.survey.name,
              Anketör: `Kullanıcı ${userIndex + 1}`,
              "Soru Adı": this.selectedQuestion.question.text,
              Yanıt:
                selectedQuestionAnswer?.toString().replace("\t", " ") || "",
            });
          } else if (this.selectedQuestion.kind === "close") {
            if (this.selectedQuestion.sort === "multiple") {
              let opts = [];
              if (typeof selectedQuestionAnswer !== "undefined") {
                if (typeof selectedQuestionAnswer === "number") {
                  opts = this.selectedQuestion.question.options.filter(
                    (m) => selectedQuestionAnswer == m.value
                  );
                } else {
                  opts = this.selectedQuestion.question.options.filter((m) =>
                    selectedQuestionAnswer.includes(m.value)
                  );
                }
              }

              if (this.distributeOptions === "yes") {
                for (const o of opts) {
                  const optKey = o.text.trim() || o.value.toString().trim();
                  output.push({
                    Tarih: moment(filling.createdOn).format("DD.MM.YYYY HH:mm"),
                    "Şirket Adı": this.companyName,
                    "Anket Adı": this.selectedSurvey.survey.name,
                    Anketör: `Kullanıcı ${userIndex + 1}`,
                    "Soru Adı": this.selectedQuestion.question.text,
                    Yanıt: optKey.toString().replace("\t", " "),
                  });
                }
              } else {
                const optKeyName = opts[0].text.trim() ? "text" : "value";
                const optKey = opts
                  .map((m) => m[optKeyName].trim().replace("\t", " "))
                  .join(", ");
                output.push({
                  Tarih: moment(filling.createdOn).format("DD.MM.YYYY HH:mm"),
                  "Şirket Adı": this.companyName,
                  "Anket Adı": this.selectedSurvey.survey.name,
                  Anketör: `Kullanıcı ${userIndex + 1}`,
                  "Soru Adı": this.selectedQuestion.question.text,
                  Yanıt: optKey.toString().replace("\t", " "),
                });
              }
            } else {
              output.push({
                Tarih: moment(filling.createdOn).format("DD.MM.YYYY HH:mm"),
                "Şirket Adı": this.companyName,
                "Anket Adı": this.selectedSurvey.survey.name,
                Anketör: `Kullanıcı ${userIndex + 1}`,
                "Soru Adı": this.selectedQuestion.question.text,
                Puan: selectedQuestionAnswer,
              });
            }
          }
        }
      }
    }

    exportExcel(output, fileName);
  }
}
