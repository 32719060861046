<div class="holder">
  <app-breadcrumb [data]="breadcrumbData"></app-breadcrumb>
  <div class="content">
    <div class="results-area">
      <ul>
        <li *ngFor="let item of earnings">
          <div class="logo-holder">
            <img *ngIf="item.logo" [src]="item.logo" alt="" />
            <img
              *ngIf="!item.logo"
              src="../../../assets/images/logo-dark.svg"
              alt=""
            />
          </div>
          <div class="content-holder">
            <span class="title"
              ><b>{{ item.name }}</b></span
            >
            <span
              ><b>Kazanım tarihi: </b>
              {{ item.discountEarnDate | date : "dd.MM.yyyy" }}</span
            >
            <span><b>İndirim kodu: </b> {{ discountCode(item) }}</span>
            <span
              ><b>{{ getStatus(item) }}</b></span
            >
          </div>
          <div class="discount-qr-holder">
            <div class="discount-holder">
              <app-button
                *ngIf="item.discountType !== 'Hediye'"
                type="curvy"
                text="{{ item.discountAmount }} {{
                  item.discountAmountType
                }} indirim"
              ></app-button>
              <app-button
                *ngIf="item.discountType === 'Hediye'"
                [type]="getGiftFontClass(item)"
                text="{{ item.discountDescription?.trim() }}"
              ></app-button>
            </div>
            <div
              class="qr-holder"
              *ngIf="item.qr && item.companyId !== sportiveCompanyId"
            >
              <div
                [style.background-image]="getBackground(item.qr)"
                class="qr-holder-image"
                (click)="openEarningQr(item)"
              ></div>
            </div>
          </div>
        </li>
        <li
          class="no-campaign"
          *ngIf="!earningsLoading && earnings.length === 0"
        >
          <img src="../../../assets/images/logo-dark.svg" alt="" />
          <span>Kazanım bulunamadı!</span>
        </li>
        <li class="loader-container" *ngIf="earningsLoading">
          <app-loader></app-loader>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="popup-holder" *ngIf="showQrModal">
  <div class="popup-container">
    <div class="popup-header">
      <div class="popup-header-title">{{ selected?.name }}</div>
      <img
        src="/assets/images/popup-close.svg"
        class="popup-header-close"
        (click)="showQrModal = false"
      />
    </div>
    <div class="popup-content">
      <div
        *ngIf="selected?.qr"
        [style.background-image]="getBackground(selected?.qr)"
        class="popup-content-image"
      ></div>
    </div>
  </div>
</div>
