<h1 style="margin-bottom: 15px">Ana Sayfa Kampanya Sırala</h1>

<table class="table table-hover table-bordered">
  <thead>
    <tr>
      <th>Başlık</th>
      <th>Sıra</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of surveys">
      <td>{{ item.name }}</td>
      <td>
        <div class="form-control-holder">
          <input
            type="number"
            [(ngModel)]="item.homeOrderNew"
            style="margin-bottom: 0"
          />
        </div>
      </td>
    </tr>
  </tbody>
</table>

<app-button
  class="form-button"
  text="Güncelle"
  type="fancy"
  (click)="submitUpdates()"
></app-button>
